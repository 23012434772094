import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthRepository } from "../../repositories/AuthRepository";
import { useDispatch } from "react-redux";
import {
  notifyShowErrorMessage,
  notifyShowInfoMessage,
} from "../../common/CommonActions";
import {
  Check,
  ContentCopy,
  MilitaryTech,
  Person4Sharp,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { alpha, useMediaQuery } from "@mui/system";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Brush,
} from "recharts";

import { SectorRepository } from "../../repositories/SectorRepository";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import CompanyAccessedTable from "./CompanyAccessedTable";
export default function MyProfile() {
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [toggleVisibility, setToggleVisibility] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const [balanceData, setBalanceData] = useState([
    { balance: 1500, date: "13/11/2023", index: 0 },
    { balance: 1224, date: "16/11/2023", index: 1 },
    { balance: 2012, date: "01/12/2023", index: 2 },
    { balance: 1223, date: "03/01/2024", index: 3 },
    { balance: 1423, date: "13/03/2024", index: 4 },
    { balance: 3500, date: "27/06/2024", index: 5 },
    { balance: 5132, date: "11/11/2024", index: 6 },
    { balance: 1600, date: "13/12/2024", index: 7 },
    { balance: 1300, date: "16/01/2025", index: 8 },
    { balance: 2040, date: "01/02/2025", index: 9 },
    { balance: 1250, date: "03/03/2025", index: 10 },
  ]);

  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [refAreaLeft, setRefAreaLeft] = useState();
  const [refAreaRight, setRefAreaRight] = useState();
  const [latestBalanceData, setLatestBalanceData] = useState(
    balanceData[balanceData.length - 1]
  );
  const [brushStartIndex, setBrushStartIndex] = useState(0);
  const [brushEndIndex, setBrushEndIndex] = useState(balanceData.length - 1);
  const [sectors, setSectors] = useState();
  const [sectorMap, setSectorMap] = useState({});
  const [historyData, setHistoryData] = useState();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  let components = [
    {
      path: `/users`,
      title: "Users",
      icon: "person",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/companies`,
      title: "Companies",
      icon: "apartment",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/chartData`,
      title: "Company Chart Data",
      icon: "show_chart",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/stockData`,
      title: "Company Stock Data",
      icon: "add_chart",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/indicatorSettings`,
      title: "Indicator Settings",
      icon: "app_settings_alt",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      title: "Consultant",
      path: "/",
      selectedPath: "/",
      icon: "support_agent",
      roles: ["ROLE_CLIENT"],
    },
    {
      title: "My Plan",
      path: "/myPlan",
      selectedPath: "/myPlan",
      icon: "pending_actions",
      roles: ["ROLE_CLIENT"],
    },
    {
      title: "History",
      path: "/history",
      selectedPath: "/history",
      icon: "history",
      roles: ["ROLE_CLIENT"],
    },

    {
      path: `/profileSettings`,
      title: "Profile Settings",
      icon: "admin_panel_settings",
      roles: ["ROLE_ADMINISTRATION", "ROLE_CLIENT"],
    },
  ];

  useEffect(() => {
    loadData();
    loadSectors();
  }, []);

  useEffect(() => {
    loadUserHistory();
  }, [page, size]);

  const loadData = () => {
    setLoading(true);
    UsersRepository.getUser()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage("Unable to load user data!"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadUserHistory = () => {
    setLoading(true);
    UsersRepository.getUserCompanyAccess(page, size)
      .then((res) => {
        setHistoryData(res.data);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage("Unable to load user history data!"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadSectors = () => {
    setLoading(true);
    SectorRepository.getAll()
      .then((res) => {
        setSectors(res.data);
        const map = {};
        res.data.forEach((sector) => {
          map[sector?.id] = sector?.name;
        });
        setSectorMap(map);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  const findSectorById = (sectorId) => {
    return sectorMap[sectorId] || "Unknown Sector";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  return (
    <Container maxWidth="lg">
      <Paper
        sx={{
          padding: 5,
          borderRadius: "20px",
          backgroundColor: theme.palette.background.default,
          backgroundSize: "cover",
          backgroundPosition: "center",
          boxShadow: 5,
          position: "relative",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            md={1.5}
            sx={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                width: 100,
                height: 100,
                bgcolor: theme.palette.darkContrast.light,
                boxShadow: 5,
                position: "relative",
                zIndex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <Person4Sharp
                sx={{
                  fontSize: "100px",
                  color: theme.palette.darkContrast.main,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={6} md={1.8}>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.darkContrast.main,
                fontWeight: "bold",
                marginY: 1,
              }}
            >
              {data?.firstName} {data?.lastName}
            </Typography>
            <Tooltip
              title={<Typography variant="h6">LinkedIn</Typography>}
              arrow
            >
              <a
                href={data?.linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="25"
                  height="25"
                  viewBox="0 0 50 50"
                  style={{ cursor: "pointer" }}
                >
                  <path
                    d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"
                    fill={`${theme.palette.darkContrast.main}`}
                  ></path>
                </svg>
              </a>
            </Tooltip>
            <Tooltip
              title={<Typography variant="h6">X (Twitter)</Typography>}
              arrow
            >
              <a
                href={data?.twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="25"
                  height="25"
                  viewBox="0 0 50 50"
                  style={{ cursor: "pointer" }}
                >
                  <path
                    fill={`${theme.palette.darkContrast.main}`}
                    d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"
                  ></path>
                </svg>
              </a>
            </Tooltip>

            <br />
            <Chip
              label={
                <Typography
                  variant="h6"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "light",
                  }}
                >
                  <Check sx={{ marginRight: "7px" }} /> Verified
                </Typography>
              }
              sx={{
                color: theme.palette.success.main,
                borderRadius: 1,
                height: "25px",
                marginTop: "5px",
              }}
            />
          </Grid>
          <Divider
            sx={{ display: { xs: "none", md: "block" } }}
            variant="middle"
            orientation="vertical"
            flexItem
          />
          <Grid item xs={6} md={1.5}>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.darkContrast.main,
                fontWeight: "bold",
                marginY: 1,
              }}
            >
              User ID
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.lightGray.main,
                fontWeight: "light",
                marginY: 1,
              }}
            >
              {data?.id?.slice(0, 10) + "..." || "N/A"}
              <Tooltip
                title={<Typography variant="h6">Copy to clipboard</Typography>}
              >
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(data?.id || "");
                    if (data?.id) {
                      dispatch(
                        notifyShowInfoMessage("User ID copied to clipboard!")
                      );
                    }
                  }}
                  size="small"
                  sx={{ marginLeft: 1 }}
                >
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={6} md={2.2}>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.darkContrast.main,
                fontWeight: "bold",
                marginY: 1,
              }}
            >
              Email
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.lightGray.main,
                fontWeight: "light",
                marginY: 1,
              }}
            >
              {data?.email?.slice(0, 15) + "..." || "N/A"}{" "}
              <Tooltip
                title={<Typography variant="h6">Copy to clipboard</Typography>}
              >
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(data?.email || "");
                    if (data?.email) {
                      dispatch(
                        notifyShowInfoMessage("Email copied to clipboard!")
                      );
                    }
                  }}
                  size="small"
                  sx={{ marginLeft: 1 }}
                >
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>

          <Grid item xs={6} md={1.4}>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.darkContrast.main,
                fontWeight: "bold",
                marginY: 1,
              }}
            >
              Type
            </Typography>
            <Chip
              label={
                data?.organization?.type.includes("OFFICE")
                  ? "OFFICE"
                  : "CLIENT"
              }
              color={"primary"}
              size="small"
              sx={{
                fontWeight: "light",
                fontSize: "15px",
                backgroundColor: theme.palette.mutedBlue.main,
              }}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.darkContrast.main,
                fontWeight: "bold",
                marginY: 1,
              }}
            >
              VIP Level
            </Typography>
            <Chip
              label={
                <Typography
                  variant="h6"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "light",
                  }}
                >
                  <MilitaryTech sx={{ marginRight: "6px" }} />{" "}
                  {data?.organization?.type.includes("OFFICE")
                    ? "Premium"
                    : "Regular User"}
                </Typography>
              }
              sx={{
                color: theme.palette.error.main,
                borderRadius: 1,
                height: "25px",
                alignContent: "center",
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Box
              sx={{
                border: `1px solid ${alpha(theme.palette.lightGray.main, 0.3)}`,
                padding: { xs: 2, sm: 4 },
                borderRadius: "10px",
                width: { xs: "100%", sm: "auto" },
                maxWidth: { xs: "100%", sm: "auto" },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography variant={isMobile ? "h5" : "h4"}>
                        Estimated Balance{" "}
                        <Tooltip
                          title={
                            <Typography variant="h6">
                              {toggleVisibility
                                ? "Hide balance"
                                : "Show balance"}
                            </Typography>
                          }
                        >
                          <IconButton
                            onClick={() => {
                              setToggleVisibility(!toggleVisibility);
                            }}
                            size="small"
                            sx={{ marginLeft: 1 }}
                          >
                            {toggleVisibility ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={isMobile ? "h4" : "h2"}>
                        {toggleVisibility
                          ? currency == "USD"
                            ? "5132.23"
                            : currency == "EUR"
                            ? (5132.23 * 0.93).toFixed(2)
                            : currency == "MKD"
                            ? (5132.23 * 57).toFixed(2)
                            : ""
                          : "******"}
                        <Select
                          value={currency || "USD"}
                          label="Currency"
                          defaultValue="USD"
                          onChange={(e) => {
                            setCurrency(e.target.value);
                          }}
                          sx={{
                            border: "none",
                            boxShadow: "none",
                            "& fieldset": {
                              border: "none",
                            },
                            fontSize: useMediaQuery((theme) =>
                              theme.breakpoints.down("sm")
                            )
                              ? "14px"
                              : "18px",
                          }}
                        >
                          <MenuItem value={"USD"}>USD</MenuItem>
                          <MenuItem value={"EUR"}>EUR</MenuItem>
                          <MenuItem value={"MKD"}>MKD</MenuItem>
                        </Select>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={isMobile ? "h5" : "h4"}
                        sx={{ fontWeight: "light" }}
                      >
                        Today's PnL:{" "}
                        <span
                          style={{
                            marginLeft: "12px",
                            color: theme.palette.primary.main,
                            fontWeight: "bold",
                          }}
                        >
                          {toggleVisibility
                            ? currency == "USD"
                              ? "231.23"
                              : currency == "EUR"
                              ? (231.23 * 0.93).toFixed(2)
                              : currency == "MKD"
                              ? (231.23 * 57).toFixed(2)
                              : ""
                            : "******"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        filter: toggleVisibility ? "none" : "blur(3px)",
                        transition: "filter 0.5s ease",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 1,
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={!toggleVisibility}
                        sx={{
                          backgroundColor: alpha(
                            theme.palette.mutedBlue.main,
                            0.9
                          ),
                          "&:hover": {
                            backgroundColor: theme.palette.mutedBlue.main,
                          },
                        }}
                      >
                        Deposit
                      </Button>
                      <Button
                        variant="contained"
                        disabled={!toggleVisibility}
                        sx={{
                          backgroundColor: alpha(
                            theme.palette.mutedRed.main,
                            0.9
                          ),
                          "&:hover": {
                            backgroundColor: theme.palette.mutedRed.main,
                          },
                        }}
                      >
                        Withdraw
                      </Button>
                      <Button
                        variant="contained"
                        disabled={!toggleVisibility}
                        sx={{
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.9
                          ),
                          "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                          },
                        }}
                      >
                        Cash In
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    filter: toggleVisibility ? "none" : "blur(5px)",
                    transition: "filter 0.5s ease",
                  }}
                >
                  <div style={{ userSelect: "none" }}>
                    <ResponsiveContainer height={200}>
                      <LineChart data={balanceData}>
                        <CartesianGrid strokeDasharray="0.1 0.1" />
                        <XAxis
                          allowDataOverflow
                          dataKey="date"
                          domain={[left, right]}
                          type="category"
                        />
                        <YAxis
                          orientation="left"
                          allowDataOverflow
                          domain={[0, "dataMax + 200"]}
                          type="number"
                          yAxisId="1"
                        />
                        {/* <Tooltip
                          cursor={false}
                          formatter={(value, name) => [
                            `${
                              currency == "USD"
                                ? value + " USD"
                                : currency == "EUR"
                                ? (value * 0.93).toFixed(2) + " EUR"
                                : currency == "MKD"
                                ? (value * 57).toFixed(2) + " MKD"
                                : ""
                            } - ${name} `,
                          ]}
                          labelFormatter={(value) => `Date: ${value}`}
                        /> */}

                        <Brush
                          onChange={(e) => {
                            let item;
                            balanceData.map((d, index) => {
                              if (d.index === e.endIndex) {
                                item = d;
                              }
                            });
                            setLatestBalanceData(item);
                            setBrushStartIndex(e.startIndex);
                            setBrushEndIndex(e.endIndex);
                          }}
                          dataKey="index"
                          fill={`${theme.palette.background.paper}`}
                          startIndex={brushStartIndex}
                          endIndex={brushEndIndex}
                          height={15}
                        />
                        <Line
                          yAxisId="1"
                          type="linear"
                          dataKey="balance"
                          name="Balance"
                          stroke="#ffd54f"
                          strokeWidth={2}
                          animationDuration={3000}
                        />

                        {refAreaLeft && refAreaRight ? (
                          <ReferenceArea
                            yAxisId="1"
                            x1={refAreaLeft}
                            x2={refAreaRight}
                            strokeOpacity={0.3}
                          />
                        ) : null}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <CompanyAccessedTable
              isMobile={isMobile}
              loading={loading}
              theme={theme}
              historyData={historyData}
              findSectorById={findSectorById}
              page={page}
              size={size}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
            }}
          >
            <Divider textAlign="center">
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "light",
                  color: theme.palette.darkContrast.main,
                }}
              >
                Shortcuts
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              container
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {components.map(
                (component, index) =>
                  AuthRepository.hasAnyRole(component.roles) && (
                    <Grid
                      item
                      md={1.5}
                      xs={2.5}
                      key={index}
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        margin: 1,
                      }}
                    >
                      <Tooltip
                        title={
                          <Typography variant="h6">
                            {component.title}
                          </Typography>
                        }
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <Card
                          sx={{
                            height: "45px",
                            width: "120px",
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            verticalAlign: "center",
                            color: theme.palette.darkContrast.main,
                            "&:hover": {
                              backgroundColor: theme.palette.darkContrast.main,
                              color: theme.palette.dashboardBackground.main,
                            },
                          }}
                          onClick={() => navigate(component.path)}
                          className="roundedBorder administration_landing_page_btn"
                        >
                          <CardContent
                            sx={{
                              paddingTop: "22px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Icon
                              sx={{
                                fontSize: "25px",
                              }}
                            >
                              {component.icon}
                            </Icon>
                          </CardContent>
                        </Card>
                      </Tooltip>
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
