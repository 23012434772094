import {
  Divider,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  Icon,
  Autocomplete,
  TextField,
  Box,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { notifyShowErrorMessage } from "../../common/CommonActions";
import { useDispatch } from "react-redux";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { CompanyRepository } from "../../repositories/CompanyRepository";
import { CompanyStockDataRepository } from "../../repositories/CompanyStockDataRepository";
import moment from "moment";
import RSIChart from "../charts/RSIChart";
import OBVChart from "../charts/OBVChart";
import AroonOscilatorChart from "../charts/AroonOscilatorChart";
import ATRChart from "../charts/ATRChart";
import StochasticIndChart from "../charts/StochasticIndChart";
import ROCMomentumChart from "../charts/ROCMomentumChart";
import BollingerBands from "../charts/BollingerBandsChart";
import { useTheme } from "@emotion/react";

export default function CompanyChartData() {
  const [searchParams, setSearchParams] = useState({});
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [fullRSIData, setFullRSIData] = useState([]);
  const [fullOBVData, setFullOBVData] = useState([]);
  const [fullAroonData, setFullAroonData] = useState([]);
  const [fullATRData, setFullATRData] = useState([]);
  const [fullStochasticData, setFullStochasticData] = useState([]);
  const [fullRocMomentumData, setFullRocMomentumData] = useState([]);
  const [fullBollingerData, setFullBollingerData] = useState([]);
  const [RSIData, setRSIData] = useState([]);
  const [OBVData, setOBVData] = useState([]);
  const [AroonData, setAroonData] = useState([]);
  const [ATRData, setATRData] = useState([]);
  const [rocMomentumData, setRocMomemtumData] = useState([]);
  const [StochasticData, setStochasticData] = useState([]);
  const [BollingerData, setBollingerData] = useState([]);
  const [latestData, setLatestData] = useState();
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [brushStartIndex, setBrushStartIndex] = useState(0);
  const [brushEndIndex, setBrushEndIndex] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [brushStartIndexOBV, setBrushStartIndexOBV] = useState(0);
  const [brushEndIndexOBV, setBrushEndIndexOBV] = useState(0);
  const [latestDataOBV, setLatestDataOBV] = useState();
  const [latestDataAroon, setLatestDataAroon] = useState();
  const [brushStartIndexAroon, setBrushStartIndexAroon] = useState(0);
  const [brushEndIndexAroon, setBrushEndIndexAroon] = useState(0);
  const [latestDataATR, setLatestDataATR] = useState();
  const [brushStartIndexATR, setBrushStartIndexATR] = useState(0);
  const [brushEndIndexATR, setBrushEndIndexATR] = useState(0);
  const [latestDataStochastic, setLatestDataStochastic] = useState();
  const [brushStartIndexStochastic, setBrushStartIndexStochastic] = useState(0);
  const [brushEndIndexStochastic, setBrushEndIndexStochastic] = useState(0);
  const [latestDataRocMomentum, setLatestDataRocMomentum] = useState();
  const [brushStartIndexRocMomentum, setBrushStartIndexRocMomentum] =
    useState(0);
  const [brushEndIndexRocMomentum, setBrushEndIndexRocMomentum] = useState(0);
  const [latestDataBollinger, setLatestDataBollinger] = useState();
  const [brushStartIndexBollinger, setBrushStartIndexBollinger] = useState(0);
  const [brushEndIndexBollinger, setBrushEndIndexBollinger] = useState(0);
  const [globalSignal, setGlobalSignal] = useState("");

  const signalMap = {
    STRONG_SELL: -1.5,
    STRONG_BUY: 1.5,
    WEAK_BUY: 0.5,
    WEAK_SELL: -0.5,
    BUY: 3,
    SELL: -3,
  };

  const ATRSignalMap = {
    BUY: 1,
    SELL: -1,
    NEUTRAL: 0,
  };

  const StochasticSignalMap = {
    BUY: 1.5,
    SELL: -1.5,
    NEUTRAL: 0,
  };

  const RocMomentumAndBollingerBandsSignalMap = {
    BUY: 2,
    SELL: -2,
    NEUTRAL: 0,
  };

  useEffect(() => {
    if (selectedCompanyId) {
      getCompanyById(selectedCompanyId);
    }
  }, [searchParams, page, size]);

  useEffect(() => {
    if (selectedCompany && selectedTerm) {
      loadData();
    }
  }, [selectedCompany, selectedTerm]);

  useEffect(() => {
    let tmp = { ...searchParams };
    tmp["company.id"] = selectedCompanyId;
    setSearchParams(tmp);
  }, [selectedCompanyId]);

  useEffect(() => {
    CompanyRepository.getAllWithoutPaging()
      .then((res) => setCompanies(res.data))
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
  }, []);

  const getCompanyById = () => {
    CompanyRepository.getCompanyById(selectedCompanyId)
      .then((res) => {
        setSelectedCompany(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadData = () => {
    setLoading(true);
    CompanyStockDataRepository.calculateWithRSIIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            rsi: item?.rsPercentage?.toFixed(2),
            weakSellLevel: item?.weakSellLevel,
            weakBuyLevel: item?.weakBuyLevel,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
          };
        });
        setFullRSIData(finalData);
        setBrushEndIndex(finalData.length - 1);
        setBrushStartIndex(finalData.length - 30);
        setLatestData(finalData[finalData.length - 1]);
        setRSIData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithOBVIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber: signalMap[item?.signalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            volume: item?.volume,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
          };
        });
        setFullOBVData(finalData);
        setBrushEndIndexOBV(finalData.length - 1);
        setBrushStartIndexOBV(finalData.length - 30);
        setLatestDataOBV(finalData[finalData.length - 1]);
        setOBVData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
    CompanyStockDataRepository.calculateWithAroonIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber: signalMap[item?.signalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
            aroonUp: item?.aroonUp,
            aroonDown: item?.aroonDown,
            differenceAroonUpDown: item?.differenceAroonUpDown,
          };
        });
        setFullAroonData(finalData);
        setBrushEndIndexAroon(finalData.length - 1);
        setBrushStartIndexAroon(finalData.length - 30);
        setLatestDataAroon(finalData[finalData.length - 1]);
        setAroonData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithATRIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber: ATRSignalMap[item?.signalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
            avgTrueRange: item?.averageTrueRangeDifferences,
          };
        });
        setFullATRData(finalData);
        setBrushEndIndexATR(finalData.length - 1);
        setBrushStartIndexATR(finalData.length - 30);
        setLatestDataATR(finalData[finalData.length - 1]);
        setATRData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithStochasticIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber: StochasticSignalMap[item?.signalType],
            signalPercentage: item?.signalPercentage,
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
          };
        });
        setFullStochasticData(finalData);
        setBrushEndIndexStochastic(finalData.length - 1);
        setBrushStartIndexStochastic(finalData.length - 30);
        setLatestDataStochastic(finalData[finalData.length - 1]);
        setStochasticData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithROCMomentumIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber:
              RocMomentumAndBollingerBandsSignalMap[item?.signalType],
            rocSignal: item?.rocSignalType,
            rocSignalNumber:
              RocMomentumAndBollingerBandsSignalMap[item?.rocSignalType],
            momentumSignal: item?.momentumSignalType,
            momentumSignalNumber:
              RocMomentumAndBollingerBandsSignalMap[item?.momentumSignalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
          };
        });
        setFullRocMomentumData(finalData);
        setBrushEndIndexRocMomentum(finalData.length - 1);
        setBrushStartIndexRocMomentum(finalData.length - 30);
        setLatestDataRocMomentum(finalData[finalData.length - 1]);
        setRocMomemtumData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithBollingerIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber:
              RocMomentumAndBollingerBandsSignalMap[item?.signalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
            upperBand: item?.upperBand,
            lowerBand: item?.lowerBand,
            sma: item?.sma,
          };
        });
        setFullBollingerData(finalData);
        setBrushEndIndexBollinger(finalData.length - 1);
        setBrushStartIndexBollinger(finalData.length - 30);
        setLatestDataBollinger(finalData[finalData.length - 1]);
        setBollingerData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    setLoading(false);
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompanyId(newValue?.id);
    setPage(0);
  };

  const handleTermChange = (event) => {
    setSelectedTerm(event.target.value);
    setPage(0);
  };

  const getSignal = (signal) => {
    if (signal === "STRONG_SELL" || signal === "WEAK_SELL" || signal === "SELL")
      return "SELL";
    if (signal === "STRONG_BUY" || signal === "WEAK_BUY" || signal === "BUY")
      return "BUY";
    if (signal === "NEUTRAL") return "NEUTRAL";
    return "NEUTRAL";
  };

  useEffect(() => {
    const signals = [
      getSignal(latestData?.signal),
      getSignal(latestDataOBV?.signal),
      getSignal(latestDataAroon?.signal),
      getSignal(latestDataATR?.signal),
      getSignal(latestDataStochastic?.signal),
      getSignal(latestDataRocMomentum?.signal),
      getSignal(latestDataBollinger?.signal),
    ];

    const buyCount = signals.filter((signal) => signal === "BUY").length;
    const sellCount = signals.filter((signal) => signal === "SELL").length;
    const neutralCount = signals.filter(
      (signal) => signal === "NEUTRAL"
    ).length;

    let updatedGlobalSignal = "";
    if (buyCount > sellCount && buyCount > neutralCount) {
      updatedGlobalSignal = "BUY";
    } else if (sellCount > buyCount && sellCount > neutralCount) {
      updatedGlobalSignal = "SELL";
    } else {
      updatedGlobalSignal = "HOLD";
    }
    setGlobalSignal(updatedGlobalSignal);
  }, [
    latestData?.signal,
    latestDataOBV?.signal,
    latestDataAroon?.signal,
    latestDataATR?.signal,
    latestDataStochastic?.signal,
    latestDataRocMomentum?.signal,
    latestDataBollinger?.signal,
  ]);

  return (
    <Paper sx={{ padding: 5, borderRadius: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} pt={3}>
              <Typography
                variant="h3"
                sx={{ textAlign: { xs: "center", md: "start" } }}
              >
                Company Chart Data
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <label>Select Company</label>
              <Autocomplete
                fullWidth
                options={companies}
                getOptionLabel={(option) => option?.name || ""}
                value={selectedCompany}
                onChange={handleCompanyChange}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.isin}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.mutedBlue.main,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.mutedBlue.main,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.mutedBlue.main,
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: theme.palette.mutedBlue.main,
                },
              }}
            >
              <label>Select Term</label>
              <Select
                fullWidth
                value={selectedTerm}
                onChange={(e) => handleTermChange(e)}
                displayEmpty
                renderValue={(value) => (value ? value : "No term selected")}
              >
                <MenuItem value="">
                  <em>No term selected</em>
                </MenuItem>
                <MenuItem value="SIMPLE">Simple</MenuItem>
                <MenuItem value="SHORT_TERM">Short term</MenuItem>
                <MenuItem value="MID_TERM">Mid term</MenuItem>
                <MenuItem value="LONG_TERM">Long term</MenuItem>
              </Select>
            </Grid>
            {selectedTerm && selectedCompany && (
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "flex-end" },
                  marginTop: { xs: "10px", md: "-70px" },
                }}
              >
                <Box
                  sx={{
                    width: "250px",
                    padding: "20px 20px 20px",
                    border: "2px solid",
                    borderColor: globalSignal?.includes("BUY")
                      ? alpha("#81c784", 0.5)
                      : globalSignal?.includes("SELL")
                      ? alpha("#ef5350", 0.5)
                      : alpha("#ffd54f", 0.5),
                    borderRadius: "10px",
                    alignItems: "center",
                    textAlign: "center",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                    position: "relative",
                  }}
                >
                  <Typography variant="h4" mb={2}>
                    {selectedCompany?.name}
                  </Typography>
                  <Typography variant="h5" color="#156183">
                    Traders Rating:
                  </Typography>
                  <Typography
                    variant="h4"
                    color={
                      globalSignal?.includes("BUY")
                        ? "#81c784"
                        : globalSignal?.includes("SELL")
                        ? "#ef5350"
                        : "#ffd54f"
                    }
                    mb={2}
                  >
                    {globalSignal}
                  </Typography>
                  <Icon
                    sx={{ fontSize: "100px" }}
                    color={
                      globalSignal?.includes("BUY")
                        ? "success"
                        : globalSignal?.includes("SELL")
                        ? "error"
                        : "warning"
                    }
                  >
                    {globalSignal?.includes("BUY")
                      ? "thumb_up"
                      : globalSignal?.includes("SELL")
                      ? "thumb_down"
                      : "sentiment_neutral"}
                  </Icon>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ margin: "20px 0 15px 0" }} />

      <RSIChart
        RSIData={RSIData}
        latestData={latestData}
        setLatestData={setLatestData}
        brushStartIndex={brushStartIndex}
        brushEndIndex={brushEndIndex}
        setBrushStartIndex={setBrushStartIndex}
        setBrushEndIndex={setBrushEndIndex}
        left={left}
        right={right}
        height={450}
      />

      <Divider sx={{ margin: "20px 0 15px 0" }} />
      <OBVChart
        OBVData={OBVData}
        latestDataOBV={latestDataOBV}
        setLatestDataOBV={setLatestDataOBV}
        brushStartIndexOBV={brushStartIndexOBV}
        brushEndIndexOBV={brushEndIndexOBV}
        setBrushStartIndexOBV={setBrushStartIndexOBV}
        setBrushEndIndexOBV={setBrushEndIndexOBV}
        left={left}
        right={right}
        height={450}
      />

      <Divider sx={{ margin: "20px 0 15px 0" }} />
      <AroonOscilatorChart
        AroonData={AroonData}
        latestDataAroon={latestDataAroon}
        setLatestDataAroon={setLatestDataAroon}
        brushStartIndexAroon={brushStartIndexAroon}
        brushEndIndexAroon={brushEndIndexAroon}
        setBrushStartIndexAroon={setBrushStartIndexAroon}
        setBrushEndIndexAroon={setBrushEndIndexAroon}
        left={left}
        right={right}
        height={450}
      />

      <Divider sx={{ margin: "20px 0 15px 0" }} />
      <ATRChart
        ATRData={ATRData}
        latestDataATR={latestDataATR}
        setLatestDataATR={setLatestDataATR}
        brushStartIndexATR={brushStartIndexATR}
        brushEndIndexATR={brushEndIndexATR}
        setBrushStartIndexATR={setBrushStartIndexATR}
        setBrushEndIndexATR={setBrushEndIndexATR}
        left={left}
        right={right}
        height={450}
      />

      <Divider sx={{ margin: "20px 0 15px 0" }} />
      <StochasticIndChart
        StochasticData={StochasticData}
        latestDataStochastic={latestDataStochastic}
        setLatestDataStochastic={setLatestDataStochastic}
        brushStartIndexStochastic={brushStartIndexStochastic}
        brushEndIndexStochastic={brushEndIndexStochastic}
        setBrushStartIndexStochastic={setBrushStartIndexStochastic}
        setBrushEndIndexStochastic={setBrushEndIndexStochastic}
        left={left}
        right={right}
        height={450}
      />

      <Divider sx={{ margin: "20px 0 15px 0" }} />
      <ROCMomentumChart
        rocMomentumData={rocMomentumData}
        latestDataRocMomentum={latestDataRocMomentum}
        setLatestDataRocMomentum={setLatestDataRocMomentum}
        brushStartIndexRocMomentum={brushStartIndexRocMomentum}
        brushEndIndexRocMomentum={brushEndIndexRocMomentum}
        setBrushStartIndexRocMomentum={setBrushStartIndexRocMomentum}
        setBrushEndIndexRocMomentum={setBrushEndIndexRocMomentum}
        left={left}
        right={right}
        height={450}
      />

      <Divider sx={{ margin: "20px 0 15px 0" }} />
      <BollingerBands
        BollingerData={BollingerData}
        latestDataBollinger={latestDataBollinger}
        setLatestDataBollinger={setLatestDataBollinger}
        brushStartIndexBollinger={brushStartIndexBollinger}
        brushEndIndexBollinger={brushEndIndexBollinger}
        setBrushStartIndexBollinger={setBrushStartIndexBollinger}
        setBrushEndIndexBollinger={setBrushEndIndexBollinger}
        left={left}
        right={right}
        height={450}
      />
    </Paper>
  );
}
