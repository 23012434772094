import {
  Grid,
  Typography,
  Icon,
  Box,
  alpha,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  Tooltip,
  Button,
  Chip,
  Autocomplete,
  TextField,
  IconButton,
  useMediaQuery,
  FormControl,
  Grow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { notifyShowErrorMessage } from "../../common/CommonActions";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { UsersRepository } from "../../repositories/UsersRepository";
import { useDispatch } from "react-redux";
import moment from "moment";
import { SectorRepository } from "../../repositories/SectorRepository";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import {
  InfoOutlined,
  QueryStatsOutlined,
  Clear,
  FilterListOff,
  FilterList,
} from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function History() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [historyData, setHistoryData] = useState();
  const navigate = useNavigate();
  const [sectors, setSectors] = useState();
  const [sectorMap, setSectorMap] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [companySearchString, setCompanySearchString] = useState("");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [showFilters, setShowFilters] = useState(true);

  const consolidateHistoryData = (historyData) => {
    const consolidated = {};

    historyData.forEach((entry) => {
      if (!consolidated[entry.companyName]) {
        consolidated[entry.companyName] = {
          companyName: entry.companyName,
          companyISIN: entry.companyISIN,
          companyBloombergTicker: entry.companyBloombergTicker,
          sector: entry.sector,
          dateAccessed: entry.dateAccessed,
          indicatorTerm: entry.indicatorTerm,
        };
      } else {
        if (
          moment(entry.dateAccessed).isAfter(
            consolidated[entry.companyName].dateAccessed
          )
        ) {
          consolidated[entry.companyName].dateAccessed = entry.dateAccessed;
        }
      }
    });

    return Object.values(consolidated);
  };

  useEffect(() => {
    loadUserHistory();
    loadSectors();
  }, [searchParams]);

  const loadUserHistory = () => {
    setLoading(true);
    UsersRepository.getUserCompanyAccessWithoutPaging(
      JSON.stringify({ ...searchParams })
    )
      .then((res) => {
        const consolidatedData = consolidateHistoryData(res.data);
        setHistoryData(consolidatedData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage("Unable to load user history data!"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadSectors = () => {
    setLoading(true);
    SectorRepository.getAll()
      .then((res) => {
        setSectors(res.data);
        const map = {};
        res.data.forEach((sector) => {
          map[sector?.id] = sector?.name;
        });
        setSectorMap(map);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  const findSectorById = (sectorId) => {
    return sectorMap[sectorId] || "Unknown Sector";
  };

  useEffect(() => {
    if (companySearchString.length >= 3) {
      UsersRepository.getUserCompanyAccessWithoutPaging(
        JSON.stringify({ ...searchParams })
      )
        .then((res) => {
          const consolidatedData = consolidateHistoryData(res.data);
          setHistoryData(consolidatedData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [companySearchString]);

  const handleChange = (name, value) => {
    let tmp = { ...searchParams };
    tmp[name] = value;
    setSearchParams(tmp);
  };

  const handleDateChange = (name, event) => {
    let tmp = { ...searchParams };
    tmp[name] = event;
    setSearchParams(tmp);
  };

  const clearFilters = () => {
    setSearchParams({});
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Paper
            sx={{
              padding: 4,
              background: theme.palette.background.default,
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: alpha(theme.palette.mutedBlue.main, 0.8),
                  borderRadius: "10px",
                }}
              >
                <Typography variant="h4">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "14px",
                      color: theme.palette.dashboardBackground.main,
                    }}
                  >
                    Analysis History
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Last analysed companies
                        </Typography>
                      }
                    >
                      <InfoOutlined
                        sx={{
                          fontSize: "20px",
                          marginLeft: 1,
                          marginTop: "5px",
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={1.5}>
                    {" "}
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      onClick={toggleFilters}
                      sx={{
                        mb: 2,
                        fontSize: "14px",
                        fontWeight: "light",
                        color: theme.palette.background.paper,
                        backgroundColor: alpha(
                          theme.palette.mutedBlue.main,
                          0.8
                        ),
                        "&:hover": {
                          backgroundColor: alpha(
                            theme.palette.mutedBlue.main,
                            1
                          ),
                        },
                        borderRadius: "20px",
                      }}
                    >
                      {showFilters ? (
                        <FilterListOff sx={{ mr: 1, fontSize: "20px" }} />
                      ) : (
                        <FilterList sx={{ mr: 1, fontSize: "20px" }} />
                      )}
                      {showFilters ? "Hide Filters" : "Show Filters"}
                    </Button>
                  </Grid>
                  {showFilters && (
                    <>
                      <Grow in={showFilters}>
                        <Grid item xs={12} md={1.8}>
                          <Autocomplete
                            size="small"
                            autoComplete={false}
                            value={
                              searchParams["company.name"]
                                ? historyData?.find(
                                    (data) =>
                                      data.companyName ===
                                      searchParams["company.name"]
                                  )
                                : null
                            }
                            onChange={(event, newValue) => {
                              handleChange(
                                "company.name",
                                newValue?.companyName
                                  ? newValue.companyName
                                  : ""
                              );
                            }}
                            inputValue={
                              companySearchString ? companySearchString : ""
                            }
                            onInputChange={(event, newInputValue) => {
                              setCompanySearchString(newInputValue);
                            }}
                            options={historyData || []}
                            getOptionLabel={(option) => option?.companyName}
                            renderOption={(props, option) => (
                              <li {...props} key={option.isin}>
                                {option.companyName}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Company"
                                fullWidth
                                variant="outlined"
                              />
                            )}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: theme.palette.mutedBlue.main,
                                  },
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: theme.palette.mutedBlue.main,
                              },
                            }}
                          />
                        </Grid>
                      </Grow>

                      <Grow
                        in={showFilters}
                        {...(showFilters ? { timeout: 500 } : {})}
                      >
                        <Grid item xs={12} md={1.8}>
                          <TextField
                            onChange={(e) =>
                              handleChange("company.ISIN", e.target.value)
                            }
                            label={"ISIN"}
                            value={
                              searchParams["company.ISIN"]
                                ? searchParams["company.ISIN"]
                                : ""
                            }
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: theme.palette.mutedBlue.main,
                                  },
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: theme.palette.mutedBlue.main,
                              },
                            }}
                          />
                        </Grid>
                      </Grow>
                      <Grow
                        in={showFilters}
                        {...(showFilters ? { timeout: 750 } : {})}
                      >
                        <Grid item xs={12} md={1.8}>
                          <TextField
                            onChange={(e) =>
                              handleChange(
                                "company.bloombergTicker",
                                e.target.value
                              )
                            }
                            label={"Bloomberg Ticker"}
                            value={
                              searchParams["company.bloombergTicker"]
                                ? searchParams["company.bloombergTicker"]
                                : ""
                            }
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: theme.palette.mutedBlue.main,
                                  },
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: theme.palette.mutedBlue.main,
                              },
                            }}
                          />
                        </Grid>
                      </Grow>
                      <Grow
                        in={showFilters}
                        {...(showFilters ? { timeout: 900 } : {})}
                      >
                        <Grid item xs={12} md={1.8}>
                          <FormControl
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                minHeight: 35,
                                height: 35,
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: theme.palette.mutedBlue.main,
                                  },
                              },
                              "& .MuiInputLabel-root": {
                                transform: "translate(14px, 10px) scale(1)",
                                "&.Mui-focused, &.MuiFormLabel-filled": {
                                  transform:
                                    "translate(14px, -9px) scale(0.75)",
                                },
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: theme.palette.mutedBlue.main,
                              },
                              "& .MuiInputBase-input": {
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                              },
                              "& .MuiSvgIcon-root": {
                                color: theme.palette.mutedBlue.main,
                              },
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Date Accessed"
                                value={
                                  searchParams?.dateAccessed
                                    ? searchParams?.dateAccessed
                                    : null
                                }
                                format="dd/MM/yyyy"
                                onChange={(e) =>
                                  handleDateChange("dateAccessed", e)
                                }
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </Grow>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "16px",
                        }}
                      >
                        {Object.keys(searchParams).length > 0 && (
                          <Tooltip
                            title={
                              <Typography variant="h6">
                                Clear filters
                              </Typography>
                            }
                          >
                            {isSmallScreen ? (
                              <Button
                                variant="contained"
                                color="mutedBlue"
                                sx={{
                                  color: theme.palette.background.paper,
                                  backgroundColor: alpha(
                                    theme.palette.mutedBlue.main,
                                    0.8
                                  ),
                                  "&:hover": {
                                    backgroundColor: alpha(
                                      theme.palette.mutedBlue.main,
                                      1
                                    ),
                                  },
                                }}
                                fullWidth
                                onClick={() => clearFilters()}
                              >
                                Clear filters
                              </Button>
                            ) : (
                              <IconButton onClick={() => clearFilters()}>
                                <Clear sx={{ fontSize: "22px" }} />
                              </IconButton>
                            )}
                          </Tooltip>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: theme.palette.lightGray.light,
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: theme.palette.lightGray.main,
                    },
                    borderRadius: "10px",
                    border: `1px solid ${alpha(
                      theme.palette.lightGray.main,
                      0.25
                    )}`,
                  }}
                >
                  <Table>
                    <TableHead
                      sx={{
                        position: "sticky",
                        top: 0,
                        backgroundColor:
                          theme.palette.mode == "dark"
                            ? "#2b2b2b"
                            : theme.palette.mode == "light"
                            ? "#e3e3e3"
                            : "transparent",
                        height: "40px",
                        zIndex: 5,
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" sx={{ fontWeight: "light" }}>
                            Company
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5" sx={{ fontWeight: "light" }}>
                            ISIN
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5" sx={{ fontWeight: "light" }}>
                            Bloomberg Ticker
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5" sx={{ fontWeight: "light" }}>
                            Sector
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5" sx={{ fontWeight: "light" }}>
                            Indicator Term
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5" sx={{ fontWeight: "light" }}>
                            Date Accessed
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5" sx={{ fontWeight: "light" }}>
                            Action
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!loading && historyData && historyData.length === 0 && (
                        <TableRow
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.darkContrast.main,
                              0.03
                            ),
                          }}
                        >
                          <TableCell
                            style={{
                              borderBottom: "0px solid white",
                              textAlign: "center",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                            colSpan={7}
                          >
                            <b
                              style={{
                                font: "-moz-initial",
                                color: "#adb5bd",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                marginLeft: 10,
                                marginTop: 10,
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "light", paddingBottom: 1.5 }}
                              >
                                <Icon
                                  style={{
                                    verticalAlign: "middle",
                                    fontSize: "25px",
                                  }}
                                  color="gray"
                                >
                                  manage_search
                                </Icon>{" "}
                                No data available
                              </Typography>
                            </b>
                          </TableCell>
                        </TableRow>
                      )}
                      {historyData &&
                        historyData.map((history, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              transition: "all 0.2s ease-in-out",
                              "&:hover": {
                                backgroundColor: alpha(
                                  theme.palette.mutedBlue.main,
                                  0.1
                                ),
                              },
                              height: "50px",
                              zIndex: 3,
                            }}
                          >
                            <TableCell>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "light" }}
                              >
                                {history.companyName}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h6">
                                {history.companyISIN}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle1">
                                {history.companyBloombergTicker}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle1">
                                {history.sector?.name
                                  ? history.sector.name
                                  : findSectorById(history.sector)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Chip
                                label={
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontWeight: "light",
                                      color:
                                        theme.palette.mode == "dark"
                                          ? "black"
                                          : "white",
                                    }}
                                  >
                                    {history.indicatorTerm === "SIMPLE"
                                      ? "Simple"
                                      : history.indicatorTerm === "SHORT_TERM"
                                      ? "Short Term"
                                      : history.indicatorTerm === "MID_TERM"
                                      ? "Mid Term"
                                      : history.indicatorTerm === "LONG_TERM"
                                      ? "Long Term"
                                      : "Unknown Term"}
                                  </Typography>
                                }
                                style={{
                                  backgroundColor:
                                    history.indicatorTerm === "SIMPLE"
                                      ? alpha(theme.palette.primary.main, 0.8)
                                      : history.indicatorTerm === "SHORT_TERM"
                                      ? theme.palette.error.main
                                      : history.indicatorTerm === "MID_TERM"
                                      ? theme.palette.warning.main
                                      : history.indicatorTerm === "LONG_TERM"
                                      ? theme.palette.success.main
                                      : alpha(
                                          theme.palette.lightGray.main,
                                          0.5
                                        ),
                                  width: "120px",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                {moment(history.dateAccessed).format(
                                  "D MMMM YYYY"
                                )}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                At{" "}
                                {moment(history.dateAccessed).format("h:mm A")}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                title={
                                  <Typography variant="h6">
                                    Analyse again
                                  </Typography>
                                }
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="lightGray"
                                  onClick={() =>
                                    navigate(
                                      `/?company=${encodeURIComponent(
                                        history.companyName
                                      )}`
                                    )
                                  }
                                >
                                  <QueryStatsOutlined />
                                </Button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
