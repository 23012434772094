import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const SectorRepository = {
  getAll: () => {
    return Axios({
      url: `${SETTINGS.API_URL}/sectors`,
      method: "GET",
    });
  },
};
