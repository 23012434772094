import * as Yup from "yup";
import YupPassword from "yup-password";
import { LOCALE } from "../../properties/Locale";
YupPassword(Yup);

export const PasswordValidator = Yup.object().shape({
  oldPassword: Yup.string().required(LOCALE.required_field),
  newPassword: Yup.string()
    .required(LOCALE.required_field)
    .min(
      8,
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});

export const ForgotPasswordResetValidator = Yup.object().shape({
  newPassword: Yup.string()
    .required(LOCALE.required_field)
    .min(
      8,
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});
