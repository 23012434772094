import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { CheckCircle, Payment } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { alpha, useMediaQuery } from "@mui/system";

const StyledCard = styled(({ isSelected, ...props }) => <Card {...props} />)(
  ({ theme, variant, isSelected }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s ease-in-out",
    position: "relative",
    "&:hover": {
      transform: "translateY(-10px)",
    },
    boxShadow: "0 4px 5px 5px rgba(0, 0, 0, 0.05)",
    backgroundColor:
      variant === "advanced" && isSelected
        ? alpha("#1976d2", 0.15)
        : variant === "advanced"
        ? alpha("#7662a3", 0.15)
        : theme.palette.background.paper,
    border:
      variant === "advanced" && isSelected
        ? "2px solid #1976d2"
        : variant === "advanced"
        ? "2px solid #7662a3"
        : isSelected
        ? "2px solid #1976d2"
        : "none",
    borderRadius: "20px",
  })
);

const PlanButton = styled(Button)({
  marginTop: "auto",
  padding: "12px",
  fontWeight: "light",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "14px",
});

const FeatureItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  gap: "8px",
});

export default function MyPlan() {
  const theme = useTheme();
  const [selectedPlan, setSelectedPlan] = useState("Advanced");
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const plans = [
    {
      title: "Starter",
      price: "$29",
      description: "Perfect for beginners looking to start investing",
      features: [
        "1-on-1 Introductory Session",
        "Basic Market Analysis",
        "Monthly Newsletter",
        "Access to Webinars",
        "Investment Basics Guide",
        "Email Support",
      ],
      variant: "starter",
      popular: false,
    },
    {
      title: "Intermediate",
      price: "$59",
      description: "Ideal for individuals ready to grow their portfolio",
      features: [
        "2-on-1 Strategy Session",
        "Weekly Market Insights",
        "Customized Investment Plan",
        "Access to Exclusive Reports",
        "Priority Email Support",
        "Community Forum Access",
        "Quarterly Portfolio Review",
      ],
      variant: "intermediate",
      popular: false,
    },
    {
      title: "Advanced",
      price: "$99",
      description:
        "Best value for active investors seeking personalized advice",
      features: [
        "Monthly 1-on-1 Strategy Call",
        "Real-time Market Alerts",
        "Advanced Portfolio Review",
        "Risk Assessment Analysis",
        "Access to Premium Content",
        "Dedicated Support",
        "Personalized Investment Strategies",
        "Access to Exclusive Market Webinars",
      ],
      variant: "advanced",
      popular: true,
    },
    {
      title: "Pro",
      price: "$199",
      description: "Comprehensive counseling for serious investors",
      features: [
        "Unlimited Strategy Sessions",
        "24/7 Market Access",
        "Tailored Investment Strategies",
        "Custom Analytics Dashboard",
        "Private Community Access",
        "Priority Phone Support",
        "Personal Financial Planning",
        "Advanced Risk Management Tools",
        "Access to Investment Workshops",
      ],
      variant: "pro",
      popular: false,
    },
  ];

  const handleSelectPlan = (planTitle) => {
    setSelectedPlan(planTitle);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 1 }}>
      <Paper sx={{ padding: 6, borderRadius: "50px" }}>
        <Box textAlign="center" mb={6}>
          <Typography
            variant={isMobile ? "h3" : "h2"}
            gutterBottom
            fontWeight="bold"
            color={theme.palette.mutedBlue.main}
          >
            Choose your desired plan
          </Typography>
          <Typography
            variant="h5"
            color="text.secondary"
            mb={4}
            sx={{ fontWeight: "light", lineHeight: 1.5 }}
          >
            Choose the perfect plan that suits your needs. Upgrade or downgrade
            at any time. We have a plan for everyone.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={3} key={plan.title}>
              <StyledCard
                variant={plan.variant}
                isSelected={selectedPlan === plan.title}
                role="region"
                aria-label={`${plan.title} Plan`}
              >
                {plan.popular && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 20,
                      right: -30,
                      transform: "rotate(45deg)",
                      backgroundColor:
                        selectedPlan == plan.title ? "#1976d2" : "#7662a3",
                      color: "white",
                      px: 5,
                      py: 0.5,
                    }}
                  >
                    Popular
                  </Box>
                )}
                {selectedPlan === plan.title && (
                  <Typography
                    variant="h4"
                    component="div"
                    color="white"
                    sx={{
                      position: "absolute",
                      top: 10,
                      left: 10,
                      fontWeight: "light",
                      backgroundColor: theme.palette.info.main,
                      padding: "4px 12px",
                      borderRadius: "20px",
                    }}
                  >
                    Selected Plan
                  </Typography>
                )}
                <CardContent
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    fontWeight="bold"
                  >
                    {plan.title}
                  </Typography>
                  <Typography
                    variant="h3"
                    component="p"
                    gutterBottom
                    color={
                      plan.popular && selectedPlan == plan.title
                        ? "#1976d2"
                        : plan.popular
                        ? "#7662a3"
                        : theme.palette.mutedBlue.main
                    }
                  >
                    {plan.price}
                    <Typography
                      variant="subtitle1"
                      component="span"
                      color="text.secondary"
                    >
                      /month
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    mb={1}
                    minHeight="60px"
                  >
                    {plan.description}
                  </Typography>

                  <Box mb={3} flex={1}>
                    {plan.features.map((feature) => (
                      <FeatureItem key={feature} pb={1}>
                        <CheckCircle color="#1976d2" />
                        <Typography variant="h6" sx={{ fontWeight: "light" }}>
                          {feature}
                        </Typography>
                      </FeatureItem>
                    ))}
                  </Box>

                  <PlanButton
                    variant="contained"
                    color={selectedPlan === plan.title ? "info" : "inherit"}
                    fullWidth
                    onClick={() => {
                      if (selectedPlan === plan.title) {
                        setOpenDialog(true);
                      } else {
                        handleSelectPlan(plan.title);
                      }
                    }}
                    sx={{
                      color: selectedPlan === plan.title ? "white" : "inherit",
                      backgroundColor:
                        selectedPlan !== plan.title &&
                        plan.title == "Advanced" &&
                        theme.palette.mode == "light"
                          ? theme.palette.background.paper
                          : selectedPlan !== plan.title &&
                            plan.title == "Advanced" &&
                            theme.palette.mode == "dark"
                          ? alpha(theme.palette.lightGray.main, 0.75)
                          : "info",
                    }}
                  >
                    {selectedPlan === plan.title
                      ? "Continue to Payment"
                      : "Choose Plan"}
                  </PlanButton>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Paper>
      {/* Dialog for payment confirmation */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: { pt: 1, px: 3, pb: 2, borderRadius: 3, width: 400 },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", pb: 1 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Payment
              fontSize="large"
              sx={{ color: theme.palette.mutedBlue.main }}
            />
            <Typography
              variant="h5"
              sx={{ color: theme.palette.mutedBlue.main }}
            >
              Confirm Your Plan
            </Typography>
          </Box>
        </DialogTitle>
        <Divider sx={{ my: 1 }} />
        <DialogContent
          sx={{
            textAlign: "center",
            py: 2,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            sx={{ fontWeight: "light" }}
          >
            Plan Selected:
            <span
              style={{
                color: theme.palette.mutedBlue.main,
                fontSize: "24px",
              }}
            >
              {" "}
              {selectedPlan}
            </span>
          </Typography>
          <Typography
            variant="h5"
            color="text.secondary"
            sx={{ fontWeight: "light", paddingTop: 1 }}
          >
            Price:
            <span
              style={{
                color: theme.palette.mutedBlue.main,
                fontSize: "24px",
              }}
            >
              {" "}
              {plans.find((plan) => plan.title === selectedPlan)?.price}
            </span>
            <Typography
              variant="subtitle1"
              component="span"
              color="text.secondary"
              sx={{ marginLeft: "4px" }}
            >
              /month
            </Typography>
          </Typography>
          <Typography
            variant="h5"
            color="text.secondary"
            sx={{ mt: 2, fontWeight: "light" }}
          >
            Would you like to proceed to payment?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pt: 3 }}>
          <Button
            onClick={handleCloseDialog}
            color="inherit"
            sx={{
              borderRadius: 3,
              fontWeight: "bold",
              textTransform: "none",
              fontSize: "14px",
              color: theme.palette.mutedBlue.main,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="mutedBlue"
            sx={{
              borderRadius: 3,
              fontWeight: "bold",
              textTransform: "none",
              px: 3,
              fontSize: "14px",
              color: "white",
              backgroundColor: theme.palette.mutedBlue.main,
            }}
            onClick={() => alert("Proceeding to payment")}
          >
            Proceed to Payment
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
