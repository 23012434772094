import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const CompanyStockDataRepository = {
  uploadData: (file) => {
    const data = new FormData();
    data.append("file", file);
    return Axios({
      url: `${SETTINGS.API_URL}/stockData/uploadData`,
      method: "PUT",
      data: data,
    });
  },
  calculateWithIndicator: (page, size, companyId, indicator, term) => {
    return Axios({
      url: `${SETTINGS.API_URL}/stockData/calculateWithIndicator`,
      method: "GET",
      params: {
        page: page,
        size: size,
        orderBy: "date",
        orderDirection: "ASC",
        companyId: companyId,
        indicator: indicator,
        term: term,
      },
    });
  },
  calculateWithRSIIndicatorWithoutPaging: (companyId, term) => {
    return Axios({
      url:
        SETTINGS.API_URL + `/stockData/calculateWithRSIIndicatorWithoutPaging`,
      method: "GET",
      params: {
        companyId: companyId,
        term: term,
      },
    });
  },
  calculateWithOBVIndicatorWithoutPaging: (companyId, term) => {
    return Axios({
      url:
        SETTINGS.API_URL + `/stockData/calculateWithOBVIndicatorWithoutPaging`,
      method: "GET",
      params: {
        companyId: companyId,
        term: term,
      },
    });
  },
  calculateWithAroonIndicatorWithoutPaging: (companyId, term) => {
    return Axios({
      url:
        SETTINGS.API_URL +
        `/stockData/calculateWithAroonIndicatorWithoutPaging`,
      method: "GET",
      params: {
        companyId: companyId,
        term: term,
      },
    });
  },
  calculateWithATRIndicatorWithoutPaging: (companyId, term) => {
    return Axios({
      url:
        SETTINGS.API_URL + `/stockData/calculateWithATRIndicatorWithoutPaging`,
      method: "GET",
      params: {
        companyId: companyId,
        term: term,
      },
    });
  },
  calculateWithStochasticIndicatorWithoutPaging: (companyId, term) => {
    return Axios({
      url:
        SETTINGS.API_URL +
        `/stockData/calculateWithStochasticIndicatorWithoutPaging`,
      method: "GET",
      params: {
        companyId: companyId,
        term: term,
      },
    });
  },
  calculateWithROCMomentumIndicatorWithoutPaging: (companyId, term) => {
    return Axios({
      url:
        SETTINGS.API_URL +
        `/stockData/calculateWithROCMomentumIndicatorWithoutPaging`,
      method: "GET",
      params: {
        companyId: companyId,
        term: term,
      },
    });
  },
  calculateWithBollingerIndicatorWithoutPaging: (companyId, term) => {
    return Axios({
      url:
        SETTINGS.API_URL +
        `/stockData/calculateWithBollingerIndicatorWithoutPaging`,
      method: "GET",
      params: {
        companyId: companyId,
        term: term,
      },
    });
  },

  getAll: (page, size, searchParams) => {
    return Axios({
      url: `${SETTINGS.API_URL}/stockData`,
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: searchParams,
        orderBy: "date",
        orderDirection: "ASC",
      },
    });
  },
};
