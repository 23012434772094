import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const UsersRepository = {
  getAll: (page, size, searchParams) => {
    return Axios({
      url: `${SETTINGS.API_URL}/users`,
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: searchParams,
        orderBy: "dateCreated",
        orderDirection: "DESC",
      },
    });
  },
  getUserCompanyAccess: (page, size) => {
    return Axios({
      url: `${SETTINGS.API_URL}/users/companyAccess`,
      method: "GET",
      params: {
        page: page,
        size: size,
        orderBy: "dateAccessed",
        orderDirection: "DESC",
      },
    });
  },
  getUserCompanyAccessWithoutPaging: (searchParams) => {
    return Axios({
      url: `${SETTINGS.API_URL}/users/companyAccessWithoutPaging`,
      method: "GET",
      params: {
        searchParams: searchParams,
      },
    });
  },
  getUserCompanyAccessFavorites: () => {
    return Axios({
      url: `${SETTINGS.API_URL}/users/companyAccessFavorites`,
      method: "GET",
    });
  },

  getUser: () => {
    return Axios({
      url: `${SETTINGS.API_URL}/users/getCurrentUser`,
      method: "GET",
    });
  },
  resetPassword: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}/users/reset_password`,
      method: "PUT",
      params: {
        username: data.username,
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
      },
    });
  },
  forgotPasswordRequest: (email) => {
    return Axios({
      url: `${SETTINGS.API_URL}/register/user/forgotPassword`,
      method: "PUT",
      params: {
        email: email,
      },
    });
  },
  forgotPasswordCheck: (token) => {
    return Axios({
      url: `${SETTINGS.API_URL}/register/user/forgotPasswordCheck`,
      method: "GET",
      params: {
        token: encodeURIComponent(token),
      },
    });
  },
  forgotPasswordReset: (token, newPassword) => {
    return Axios({
      url: `${SETTINGS.API_URL}/register/user/forgotPasswordReset`,
      method: "PUT",
      params: {
        token: encodeURIComponent(token),
        password: newPassword,
      },
    });
  },

  updateUser: (token, user) => {
    return Axios({
      url: `${SETTINGS.API_URL}/users`,
      method: "PUT",
      params: {
        token: token,
      },
      data: user,
    });
  },
};
