import {
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { AuthRepository } from "../../repositories/AuthRepository";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function HomeComponentAdmin() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let components = [
    {
      path: "/myProfile",
      title: "My Profile",
      icon: "person",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/users`,
      title: "Users",
      icon: "group",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/companies`,
      title: "Companies",
      icon: "apartment",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/chartData`,
      title: "Company Chart Data",
      icon: "show_chart",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/stockData`,
      title: "Company Stock Data",
      icon: "add_chart",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/profileSettings`,
      title: "Profile Settings",
      icon: "admin_panel_settings",
      roles: ["ROLE_ADMINISTRATION"],
    },
    {
      path: `/indicatorSettings`,
      title: "Indicator Settings",
      icon: "app_settings_alt",
      roles: ["ROLE_ADMINISTRATION"],
    },
  ];

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          justifyContent={
            components.length % 3 === 1 || components.length % 3 === 2
              ? "center"
              : "flex-start"
          }
        >
          {" "}
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              paddingTop: "5px",
            }}
          >
            {!isSmallScreen && (
              <img
                alt=""
                src={
                  theme.palette.mode == "dark"
                    ? require("../../assets/img/capitelize_logo_inverted.png")
                    : require("../../assets/img/capitelize_logo.png")
                }
                width="150px"
                onClick={() => navigate("/home")}
                style={{
                  cursor: "pointer",
                  marginLeft: "-10px",
                  borderRadius: "20px",
                }}
              ></img>
            )}

            <Typography
              fontSize={65}
              sx={{
                color: theme.palette.darkContrast.main,
              }}
            >
              CapitElize
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Divider variant="middle" sx={{ mb: 3 }} />
          </Grid>
          {components.map(
            (component, index) =>
              AuthRepository.hasAnyRole(component.roles) && (
                <Grid item md={4} xs={12} key={index}>
                  <Card
                    sx={{
                      height: { xs: "90px", md: "120px" },
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: theme.palette.mutedBlue.main,
                      },
                    }}
                    onClick={() => navigate(component.path)}
                    className="roundedBorder administration_landing_page_btn"
                  >
                    <CardContent>
                      <Icon sx={{ fontSize: { xs: "25px", md: "35px" } }}>
                        {component.icon}
                      </Icon>
                      <Typography variant="h5">{component.title}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      </Container>
    </>
  );
}
