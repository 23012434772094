import React, { useState } from "react";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Typography,
  Grid,
  Icon,
  useMediaQuery,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const BollingerBands = ({
  BollingerData,
  latestDataBollinger,
  setLatestDataBollinger,
  brushStartIndexBollinger,
  brushEndIndexBollinger,
  setBrushStartIndexBollinger,
  setBrushEndIndexBollinger,
  left,
  right,
  height,
}) => {
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={9}
          style={{ textAlign: isSmallScreen && "center" }}
        >
          <Typography
            variant="h5"
            style={{ color: theme.palette.mutedBlue.main }}
          >
            Indicator 7: Bollinger Bands
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer height={height}>
              <ComposedChart data={BollingerData}>
                <CartesianGrid strokeDasharray="0.1 0.1" />
                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  domain={[left, right]}
                  type="category"
                />
                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[-2.5, 2.5]}
                  ticks={[-2.5, -2, -1.5, -1, -0.5, 0, 0.5, 1, 1.5, 2, 2.5]}
                  type="number"
                  yAxisId="2"
                />
                <YAxis
                  orientation="left"
                  allowDataOverflow
                  domain={[0, "dataMax + 50"]}
                  type="number"
                  yAxisId="1"
                  tickFormatter={(value) => Math.floor(value)}
                />
                <Tooltip
                  cursor={false}
                  formatter={(value, name, props) => [
                    `${
                      name == "Upper Band" ||
                      name == "Lower Band" ||
                      name == "SMA"
                        ? value?.toFixed(2)
                        : value
                    } - ${name == "Signal" ? "" : name} ${
                      name == "Signal" ? props.payload.signal + " - Signal" : ""
                    }`,
                  ]}
                  labelFormatter={(value) => `Date: ${value}`}
                  itemStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                />
                <Brush
                  onChange={(e) => {
                    let item;
                    BollingerData.map((d, index) => {
                      if (d.index === e.endIndex) {
                        item = d;
                      }
                    });
                    setLatestDataBollinger(item);
                    setBrushStartIndexBollinger(e.startIndex);
                    setBrushEndIndexBollinger(e.endIndex);
                  }}
                  data={BollingerData}
                  dataKey="index"
                  height={20}
                  fill={`${theme.palette.background.paper}`}
                  startIndex={brushStartIndexBollinger}
                  endIndex={brushEndIndexBollinger}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="center"
                />

                <Line
                  yAxisId="1"
                  type="linear"
                  dataKey="price"
                  name="Price"
                  stroke="black"
                  strokeWidth={3}
                  dot={false}
                  animationDuration={3000}
                />
                <Line
                  yAxisId="1"
                  type="linear"
                  dataKey="upperBand"
                  name="Upper Band"
                  stroke="#d32626"
                  strokeWidth={3}
                  strokeDasharray="15 15"
                  dot={false}
                  animationDuration={3000}
                />
                <Line
                  yAxisId="1"
                  type="linear"
                  dataKey="lowerBand"
                  name="Lower Band"
                  stroke="#10b65b"
                  strokeWidth={3}
                  strokeDasharray="15 15"
                  dot={false}
                  animationDuration={3000}
                />
                <Line
                  yAxisId="1"
                  type="linear"
                  dataKey="sma"
                  name="SMA"
                  stroke="#7f7e7e"
                  strokeWidth={3}
                  dot={false}
                  animationDuration={3000}
                />

                <Bar
                  yAxisId="2"
                  dataKey="signalNumber"
                  name="Signal"
                  fill="#6684b6"
                  barSize={1.5}
                />

                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          style={{ textAlign: isSmallScreen ? "center" : "left" }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align={isSmallScreen ? "center" : "left"}
                >
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.mutedBlue.main }}
                  >
                    {latestDataBollinger?.name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Date:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataBollinger?.date}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Price:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataBollinger?.price?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Upper Band:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataBollinger?.upperBand?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Lower Band:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataBollinger?.lowerBand?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">SMA:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataBollinger?.sma?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Signal Number:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataBollinger?.signalNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Signal:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataBollinger?.signal === "SELL"
                      ? "SELL"
                      : latestDataBollinger?.signal === "BUY"
                      ? "BUY"
                      : latestDataBollinger?.signal === "NEUTRAL"
                      ? "NEUTRAL"
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  sx={{ borderBottom: "none" }}
                >
                  <Icon
                    sx={{ fontSize: "100px", marginTop: "10px" }}
                    color={
                      latestDataBollinger?.signal?.includes("BUY")
                        ? "success"
                        : latestDataBollinger?.signal?.includes("SELL")
                        ? "error"
                        : "warning"
                    }
                  >
                    {latestDataBollinger?.signal?.includes("BUY")
                      ? "thumb_up"
                      : latestDataBollinger?.signal?.includes("SELL")
                      ? "thumb_down"
                      : "sentiment_neutral"}
                  </Icon>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>{" "}
      </Grid>
    </>
  );
};

export default BollingerBands;
