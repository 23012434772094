import React, { useState } from "react";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Typography,
  Grid,
  Icon,
  useMediaQuery,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const ROCMomentumChart = ({
  rocMomentumData,
  latestDataRocMomentum,
  setLatestDataRocMomentum,
  brushStartIndexRocMomentum,
  brushEndIndexRocMomentum,
  setBrushStartIndexRocMomentum,
  setBrushEndIndexRocMomentum,
  left,
  right,
  height,
}) => {
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={9}
          style={{ textAlign: isSmallScreen && "center" }}
        >
          <Typography
            variant="h5"
            style={{ color: theme.palette.mutedBlue.main }}
          >
            Indicator 6: ROC & Momentum{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer height={height}>
              <ComposedChart data={rocMomentumData}>
                <CartesianGrid strokeDasharray="0.1 0.1" />
                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  domain={[left, right]}
                  type="category"
                />
                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[-1.5, 1.5]}
                  ticks={[-1.5, -1, -0.5, 0, 1, 1.5]}
                  type="number"
                  yAxisId="1"
                />
                <YAxis
                  orientation="left"
                  allowDataOverflow
                  domain={["dataMin - 10", "dataMax + 10"]}
                  type="number"
                  tickFormatter={(value) => value?.toFixed(2)}
                  yAxisId="2"
                />
                <Tooltip
                  cursor={false}
                  formatter={(value, name, props) => [
                    `${name == "Price" ? value?.toFixed(2) : value} - ${
                      name == "ROC Signal" ? "" : name
                    } ${
                      name == "ROC Signal"
                        ? props.payload.signal + " - ROC Signal"
                        : ""
                    }`,
                  ]}
                  labelFormatter={(value) => `Date: ${value}`}
                  itemStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                />
                <Brush
                  onChange={(e) => {
                    let item;
                    rocMomentumData.map((d, index) => {
                      if (d.index === e.endIndex) {
                        item = d;
                      }
                    });
                    setLatestDataRocMomentum(item);
                    setBrushStartIndexRocMomentum(e.startIndex);
                    setBrushEndIndexRocMomentum(e.endIndex);
                  }}
                  data={rocMomentumData}
                  dataKey="index"
                  height={20}
                  fill={`${theme.palette.background.paper}`}
                  startIndex={brushStartIndexRocMomentum}
                  endIndex={brushEndIndexRocMomentum}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="center"
                />

                <Line
                  yAxisId="2"
                  type="linear"
                  dataKey="price"
                  name="Price"
                  stroke="#5f8fb5"
                  strokeWidth={5}
                  dot={false}
                  animationDuration={3000}
                />

                <Bar
                  yAxisId="1"
                  dataKey="rocSignalNumber"
                  name="ROC Signal"
                  fill="#f29157"
                  barSize={1.5}
                />

                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          style={{ textAlign: isSmallScreen ? "center" : "left" }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align={isSmallScreen ? "center" : "left"}
                >
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.mutedBlue.main }}
                  >
                    {latestDataRocMomentum?.name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Date:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataRocMomentum?.date}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Price:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataRocMomentum?.price?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">ROC Signal Numeric:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataRocMomentum?.rocSignalNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">ROC Signal:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataRocMomentum?.rocSignal === "BUY"
                      ? "BUY"
                      : latestDataRocMomentum?.rocSignal === "SELL"
                      ? "SELL"
                      : latestDataRocMomentum?.rocSignal === "NEUTRAL"
                      ? "NEUTRAL"
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Momentum Signal Numeric:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataRocMomentum?.momentumSignalNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Momentum Signal:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataRocMomentum?.momentumSignal === "BUY"
                      ? "BUY"
                      : latestDataRocMomentum?.momentumSignal === "SELL"
                      ? "SELL"
                      : latestDataRocMomentum?.momentumSignal === "NEUTRAL"
                      ? "NEUTRAL"
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Signal Numeric:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataRocMomentum?.signalNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Signal:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataRocMomentum?.signal === "BUY"
                      ? "BUY"
                      : latestDataRocMomentum?.signal === "SELL"
                      ? "SELL"
                      : latestDataRocMomentum?.signal === "NEUTRAL"
                      ? "NEUTRAL"
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  sx={{ borderBottom: "none" }}
                >
                  <Icon
                    sx={{ fontSize: "100px", marginTop: "10px" }}
                    color={
                      latestDataRocMomentum?.signal?.includes("BUY")
                        ? "success"
                        : latestDataRocMomentum?.signal?.includes("SELL")
                        ? "error"
                        : "warning"
                    }
                  >
                    {latestDataRocMomentum?.signal?.includes("BUY")
                      ? "thumb_up"
                      : latestDataRocMomentum?.signal?.includes("SELL")
                      ? "thumb_down"
                      : "sentiment_neutral"}
                  </Icon>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>{" "}
        <Grid item xs={12} md={8}>
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer height={height}>
              <ComposedChart data={rocMomentumData}>
                <CartesianGrid strokeDasharray="0.1 0.1" />
                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  domain={[left, right]}
                  type="category"
                />
                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[-1.5, 1.5]}
                  ticks={[-1.5, -1, -0.5, 0, 1, 1.5]}
                  type="number"
                  yAxisId="1"
                />
                <YAxis
                  orientation="left"
                  allowDataOverflow
                  domain={["dataMin - 10", "dataMax + 10"]}
                  type="number"
                  tickFormatter={(value) => value?.toFixed(2)}
                  yAxisId="2"
                />
                <Tooltip
                  cursor={false}
                  formatter={(value, name, props) => [
                    `${name == "Price" ? value?.toFixed(2) : value} - ${
                      name == "Momentum Signal" ? "" : name
                    } ${
                      name == "Momentum Signal"
                        ? props.payload.signal + " - Momentum Signal"
                        : ""
                    }`,
                  ]}
                  labelFormatter={(value) => `Date: ${value}`}
                  itemStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                />
                <Brush
                  onChange={(e) => {
                    let item;
                    rocMomentumData.map((d, index) => {
                      if (d.index === e.endIndex) {
                        item = d;
                      }
                    });
                    setLatestDataRocMomentum(item);
                    setBrushStartIndexRocMomentum(e.startIndex);
                    setBrushEndIndexRocMomentum(e.endIndex);
                  }}
                  data={rocMomentumData}
                  dataKey="index"
                  height={20}
                  fill={`${theme.palette.background.paper}`}
                  startIndex={brushStartIndexRocMomentum}
                  endIndex={brushEndIndexRocMomentum}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="center"
                />

                <Line
                  yAxisId="2"
                  type="linear"
                  dataKey="price"
                  name="Price"
                  stroke="#5f8fb5"
                  strokeWidth={5}
                  dot={false}
                  animationDuration={3000}
                />

                <Bar
                  yAxisId="1"
                  dataKey="momentumSignalNumber"
                  name="Momentum Signal"
                  fill="#f29157"
                  barSize={1.5}
                />

                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer height={height}>
              <ComposedChart data={rocMomentumData}>
                <CartesianGrid strokeDasharray="0.1 0.1" />
                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  domain={[left, right]}
                  type="category"
                />
                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[-1.5, 1.5]}
                  ticks={[-1.5, -1, -0.5, 0, 1, 1.5]}
                  type="number"
                  yAxisId="1"
                />
                <YAxis
                  orientation="left"
                  allowDataOverflow
                  domain={["dataMin - 10", "dataMax + 10"]}
                  type="number"
                  tickFormatter={(value) => value?.toFixed(2)}
                  yAxisId="2"
                />
                <Tooltip
                  cursor={false}
                  formatter={(value, name, props) => [
                    `${name == "Price" ? value?.toFixed(2) : value} - ${
                      name == "Signal" ? "" : name
                    } ${
                      name == "Signal"
                        ? props.payload.signal + " - Global Signal"
                        : ""
                    }`,
                  ]}
                  labelFormatter={(value) => `Date: ${value}`}
                  itemStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                />
                <Brush
                  onChange={(e) => {
                    let item;
                    rocMomentumData.map((d, index) => {
                      if (d.index === e.endIndex) {
                        item = d;
                      }
                    });
                    setLatestDataRocMomentum(item);
                    setBrushStartIndexRocMomentum(e.startIndex);
                    setBrushEndIndexRocMomentum(e.endIndex);
                  }}
                  data={rocMomentumData}
                  dataKey="index"
                  height={20}
                  fill={`${theme.palette.background.paper}`}
                  startIndex={brushStartIndexRocMomentum}
                  endIndex={brushEndIndexRocMomentum}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="center"
                />

                <Line
                  yAxisId="2"
                  type="linear"
                  dataKey="price"
                  name="Price"
                  stroke="#5f8fb5"
                  strokeWidth={5}
                  dot={false}
                  animationDuration={3000}
                />

                <Bar
                  yAxisId="1"
                  dataKey="signalNumber"
                  name="Signal"
                  fill="#f29157"
                  barSize={1.5}
                />

                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ROCMomentumChart;
