import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOCALE } from "../../properties/Locale";
import { UsersRepository } from "../../repositories/UsersRepository";
import { useTheme } from "@emotion/react";
import logo from "../../assets/img/capitelize_logo.png";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [loading, setLoading] = useState();
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    if (!email) {
      return "Email is required.";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address.";
    }
    return "";
  };

  const handleForgotPassword = () => {
    setLoading(true);
    const error = validateEmail(email);
    if (error) {
      setEmailError(error);
      setLoading(false);
      return;
    }
    UsersRepository.forgotPasswordRequest(email)
      .then((res) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={3}
          justifyContent="center"
          style={{
            width: "500px",
            height: "600px",
            textAlign: "center",
            borderRadius: "20px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img
                src={logo}
                height={90}
                style={{
                  marginLeft: "-15px",
                  marginTop: "30px",
                  marginBottom: "40px",
                }}
              />
            </Grid>
            <Grid container spacing={2}>
              {globalFormError && (
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Alert severity="error">{globalFormError}</Alert>
                  </Grid>
                </Grid>
              )}
              {!success && (
                <>
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <Grid item xs={7}>
                      <TextField
                        fullWidth
                        label={LOCALE.email}
                        value={email}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleForgotPassword();
                          }
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          height: "48px",
                          borderRadius: "4px",
                        }}
                        error={!!emailError}
                        helperText={emailError}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item xs={7}>
                      <Button
                        onClick={() => {
                          handleForgotPassword();
                        }}
                        fullWidth
                        variant="contained"
                        sx={{ height: "48px", backgroundColor: "#10377c" }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          {LOCALE.next.toLowerCase()}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {success && (
                <Grid container justifyContent="center">
                  <Grid item xs={7}>
                    <Alert severity="success">
                      {LOCALE.forgotPasswordSuccessCheckYourEmail}
                    </Alert>
                  </Grid>
                </Grid>
              )}
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={7}
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#10377c",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {LOCALE.login}
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </div>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: "20px",
          textAlign: "center",
          color: theme.palette.lightGray.main,
        }}
      >
        &copy;Capitelize 2024
      </Grid>
    </>
  );
}
