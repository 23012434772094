import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Alert,
  Grid,
  TextField,
  Backdrop,
  CircularProgress,
  Button,
  Snackbar,
  Paper,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { UserRegistrationRepository } from "../../repositories/UserRegistrationRepository";
import {
  notifyShowInfoMessage,
  notifyShowSuccessMessage,
  triggerRerender,
} from "../../common/CommonActions";
import { AuthRepository } from "../../repositories/AuthRepository";
import { LOCALE } from "../../properties/Locale";

const ActivateUser = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [expired, setExpired] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (token) {
      activateUser();
    }
  }, [token]);

  const activateUser = () => {
    if (token) {
      UserRegistrationRepository.activateUser(token)
        .then((res) => {
          setMessage(res.data);
          setError(false);
          setExpired(false);
          if (res.data !== "User is already activated.") {
            dispatch(
              notifyShowSuccessMessage(
                "User activated successfully! Log in to continue!"
              )
            );
          } else {
            dispatch(notifyShowInfoMessage(res.data));
          }
        })
        .catch((error) => {
          console.error(error);
          //setMessage(error.res ? error.res.data : "Activation failed.");
          setMessage("User Activation link expired!");
          setExpired(true);
          setSnackbarOpen(true);
          setTimeout(() => navigate("/login"), 5000);
          setError(true);
        });
    }
  };

  const handleLogin = () => {
    setLoading(true);
    AuthRepository.fetchToken({ username: username, password: password }).then(
      (res) => {
        window.localStorage.setItem("jwt", res?.data?.jwt);
        dispatch(triggerRerender());
        if (AuthRepository.hasAnyRole("ROLE_ADMINISTRATION")) {
          navigate("/home");
        } else {
          navigate("/");
        }
        setLoading(false);
      },
      (err) => {
        console.log(err);
        console.log(err.response);
        setGlobalFormError(err.response?.data);
        setLoading(false);
      }
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      {!expired ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper
            elevation={3}
            justifyContent="center"
            style={{
              width: "500px",
              height: isSmallScreen ? "665px" : "600px",
              textAlign: "center",
              borderRadius: "20px",
            }}
          >
            <Grid
              continer
              justifyContent="center"
              style={{
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <img
                alt=""
                src={require("../../assets/img/capitelize_logo.png")}
                width="90px"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer", marginBottom: "60px" }}
              ></img>{" "}
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{ marginTop: "30px" }}
            >
              {globalFormError && (
                <Grid
                  item
                  xs={8}
                  style={{
                    marginTop: "-10px",
                    marginBottom: "15px",
                  }}
                >
                  <Alert severity="error">{globalFormError}</Alert>
                </Grid>
              )}
              <Grid container justifyContent="center" paddingBottom={2}>
                <Grid item xs={8} md={7}>
                  <TextField
                    fullWidth
                    label="Username"
                    value={username}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{
                      height: "48px",
                      borderRadius: "4px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center" paddingBottom={2}>
                <Grid item xs={8} md={7}>
                  <TextField
                    fullWidth
                    label={LOCALE.password}
                    type={showPassword ? "text" : "password"}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    style={{
                      height: "48px",
                      borderRadius: "4px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {!showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={8} md={7}>
                  <Button
                    onClick={() => {
                      handleLogin();
                    }}
                    fullWidth
                    variant="contained"
                    sx={{ height: "48px", backgroundColor: "#10377c" }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {LOCALE.login.toLowerCase()}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <a
                  style={{
                    cursor: "pointer",
                    color: "#10377c",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    navigate("/forgotPassword");
                  }}
                >
                  {LOCALE.forgotPassword}
                </a>
              </Grid>

              {!isSmallScreen && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                    color: theme.palette.lightGray.main,
                    marginTop: "180px",
                  }}
                >
                  &copy; Capitelize 2024
                </Grid>
              )}
            </Grid>
          </Paper>
        </div>
      ) : (
        <Grid item xs={12}>
          <Alert severity="error">User Activation Link Expired!</Alert>
        </Grid>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%", color: "white" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ActivateUser;
