import { useTheme } from "@emotion/react";
import {
  AppBar,
  Collapse,
  Drawer,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  SnackbarContent,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { AuthRepository } from "../repositories/AuthRepository";
import MenuIcon from "@mui/icons-material/Menu";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import { ProfileListMobile, SideMenuLinks } from "./SideMenuLink";
import { alpha } from "@mui/system";
import styled from "@emotion/styled";

const DarkModeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
        ...theme.applyStyles("dark", {
          backgroundColor: "#8796A5",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#003892",
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
    ...theme.applyStyles("dark", {
      backgroundColor: "#8796A5",
    }),
  },
}));

export default function HeaderMobile({
  setOpenMobile,
  openMobile,
  themeMode,
  setThemeMode,
}) {
  const [redirectTo, setRedirectTo] = React.useState();
  const location = useLocation();
  const globalState = useSelector((state) => state);
  const [currentUser, setCurrentUser] = React.useState();
  const theme = useTheme();
  const navigate = useNavigate();
  const [menuLinks, setMenuLinks] = React.useState(SideMenuLinks);
  const [menuLinksProfile, setMenuLinksProfile] =
    React.useState(ProfileListMobile);
  const [submenuExpanded, setSubmenuExpanded] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [openSidebar, setOpenSidebar] = React.useState(false);

  const checkActiveTab = (item) => {
    return (
      (window.location.pathname.includes(item.selectedPath) &&
        window.location.pathname !== "/" &&
        item.path !== "/") ||
      (window.location.pathname === item.selectedPath &&
        item.selectedPath === "/")
    );
    return false;
  };

  const getUserInitials = (user) => {
    if (!user) return "";
    const firstNameInitial = user.firstName?.charAt(0)?.toUpperCase() || "";
    const lastNameInitial = user.lastName?.charAt(0)?.toUpperCase() || "";
    return firstNameInitial + lastNameInitial;
  };
  const getUserFullName = (user) => {
    if (!user) return "";
    return `${user.firstName || ""} ${user.lastName || ""}`;
  };

  const getUserEmail = (user) => {
    if (!user) return "";
    return `${user.email || ""}`;
  };

  React.useEffect(() => {
    loadCurrentUser();
  }, [globalState.triggerRerender]);

  React.useEffect(() => {
    setRedirectTo(undefined);
  }, [location]);

  const loadCurrentUser = () => {
    if (localStorage.getItem("jwt")) {
      setCurrentUser(jwtDecode(localStorage.getItem("jwt")));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      {redirectTo && <Navigate to={redirectTo} push />}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          display: { xs: "block", md: "none" },
          width: `calc(100% - ${openSidebar ? "230px" : "0px"})`,
          transition: (theme) =>
            theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          backgroundColor: theme.palette.background.default,
          boxShadow: (theme) => theme.shadows[2],
        }}
      >
        <Toolbar
          style={{
            minHeight: "63px",
          }}
        >
          <Grid
            container
            style={{
              height: "50px",
              alignItems: "center",
            }}
          >
            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{
                  position: "fixed",
                  left: "12px",
                  border: "rgba(128, 128, 128, 0.5) 1px solid",
                  borderRadius: "4px",
                  width: "45px",
                  height: "45px",
                }}
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                <MenuIcon
                  style={{
                    color: theme.palette.mutedBlue.main,
                    fontSize: "24px",
                  }}
                />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                textAlign: "center",
              }}
            >
              <img
                src={
                  themeMode == "dark"
                    ? require("../assets/img/capitelize_logo_inverted_header.png")
                    : require("../assets/img/capitelize_logo.png")
                }
                alt="CAPITELIZE"
                style={{
                  width: "45px",
                  height: "45px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                textAlign: "end",
              }}
            >
              <FormControlLabel
                control={<DarkModeSwitch sx={{ m: 1 }} size="small" />}
                checked={themeMode === "dark" ? true : false}
                onChange={(e) => {
                  setThemeMode(e.target.checked ? "dark" : "light");
                }}
              />
            </Grid>
          </Grid>
          {openSidebar && (
            <Drawer
              variant="permanent"
              open={openMobile}
              sx={{
                display: { xs: "block", md: "none" },
                width: "100%",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: "100%",
                  boxSizing: "border-box",

                  backgroundColor:
                    themeMode === "dark"
                      ? theme.palette.lightGray.dark
                      : theme.palette.mutedBlue.main,
                  position: "fixed",
                },
              }}
              PaperProps={{
                sx: {
                  width: 240,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: 240,
                    boxSizing: "border-box",
                    zIndex: (theme) => theme.zIndex.drawer,
                    position: "relative",
                  },
                },
              }}
            >
              <div style={{ flex: 1 }}>
                <Grid container direction="column" style={{ height: "100%" }}>
                  <Grid
                    container
                    style={{
                      height: "50px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <IconButton
                        sx={{
                          position: "absolute",
                          border: "white 1px solid",
                          borderRadius: "4px",
                          left: "12px",
                          width: "50px",
                          height: "50px",
                          top: "12px",
                          zIndex: 1201,
                        }}
                        onClick={() => setOpenSidebar(false)}
                      >
                        <CloseIcon
                          style={{ color: "white", fontSize: "24px" }}
                        />
                      </IconButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "end" }}>
                      <Icon
                        style={{
                          width: "60px",
                          height: "70px",
                          marginTop: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src={
                            themeMode == "dark"
                              ? require("../assets/img/capitelize_logo_inverted.png")
                              : require("../assets/img/capitelize_logo_2.png")
                          }
                          alt="CAPITELIZE"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "-5px",
                          }}
                        />
                      </Icon>
                    </Grid>

                    <Grid item style={{ marginTop: " 15px", width: "100%" }}>
                      <List>
                        {menuLinks.map(
                          (item, index) =>
                            AuthRepository.hasAnyRole(item.roles) &&
                            item.name != "CAPITELIZE" && (
                              <React.Fragment key={index}>
                                {
                                  <>
                                    <ListItem
                                      button
                                      key={index}
                                      onClick={() => {
                                        if (item.name === "Settings") {
                                          setSubmenuExpanded(!submenuExpanded);
                                        } else if (item.name === "CAPITELIZE") {
                                          navigate("/home");
                                        } else {
                                          setSelectedItem(item.name);
                                          setOpenSidebar(false);
                                          navigate(item.path);
                                        }
                                      }}
                                      style={{
                                        backgroundColor: checkActiveTab(item)
                                          ? alpha(
                                              theme.palette.lightGray.main,
                                              0.2
                                            )
                                          : "transparent",
                                        borderRadius: "8px",
                                        transition: "all 0.3s ease",
                                        transform: "scale(0.93)",
                                      }}
                                    >
                                      <ListItemIcon
                                        style={{
                                          minWidth: "40px",
                                          minHeight: "30px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Icon
                                          sx={{
                                            fontSize: "24px",
                                            color: "white",
                                          }}
                                        >
                                          {item.icon}
                                        </Icon>
                                      </ListItemIcon>
                                      {openSidebar && (
                                        <>
                                          <ListItemText
                                            primary={
                                              <Typography
                                                sx={{
                                                  fontWeight: "bold",
                                                  color: "white",
                                                  fontSize: "24px",
                                                }}
                                              >
                                                {item.name}
                                              </Typography>
                                            }
                                            style={{ marginLeft: "5px" }}
                                          />
                                        </>
                                      )}

                                      {item.name === "Settings" ? (
                                        submenuExpanded ? (
                                          <ExpandLess />
                                        ) : (
                                          <ExpandMore />
                                        )
                                      ) : null}
                                    </ListItem>
                                  </>
                                }
                                {item.name === "Settings" && (
                                  <Collapse
                                    in={submenuExpanded}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <List component="div" disablePadding>
                                      {item.submenus
                                        .filter((submenu) => {
                                          if (
                                            AuthRepository.hasAnyRole(
                                              "ROLE_ADMINISTRATION"
                                            )
                                          ) {
                                            return true;
                                          }
                                          if (
                                            AuthRepository.hasAnyRole(
                                              "ROLE_CLIENT"
                                            )
                                          ) {
                                            return (
                                              submenu.name ===
                                              "Profile Settings"
                                            );
                                          }
                                          return false;
                                        })
                                        .map((submenu, subIndex) => (
                                          <ListItem
                                            button
                                            key={subIndex}
                                            onClick={() => {
                                              setSelectedItem(item.name);
                                              setOpenSidebar(false);
                                              navigate(submenu.path);
                                            }}
                                            sx={{
                                              paddingLeft: "35px",
                                              backgroundColor: checkActiveTab(
                                                submenu
                                              )
                                                ? alpha(
                                                    theme.palette.lightGray
                                                      .main,
                                                    0.2
                                                  )
                                                : theme.palette
                                                    .dashboardBackground.light,
                                              color:
                                                checkActiveTab(item) &&
                                                theme.palette
                                                  .dashboardBackground.light,
                                              borderRadius: "8px",
                                              transition: "all 0.2s ease",
                                              transform: "scale(0.95)",
                                              "&:hover": {
                                                backgroundColor: checkActiveTab(
                                                  submenu
                                                )
                                                  ? alpha("#10377c", 0.8)
                                                  : alpha(
                                                      theme.palette
                                                        .dashboardBackground
                                                        .main,
                                                      0.8
                                                    ),
                                              },
                                            }}
                                          >
                                            <ListItemIcon>
                                              <Icon
                                                sx={{
                                                  fontWeight: "bold",
                                                  fontSize: "18px",
                                                  color: "#ffffff",
                                                }}
                                              >
                                                {submenu.icon}
                                              </Icon>
                                            </ListItemIcon>
                                            <ListItemText
                                              sx={{
                                                color: "#ffffff",
                                              }}
                                            >
                                              <Typography
                                                variant="h5"
                                                sx={{ fontWeight: "light" }}
                                              >
                                                {submenu.name}
                                              </Typography>
                                            </ListItemText>
                                          </ListItem>
                                        ))}
                                    </List>
                                  </Collapse>
                                )}
                              </React.Fragment>
                            )
                        )}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div style={{ marginTop: "auto", marginBottom: "-15px" }}>
                <List>
                  {menuLinksProfile.map(
                    (item, index) =>
                      AuthRepository.hasAnyRole(item.roles) && (
                        <React.Fragment key={index}>
                          <>
                            <ListItem
                              button
                              key={index}
                              onClick={() => {
                                navigate(item.path);
                                setOpenSidebar(false);
                              }}
                              style={{
                                backgroundColor: checkActiveTab(item)
                                  ? alpha(theme.palette.lightGray.main, 0.2)
                                  : "transparent",
                                paddingLeft: "20px",
                                paddingRight: "5px",
                                borderRadius: "8px",
                                transition: "all 0.3s ease",
                                transform: "scale(0.93)",
                              }}
                            >
                              <ListItemIcon
                                style={{
                                  minWidth: "30px",
                                  minHeight: "25px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    borderColor: "white",
                                    borderRadius: "4px",
                                    width: "50px",
                                    height: "50px",
                                    minWidth: "30px",
                                    minHeight: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    fontSize: "18px",
                                    backgroundColor: "white",
                                    fontWeight: "bold",
                                    color: "#202020",
                                    fontSize: "24px",
                                  }}
                                >
                                  {getUserInitials(currentUser)}
                                </div>
                              </ListItemIcon>
                              {openSidebar && (
                                <>
                                  <ListItemText
                                    primary={
                                      <div>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: "white",
                                            opacity: 0.7,
                                            fontSize: "12px",
                                          }}
                                        >
                                          {currentUser?.organization?.nameEn}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: "white",
                                            marginTop: "-1px",
                                            marginBottom: "-1px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          <b>{getUserFullName(currentUser)}</b>
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: "white",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {getUserEmail(currentUser)}
                                        </Typography>
                                      </div>
                                    }
                                    style={{ marginLeft: "5px" }}
                                  />
                                </>
                              )}
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  style={{
                                    border: "white 1px solid",
                                    borderRadius: "4px",
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "5px",
                                  }}
                                  onClick={() => AuthRepository.logout()}
                                >
                                  <LogoutIcon
                                    style={{
                                      color: "white",
                                      fontSize: "24px",
                                    }}
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </>
                        </React.Fragment>
                      )
                  )}
                </List>
              </div>

              <List>
                <></>
              </List>
            </Drawer>
          )}

          <Typography sx={{ flexGrow: 1 }}></Typography>
        </Toolbar>
      </AppBar>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: " #d93535",
          }}
          message={snackbarMessage}
        />
      </Snackbar>
    </React.Fragment>
  );
}
