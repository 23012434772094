import { COMMON_ACTIONS } from "./CommonActions";

export default function reducer(
  state = {
    success: false,
    error: false,
    loading: false,
    currentUser: null,
    conversationsViber: [],
    conversationsWhatsApp: [],
    receiveNewTicket: undefined,
  },
  action
) {
  switch (action.type) {
    case COMMON_ACTIONS.API_CALL_START:
      return {
        ...state,
        loading: true,
      };

    case COMMON_ACTIONS.API_CALL_FINISH:
      return {
        ...state,
        loading: false,
      };

    case COMMON_ACTIONS.SHOW_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        open: action.payload.open,
        variant: action.payload.variant,
        link: action.payload.link,
        autoHide: action.payload.autoHide,
      };

    case COMMON_ACTIONS.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        open: false,
      };

    case COMMON_ACTIONS.RERENDER:
      return {
        ...state,
        triggerRerender: !state.triggerRerender,
      };

    case COMMON_ACTIONS.RECEIVE_NEW_MAIL:
      return {
        ...state,
        receiveNewMail: action.payload.receiveNewMail,
      };

    case COMMON_ACTIONS.RECEIVE_NEW_NOTIFICATION:
      return {
        ...state,
        receiveNewNotification: action.payload.receiveNewNotification,
      };

    default:
      return state;
  }
}
