import React, { useState } from "react";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Typography,
  Grid,
  Icon,
  useMediaQuery,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const AroonOscilatorChart = ({
  AroonData,
  latestDataAroon,
  setLatestDataAroon,
  brushStartIndexAroon,
  brushEndIndexAroon,
  setBrushStartIndexAroon,
  setBrushEndIndexAroon,
  left,
  right,
  height,
}) => {
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={9}
          style={{ textAlign: isSmallScreen && "center" }}
        >
          <Typography
            variant="h5"
            style={{ color: theme.palette.mutedBlue.main }}
          >
            Indicator 3: Aroon Oscilator{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer height={height}>
              <ComposedChart data={AroonData}>
                <CartesianGrid strokeDasharray="0.1 0.1" />

                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  domain={[left, right]}
                  type="category"
                />

                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[-3, 3]}
                  ticks={[-3, 0, 3]}
                  type="number"
                  yAxisId="1"
                />

                <YAxis
                  orientation="left"
                  allowDataOverflow
                  domain={[0, "dataMax + 50"]}
                  type="number"
                  yAxisId="2"
                />

                <Tooltip
                  cursor={false}
                  formatter={(value, name, props) => [
                    `${value} - ${name === "Signal" ? "" : name} ${
                      name === "Signal"
                        ? props.payload.signal + " - Signal"
                        : ""
                    }`,
                  ]}
                  labelFormatter={(value) => `Date: ${value}`}
                  itemStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                />

                <Brush
                  onChange={(e) => {
                    let item;
                    AroonData.map((d, index) => {
                      if (d.index === e.endIndex) {
                        item = d;
                      }
                    });
                    setLatestDataAroon(item);
                    setBrushStartIndexAroon(e.startIndex);
                    setBrushEndIndexAroon(e.endIndex);
                  }}
                  data={AroonData}
                  dataKey="index"
                  height={20}
                  fill={`${theme.palette.background.paper}`}
                  startIndex={brushStartIndexAroon}
                  endIndex={brushEndIndexAroon}
                />

                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="center"
                />

                <Line
                  yAxisId="2"
                  type="linear"
                  dataKey="price"
                  name="Price"
                  stroke="#5f8fb5"
                  strokeWidth={5}
                  dot={false}
                  animationDuration={3000}
                />

                <Bar
                  yAxisId="1"
                  dataKey="signalNumber"
                  name="Signal"
                  fill="#f29157"
                  barSize={1.5}
                />

                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          style={{ textAlign: isSmallScreen ? "center" : "left" }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align={isSmallScreen ? "center" : "left"}
                >
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.mutedBlue.main }}
                  >
                    {latestDataAroon?.name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Date:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataAroon?.date}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Price:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataAroon?.price?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Aroon Up - Aroon Down:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataAroon?.differenceAroonUpDown?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Signal Numeric:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataAroon?.signalNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Signal:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataAroon?.signal === "BUY"
                      ? "BUY"
                      : latestDataAroon?.signal === "SELL"
                      ? "SELL"
                      : latestDataAroon?.signal}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  sx={{ borderBottom: "none" }}
                >
                  <Icon
                    sx={{ fontSize: "100px", marginTop: "10px" }}
                    color={
                      latestDataAroon?.signal?.includes("BUY")
                        ? "success"
                        : latestDataAroon?.signal?.includes("SELL")
                        ? "error"
                        : "warning"
                    }
                  >
                    {latestDataAroon?.signal?.includes("BUY")
                      ? "thumb_up"
                      : latestDataAroon?.signal?.includes("SELL")
                      ? "thumb_down"
                      : "sentiment_neutral"}
                  </Icon>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>{" "}
        <Grid item xs={12} md={7.55}>
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer height={height}>
              <LineChart data={AroonData}>
                <CartesianGrid strokeDasharray="0.1 0.1" />
                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  domain={[left, right]}
                  type="category"
                />
                <YAxis
                  orientation="left"
                  allowDataOverflow
                  domain={[0, 100]}
                  ticks={[0, 20, 40, 60, 80, 100]}
                  type="number"
                  yAxisId="1"
                />

                <Tooltip
                  cursor={false}
                  formatter={(value, name, props) => [`${value} - ${name} `]}
                  labelFormatter={(value) => `Date: ${value}`}
                  itemStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                />
                <Brush
                  onChange={(e) => {
                    let item;
                    AroonData.map((d, index) => {
                      if (d.index === e.endIndex) {
                        item = d;
                      }
                    });
                    setLatestDataAroon(item);
                    setBrushStartIndexAroon(e.startIndex);
                    setBrushEndIndexAroon(e.endIndex);
                  }}
                  data={AroonData}
                  dataKey="index"
                  height={20}
                  fill={`${theme.palette.background.paper}`}
                  startIndex={brushStartIndexAroon}
                  endIndex={brushEndIndexAroon}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="center"
                />

                <Line
                  yAxisId="1"
                  type="vertical"
                  dataKey="aroonUp"
                  name="Aroon Up"
                  stroke="#5f8fb5"
                  strokeWidth={5}
                  dot={false}
                  animationDuration={3000}
                />

                <Line
                  yAxisId="1"
                  type="linear"
                  dataKey="aroonDown"
                  name="Aroon Down"
                  stroke="#f29157"
                  strokeWidth={5}
                  dot={false}
                  animationDuration={3000}
                />

                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AroonOscilatorChart;
