import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const CompanyIndicatorRepository = {
  update: (selectedCompany, selectedIndicator, selectedTerm) => {
    return Axios({
      url: `${SETTINGS.API_URL}/companyIndicators/update`,
      params: {
        companyId: selectedCompany?.id,
        term: selectedTerm,
      },
      data: selectedIndicator,
      method: "PUT",
    });
  },
  getByCompanyAndTerm: (
    selectedCompany,
    selectedTerm,
    selectedIndicatorName
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}/companyIndicators/get/${selectedCompany}`,
      params: {
        term: selectedTerm,
        indicatorName: selectedIndicatorName,
      },
      method: "GET",
    });
  },
};
