import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notifyShowSuccessMessage } from "../../common/CommonActions";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { LOCALE } from "../../properties/Locale";
import { UsersRepository } from "../../repositories/UsersRepository";
import { ForgotPasswordResetValidator } from "./PasswordValidator";
import { useTheme } from "@emotion/react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function ResetPassword() {
  const [globalFormError, setGlobalFormError] = React.useState();
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [loading, setLoading] = useState();
  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [networkErrorMessage, setNetworkErrorMessage] = useState("");
  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [showPassword, setShowPassword] = useState(false);

  React.useEffect(() => {
    if (searchParams.get("token")) {
      setLoading(true);
      UsersRepository.forgotPasswordCheck(searchParams.get("token")).then(
        (res) => {
          if (res.data) {
            setStep(1);
          } else {
            setStep(3);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      );
    }
  }, [searchParams]);

  const handleResetPassword = () => {
    setLoading(true);
    validateData(data);
    if (ForgotPasswordResetValidator.isValidSync(data)) {
      UsersRepository.forgotPasswordReset(
        searchParams.get("token"),
        data.newPassword
      ).then(
        (response) => {
          setLoading(false);
          dispatch(notifyShowSuccessMessage(LOCALE.password_reset_success));
          navigate("/login");
        },
        (err) => {
          console.log(err);
          setNetworkErrorMessage(ErrorMessageResolver.resolve(err));
          setLoading(false);
        }
      );
    }
  };

  const validateData = (data) => {
    if (!ForgotPasswordResetValidator.isValidSync(data)) {
      var validationErrors = {};
      (async () => {
        await ForgotPasswordResetValidator.validate(data, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });

          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "87vh",
        }}
      >
        <Paper
          elevation={3}
          justifyContent="center"
          style={{
            width: isSmallScreen ? "380px" : "500px",
            height: isSmallScreen ? "665px" : "600px",
            textAlign: "center",
            borderRadius: "20px",
          }}
        >
          <Grid
            continer
            justifyContent="center"
            style={{
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <img
              alt=""
              src={require("../../assets/img/capitelize_logo.png")}
              width="90px"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer", marginBottom: "60px" }}
            ></img>{" "}
          </Grid>

          <Grid container spacing={2} justifyContent="center">
            {" "}
            {globalFormError && (
              <Grid item xs={8}>
                <Alert severity="error">{globalFormError}</Alert>
              </Grid>
            )}
            {step == 1 && (
              <>
                <Grid container justifyContent="center" paddingBottom={3}>
                  <Grid item xs={8} md={7}>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      label={LOCALE.new_password}
                      name="new_password"
                      variant="outlined"
                      fullWidth
                      value={data.newPassword ? data.newPassword : ""}
                      onChange={(e) =>
                        setData({ ...data, newPassword: e.target.value })
                      }
                      error={formDataErrors.newPassword ? true : false}
                      helperText={formDataErrors.newPassword}
                      style={{
                        height: "48px",
                        borderRadius: "4px",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {!showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" paddingBottom={5}>
                  <Grid item xs={8} md={7}>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      label={LOCALE.confirm_password}
                      name="confirm_password"
                      variant="outlined"
                      fullWidth
                      value={data.confirmPassword ? data.confirmPassword : ""}
                      onChange={(e) =>
                        setData({ ...data, confirmPassword: e.target.value })
                      }
                      error={formDataErrors.confirmPassword ? true : false}
                      helperText={formDataErrors.confirmPassword}
                      style={{
                        height: "48px",
                        borderRadius: "4px",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {!showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" paddingBottom={2}>
                  <Grid item xs={8} md={7}>
                    <Button
                      onClick={() => {
                        handleResetPassword();
                      }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ height: "48px", backgroundColor: "#10377c" }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {LOCALE.next.toLowerCase()}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            {step == 2 && (
              <Grid item xs={8} md={7}>
                <Alert severity="success">
                  {LOCALE.password_reset_success}
                </Alert>
              </Grid>
            )}
            {step == 3 && (
              <Grid item xs={8} md={7}>
                <Alert severity="error">
                  {LOCALE.forgotPasswordLinkExpired}
                </Alert>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                marginTop: { xs: "300px ", md: "360px" },
                textAlign: "center",
                color: theme.palette.lightGray.main,
              }}
            >
              &copy; Capitelize 2024
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
