import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import { UsersRepository } from "../../repositories/UsersRepository";
import { useDispatch } from "react-redux";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/CommonActions";
import { AuthRepository } from "../../repositories/AuthRepository";
import { Visibility, VisibilityOff, VpnKeyOutlined } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const ClientResetPassword = ({ username, loadData }) => {
  const [formData, setFormData] = useState({
    username: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setFormData({
      username: username,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [loadData]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    UsersRepository.resetPassword(formData)
      .then((res) => {
        dispatch(
          notifyShowSuccessMessage(
            "Password updated succesfully! You will be logged out!"
          )
        );
        setTimeout(() => {
          AuthRepository.logout();
        }, [2000]);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.response.data.message));
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        maxWidth: { md: 600 },
        mx: "auto",
        p: 4,
      }}
    >
      <Alert severity="info" sx={{ mb: 2 }}>
        <Typography variant="subtitle1">
          Please note: Changing your password will log you out, and any unsaved
          data will be lost.
        </Typography>
      </Alert>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Enter Current Password"
              name="oldPassword"
              type={showPassword ? "text" : "password"}
              value={formData.oldPassword}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Enter New Password"
              name="newPassword"
              type={showPassword ? "text" : "password"}
              value={formData.newPassword}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyOutlined />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.mutedBlue.main,
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: theme.palette.mutedBlue.main,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirm New Password"
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              value={formData.confirmPassword}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyOutlined />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.mutedBlue.main,
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: theme.palette.mutedBlue.main,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="mutedBlue"
              sx={{
                color: theme.palette.dashboardBackground.main,
              }}
              disabled={
                formData?.oldPassword == "" ||
                formData?.newPassword == "" ||
                formData?.confirmPassword == ""
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ClientResetPassword;
