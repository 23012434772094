import { Icon, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

export const TableRowEmptyData = ({ colSpan }) => {
  return (
    <TableRow>
      <TableCell
        style={{
          borderBottom: "0px solid white",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
        colSpan={colSpan ? colSpan : 20}
      >
        <b
          style={{
            font: "-moz-initial",
            color: "#adb5bd",
            fontWeight: "bold",
            textTransform: "uppercase",
            marginLeft: 10,
            marginTop: 10,
          }}
        >
          <Typography variant="h6">
            {" "}
            <Icon style={{ verticalAlign: "middle" }} color="warning">
              warning
            </Icon>{" "}
            No records found
          </Typography>
        </b>
      </TableCell>
    </TableRow>
  );
};
