import React from "react";
import { UserRegistrationRepository } from "../../repositories/UserRegistrationRepository";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import facebook from "../../assets/img/communication.png";
import { useNavigate } from "react-router-dom";

const Facebook = (props) => {
  const navigate = useNavigate();
  const handleFacebookCallback = async (response) => {
    if (response?.status === "unknown") {
      console.error("Sorry!", "Something went wrong with Facebook Login.");
      return;
    }

    UserRegistrationRepository.facebookLogin(response.email, response.name)
      .then((result) => {
        if (result.status === 200) {
          const token = result.data;
          localStorage.setItem("jwt", token);
          navigate("/");
          props.history.push("/");
        }
      })
      .catch((error) => {
        console.error("Error during authentication:", error);
      });
  };

  return (
    <FacebookLogin
      buttonStyle={{
        padding: "10px 20px",
        backgroundColor: "#4267B2",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        fontSize: "14px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
      appId="550240060856749"
      autoLoad={false}
      fields="name,email,picture"
      callback={handleFacebookCallback}
      icon="fa-facebook"
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          style={{
            border: "none",
            backgroundColor: "white",
            cursor: "pointer",
          }}
        >
          {" "}
          <img
            style={{
              width: "25px",
              height: "25px",
            }}
            src={facebook}
            alt="Facebook"
          />
        </button>
      )}
    />
  );
};
export default Facebook;
