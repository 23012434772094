import React from "react";
import {
  Box,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TableBody,
  Icon,
  TableFooter,
  TablePagination,
  Pagination,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { alpha } from "@mui/system";
import { InfoOutlined } from "@mui/icons-material";
import { LOCALE } from "../../properties/Locale";

export default function CompanyAccessedTable({
  isMobile,
  loading,
  theme,
  historyData,
  findSectorById,
  size,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <Box
      sx={{
        border: `1px solid ${alpha(theme.palette.lightGray.main, 0.3)}`,
        padding: { xs: 2, sm: 4 },
        borderRadius: "10px",
        width: { xs: "100%", sm: "auto" },
        maxWidth: { xs: "100%", sm: "auto" },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant={isMobile ? "h5" : "h4"}>
                History
                <Tooltip
                  title={
                    <Typography variant="h6">
                      Last accessed company data
                    </Typography>
                  }
                >
                  <IconButton
                    size="small"
                    sx={{
                      marginLeft: 1,
                      cursor: "default",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              maxHeight: { xs: "500px", md: "320px" },
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.lightGray.light,
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.lightGray.main,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.background.paper,
              },
            }}
          >
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  background: theme.palette.background.paper,
                  zIndex: 1,
                }}
              >
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Company</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">ISIN</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Bloomberg Ticker</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Sector</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Indicator</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Term</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Date Accessed</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  historyData &&
                  historyData?.totalElements === 0 && (
                    <TableRow
                      sx={{
                        backgroundColor: alpha(
                          theme.palette.lightGray.main,
                          0.05
                        ),
                      }}
                    >
                      <TableCell
                        style={{
                          borderBottom: "0px solid white",
                          textAlign: "center",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                        colSpan={20}
                      >
                        <b
                          style={{
                            font: "-moz-initial",
                            color: "#adb5bd",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            marginLeft: 10,
                            marginTop: 10,
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "light", paddingBottom: 1.5 }}
                          >
                            {" "}
                            <Icon
                              style={{
                                verticalAlign: "middle",
                                fontSize: "25px",
                              }}
                              color="gray"
                            >
                              manage_search
                            </Icon>{" "}
                            No data available
                          </Typography>
                        </b>
                      </TableCell>
                    </TableRow>
                  )}
                {historyData &&
                  historyData?.content?.map((history, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        background:
                          index % 2 == 0 &&
                          `${alpha(theme.palette.lightGray.main, 0.1)}`,
                      }}
                    >
                      <TableCell sx={{ border: "none" }}>
                        <Typography variant="subtitle1">
                          {history?.companyName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <Typography variant="subtitle1">
                          {history?.companyISIN}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <Typography variant="subtitle1">
                          {history?.companyBloombergTicker}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <Typography variant="subtitle1">
                          {history?.sector?.name
                            ? history?.sector?.name
                            : findSectorById(history?.sector)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <Typography variant="subtitle1">
                          {history?.indicatorName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <Typography variant="subtitle1">
                          {history.indicatorTerm === "SIMPLE"
                            ? "Simple"
                            : history.indicatorTerm === "SHORT_TERM"
                            ? "Short Term"
                            : history.indicatorTerm === "MID_TERM"
                            ? "Mid Term"
                            : history.indicatorTerm === "LONG_TERM"
                            ? "Long Term"
                            : "Unknown Term"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <Typography variant="body1">
                          {moment(history.dateAccessed).format("D MMMM YYYY")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          At {moment(history.dateAccessed).format("h:mm A")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter
                style={{
                  position: "sticky",
                  bottom: -5,
                  zIndex: 5,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <TableRow>
                  <TableCell colSpan={10} sx={{ borderBottom: "none" }}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={12} md={4} style={{ marginTop: "5px" }}>
                        <TablePagination
                          sx={{
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: { xs: "center", md: "start" },
                            "& .MuiTablePagination-select": {
                              fontSize: "12px",
                              border: "1px solid",
                              borderColor: theme.palette.lightGray.main,
                              padding: "5px 2px",
                              borderRadius: "4px",
                            },
                            "& .MuiTablePagination-displayedRows": {
                              fontSize: "12px",
                            },
                            "& .MuiTablePagination-actions button": {
                              fontSize: "12px",
                            },
                            "& .MuiIconButton-root": {
                              fontSize: "12px",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "12px",
                            },
                            "& .MuiTablePagination-displayedRows": {
                              marginLeft: "-15px",
                              fontSize: "12px",
                              color: theme.palette.lightGray.main,
                            },
                          }}
                          rowsPerPageOptions={[5, 10, 15, 25]}
                          rowsPerPage={
                            historyData?.size ? historyData?.size : size
                          }
                          labelDisplayedRows={() =>
                            `of ${historyData?.totalElements}`
                          }
                          labelRowsPerPage={
                            <span
                              style={{
                                fontSize: "12px",
                                marginLeft: "-20px",
                                color: theme.palette.lightGray.main,
                              }}
                            >
                              {LOCALE.rows_per_page}
                            </span>
                          }
                          SelectProps={{
                            inputProps: {
                              fontSize: "12px",
                              "aria-label": LOCALE.rows_per_page,
                            },
                            native: true,
                          }}
                          onRowsPerPageChange={(event, value) => {
                            handleChangeRowsPerPage(event);
                          }}
                          page={historyData?.number || 0}
                          count={historyData?.totalElements || 0}
                          size="large"
                          ActionsComponent={() => null}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Pagination
                          sx={{
                            marginTop: "15px",
                            display: "flex",
                            justifyContent: "center",
                            "& .MuiPaginationItem-root": {
                              fontSize: "12px",
                              margin: "5px 2px 5px 2px",
                            },
                            "& .MuiPaginationItem-page.Mui-selected": {
                              backgroundColor: theme.palette.darkContrast.main,
                              opacity: "80%",
                              color: theme.palette.dashboardBackground.main,
                            },
                            "& .MuiPaginationItem-previousNext": {
                              border: "1px solid",
                              borderColor: theme.palette.lightGray.main,
                            },
                          }}
                          count={
                            (historyData?.totalElements % size === 0
                              ? historyData?.totalElements / size
                              : Math.floor(historyData?.totalElements / size) +
                                1) || 0
                          }
                          shape="rounded"
                          size="small"
                          page={historyData?.number + 1 || 0}
                          onChange={(event, value) => {
                            handleChangePage(event, value - 1);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sx={{ display: { xs: "none", md: "inline" } }}
                      >
                        {" "}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
