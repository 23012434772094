import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { alpha, useMediaQuery } from "@mui/system";
import React, { useState, useEffect } from "react";

const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return (
    <Typography
      style={{
        fontFamily: "EB Garamond",
        lineHeight: 1.1,
        fontSize: isSmallScreen ? "30px" : "52px",
        color: alpha(theme.palette.mutedBlue.main, 0.9),
      }}
    >
      {currentText}
    </Typography>
  );
};

export default Typewriter;
