import * as React from "react";
import { Route, Routes } from "react-router-dom";
import CompaniesList from "../components/admin/CompaniesList";
import CompanyStockData from "../components/admin/CompanyStockData";
import CompanyChartData from "../components/admin/CompanyChartData";
import IndicatorSettings from "../components/admin/IndicatorSettings";
import HomeComponentAdmin from "../components/admin/HomeComponentAdmin";
import UserList from "../components/admin/UserList";
import MyProfile from "../components/client/MyProfile";
import Consultant from "../components/client/Consultant";
import History from "../components/client/History";
import ProfileSettings from "../components/client/ProfileSettings";
import MyPlan from "../components/client/MyPlan";
import Login from "../components/registration/Login";
import ForgotPassword from "../components/registration/ForgotPassword";
import ResetPassword from "../components/registration/ResetPassword";
import GoogleAuth from "../components/registration/GoogleAuth";
import Facebook from "../components/registration/Facebook";
import { RegisterUserNow } from "../components/registration/RegisterUserNow";
import ActivateUser from "../components/registration/ActivateUser";

export default function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Consultant />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/google-auth" element={<GoogleAuth />} />
        <Route path="/facebook" element={<Facebook />} />
        <Route path="/registerUser" element={<RegisterUserNow />} />
        <Route path="/activate-user/" element={<ActivateUser />} />

        <Route path="/home" element={<HomeComponentAdmin />} />

        <Route path="/users" element={<UserList />} />

        <Route path="/companies" element={<CompaniesList />} />
        <Route path="/stockData" element={<CompanyStockData />} />
        <Route path="/chartData" element={<CompanyChartData />} />
        <Route path="/indicatorSettings" element={<IndicatorSettings />} />
        <Route path="/history" element={<History />} />
        <Route path="/profileSettings" element={<ProfileSettings />} />
        <Route path="/myPlan" element={<MyPlan />} />
        <Route path="/myProfile" element={<MyProfile />} />
      </Routes>
    </>
  );
}
