import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthRepository } from "../../repositories/AuthRepository";
import { useDispatch } from "react-redux";
import {
  notifyShowErrorMessage,
  notifyShowInfoMessage,
  triggerRerender,
} from "../../common/CommonActions";
import { Check, DragIndicator, Person4Sharp, Phone } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { alpha } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import _ from "lodash";
import ClientResetPassword from "./ClientResetPassword";

export default function ProfileSettings() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [showNotifications, setShowNotifications] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [originalFirstName, setOriginalFirstName] = useState(data?.firstName);
  const [originalLastName, setOriginalLastName] = useState(data?.lastName);
  const [originalEmail, setOriginalEmail] = useState(data?.email);
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState(
    data?.phoneNumber
  );
  const [originalTwitterUrl, setOriginalTwitterUrl] = useState(
    data?.twitterUrl || ""
  );
  const [originalLinkedInUrl, setOriginalLinkedInUrl] = useState(
    data?.linkedInUrl || ""
  );
  const [originalBirthDate, setOriginalBirthDate] = useState(data?.dateOfBirth);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const userId = AuthRepository.getUserDetails()?.userId;
    if (userId !== undefined) {
      setLoading(true);
      UsersRepository.getUser()
        .then((res) => {
          setData(res.data);
          setOriginalFirstName(res.data.firstName);
          setOriginalLastName(res.data.lastName);
          setOriginalEmail(res.data.email);
          setOriginalPhoneNumber(res.data.phoneNumber);
          setOriginalLinkedInUrl(res.data.linkedInUrl);
          setOriginalTwitterUrl(res.data.twitterUrl);
          setOriginalBirthDate(res.data.dateOfBirth);
        })
        .catch((err) => {
          dispatch(notifyShowErrorMessage("Unable to load user data!"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSave = () => {
    if (data?.dateOfBirth && !isUserAdult(data?.dateOfBirth)) {
      dispatch(notifyShowErrorMessage("User must be at least 18 years old!"));
      return;
    }

    const updatedUser = {
      ...data,
    };

    setSaving(true);
    UsersRepository.updateUser(AuthRepository.getToken(), updatedUser)
      .then(() => {
        setIsChanged(false);
        dispatch(notifyShowInfoMessage("User updated successfully!"));
        loadData();

        dispatch(triggerRerender());
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err?.response?.data?.message));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleChangeData = (name, value) => {
    setData((prevData) => {
      const updatedData = _.set({ ...prevData }, name, value);
      const isDataChanged =
        updatedData.firstName !== originalFirstName ||
        updatedData.lastName !== originalLastName ||
        updatedData.email !== originalEmail ||
        updatedData.phoneNumber !== originalPhoneNumber ||
        updatedData.linkedInUrl !== originalLinkedInUrl ||
        updatedData.twitterUrl !== originalTwitterUrl ||
        updatedData.dateOfBirth !== originalBirthDate;

      setIsChanged(isDataChanged);
      return updatedData;
    });
  };

  const isUserAdult = (dateOfBirth) => {
    if (!dateOfBirth) return false;

    const birthDate = new Date(dateOfBirth);
    if (isNaN(birthDate.getTime())) {
      return false;
    }

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age >= 18;
  };

  return (
    <>
      <Container maxWidth="lg">
        <Paper
          sx={{
            padding: 5,
            borderRadius: "20px",
            backgroundColor: theme.palette.background.default,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: 5,
            position: "relative",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: { xs: "center", md: "start" },
              mb: 5,
            }}
          >
            <DragIndicator
              sx={{
                fontSize: "40px",
                mr: 1,
              }}
            />
            Profile Settings
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: alpha(theme.palette.mutedBlue.main, 0.8),
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginBottom: "15px",
                  color: theme.palette.dashboardBackground.main,
                }}
              >
                Basic Information
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={1.5}
              sx={{
                position: "relative",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  bgcolor: theme.palette.darkContrast.light,
                  boxShadow: 5,
                  position: "relative",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                }}
              >
                <Person4Sharp
                  sx={{
                    fontSize: "100px",
                    color: theme.palette.darkContrast.main,
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.darkContrast.main,
                  fontWeight: "bold",
                  marginY: 1,
                  marginLeft: "10px",
                }}
              >
                {data?.firstName} {data?.lastName}
              </Typography>

              <br />
              <Chip
                label={
                  <Typography
                    variant="h6"
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      fontWeight: "light",
                    }}
                  >
                    <Check sx={{ marginRight: "7px" }} /> Verified
                  </Typography>
                }
                sx={{
                  color: theme.palette.success.main,
                  borderRadius: 1,
                  height: "25px",
                  marginLeft: "10px",
                  marginTop: "-25px",
                }}
              />
            </Grid>
            <Divider
              sx={{
                display: { xs: "none", md: "block" },
                marginLeft: "20px",
                marginRight: "20px",
              }}
              variant="middle"
              orientation="vertical"
              flexItem
            />
            <Grid item xs={6} md={3}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.darkContrast.main,
                  fontWeight: "bold",
                  marginY: 1,
                }}
              >
                User ID
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.lightGray.main,
                  fontWeight: "light",
                  marginY: 1,
                }}
              >
                {data?.id}
              </Typography>
            </Grid>
            <Divider
              sx={{
                display: { xs: "none", md: "block" },
                marginLeft: "20px",
                marginRight: "20px",
              }}
              variant="middle"
              orientation="vertical"
              flexItem
            />
            <Grid item xs={6} md={2}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.darkContrast.main,
                  fontWeight: "bold",
                  marginY: 1,
                }}
              >
                Username
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.lightGray.main,
                  fontWeight: "light",
                  marginY: 1,
                }}
              >
                {data?.username}
              </Typography>
            </Grid>
            <Divider
              sx={{
                display: { xs: "none", md: "block" },
                marginLeft: "20px",
                marginRight: "20px",
              }}
              variant="middle"
              orientation="vertical"
              flexItem
            />
            <Grid item xs={6} md={2}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.darkContrast.main,
                  fontWeight: "bold",
                  marginY: 1,
                }}
              >
                Date Created
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.lightGray.main,
                  fontWeight: "light",
                  marginY: 1,
                }}
              >
                {moment(data?.dateCreated).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ marginBottom: "5px", marginTop: "15px" }} />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.darkContrast.main,
                      fontWeight: "bold",
                      marginY: 1,
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    value={data?.firstName || ""}
                    onChange={(e) =>
                      handleChangeData("firstName", e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    sx={{
                      marginY: 1,
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.palette.mutedBlue.main,
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: theme.palette.mutedBlue.main,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.darkContrast.main,
                      fontWeight: "bold",
                      marginY: 1,
                    }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    value={data?.lastName || ""}
                    onChange={(e) =>
                      handleChangeData("lastName", e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    sx={{
                      marginY: 1,
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.palette.mutedBlue.main,
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: theme.palette.mutedBlue.main,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.darkContrast.main,
                      fontWeight: "bold",
                      marginY: 1,
                    }}
                  >
                    Email Addess
                  </Typography>
                  <TextField
                    value={data?.email || ""}
                    onChange={(e) => handleChangeData("email", e.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{
                      marginY: 1,
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.palette.mutedBlue.main,
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: theme.palette.mutedBlue.main,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.darkContrast.main,
                      fontWeight: "bold",
                      marginY: 1,
                    }}
                  >
                    Phone Number
                  </Typography>
                  <TextField
                    value={data?.phoneNumber || ""}
                    variant="outlined"
                    placeholder="+(389) 70 000 000"
                    onChange={(e) =>
                      handleChangeData("phoneNumber", e.target.value)
                    }
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      marginY: 1,
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.palette.mutedBlue.main,
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: theme.palette.mutedBlue.main,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: theme.palette.darkContrast.main,
                          fontWeight: "bold",
                          marginY: 1,
                          marginBottom: "15px",
                        }}
                      >
                        Date of Birth
                      </Typography>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={
                              data?.dateOfBirth
                                ? new Date(data.dateOfBirth)
                                : null
                            }
                            format="dd/MM/yyyy"
                            onChange={(e) =>
                              handleChangeData("dateOfBirth", e.toISOString())
                            }
                            maxDate={
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 18
                                )
                              )
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: theme.palette.mutedBlue.main,
                                  },
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: theme.palette.mutedBlue.main,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ paddingTop: "5px", paddingBottom: "10px" }} />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.darkContrast.main,
                  fontWeight: "bold",
                  marginY: 3,
                }}
              >
                Social Links
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                value={data?.twitterUrl || ""}
                variant="outlined"
                placeholder="https://www.x.com/your_username"
                onChange={(e) => handleChangeData("twitterUrl", e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip
                        title={
                          <Typography variant="h6">X (Twitter)</Typography>
                        }
                        arrow
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="25"
                          height="25"
                          viewBox="0 0 50 50"
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            fill={`${theme.palette.darkContrast.main}`}
                            d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"
                          ></path>
                        </svg>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  marginY: 1,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.mutedBlue.main,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.mutedBlue.main,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                value={data?.linkedInUrl || ""}
                variant="outlined"
                placeholder="https://www.linkedin.com/in/yourusername"
                onChange={(e) =>
                  handleChangeData("linkedInUrl", e.target.value)
                }
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip
                        title={<Typography variant="h6">LinkedIn</Typography>}
                        arrow
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="25"
                          height="25"
                          viewBox="0 0 50 50"
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"
                            fill={`${theme.palette.darkContrast.main}`}
                          ></path>
                        </svg>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  marginY: 1,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.mutedBlue.main,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.mutedBlue.main,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="mutedBlue"
                onClick={handleSave}
                disabled={saving || !isChanged}
                sx={{
                  marginTop: 2,
                  color: theme.palette.dashboardBackground.main,
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          sx={{
            padding: 5,
            borderRadius: "20px",
            backgroundColor: theme.palette.background.default,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: 5,
            position: "relative",
            marginTop: "15px",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: alpha(theme.palette.mutedBlue.main, 0.8),
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginBottom: "15px",
                  color: theme.palette.dashboardBackground.main,
                }}
              >
                Notification Settings
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.darkContrast.main,
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >
                Notifications
                <FormControlLabel
                  control={
                    <Switch
                      checked={showNotifications}
                      onChange={(e) => {
                        setShowNotifications(!showNotifications);
                      }}
                    />
                  }
                  label=""
                  sx={{ marginLeft: "5px" }}
                />
              </Typography>

              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.lightGray.main, marginTop: "-5px" }}
              >
                Receive notifications
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                filter: showNotifications ? "none" : "blur(2px)",
                transition: "filter 0.3s ease",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.darkContrast.main,
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >
                Email Notifications
              </Typography>
              <Typography
                variant="body"
                sx={{ color: theme.palette.lightGray.main }}
              >
                Get emails to find out what's going on when you're not online.
              </Typography>
              <Grid container spacing={2} pt={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={false} onChange={() => {}} />}
                    label="Updates"
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "text.secondary" }}
                  >
                    Receive updates related to our services.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={false} onChange={() => {}} />}
                    label="Monthly summary"
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "text.secondary" }}
                  >
                    Receive a summary of your activities each month.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                filter: showNotifications ? "none" : "blur(2px)",
                transition: "filter 0.3s ease",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.darkContrast.main,
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >
                Push Notifications
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.lightGray.main }}
              >
                Get notifications in app when you are online.
              </Typography>
              <Grid container spacing={2} pt={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={false} onChange={() => {}} />}
                    label="Reminders"
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "text.secondary" }}
                  >
                    Reminds you of updates that you made.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="mutedBlue"
                onClick={() => {}}
                sx={{
                  marginTop: 2,
                  color: theme.palette.dashboardBackground.main,
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            padding: 5,
            borderRadius: "20px",
            backgroundColor: theme.palette.background.default,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: 5,
            position: "relative",
            marginTop: "15px",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: alpha(theme.palette.mutedBlue.main, 0.8),
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginBottom: "15px",
                  color: theme.palette.dashboardBackground.main,
                }}
              >
                Password & Security
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ClientResetPassword
                username={data?.username}
                loadData={loadData}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
