import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const IndicatorRepository = {
  getAll: () => {
    return Axios({
      url: `${SETTINGS.API_URL}/indicators/all`,
      method: "GET",
    });
  },
  update: (selectedIndicator) => {
    return Axios({
      url: `${SETTINGS.API_URL}/indicators/update`,
      data: selectedIndicator,
      method: "PUT",
    });
  },
};
