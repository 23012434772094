import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { UserRegistrationRepository } from "../../repositories/UserRegistrationRepository";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { notifyShowErrorMessage } from "../../common/CommonActions";
import logo from "../../assets/img/capitelize_logo.png";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Phone } from "@mui/icons-material";
import { LOCALE } from "../../properties/Locale";

export const RegisterUserNow = () => {
  const [globalError, setGlobalError] = useState();
  const [formData, setFormData] = useState({});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [globalFormError, setGlobalFormError] = useState();
  const [loading, setLoading] = useState(false);
  const dispath = useDispatch();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = () => {
    setFormDataErrors({});
    setGlobalFormError();
    let validation = yup.object().shape({
      email: yup.string().required("Required field").email(),
      username: yup.string().required("Required field"),
      firstName: yup.string().required("Required field"),
      phoneNumber: yup.string().required("Required field"),
      lastName: yup.string().required("Required field").nullable(),
      confirmPassword: yup
        .string()
        .required("Required field")
        .min(6)
        .max(20)
        .test("passwords-match", "Passwords must match", function (value) {
          console.log(value, this.parent.password);
          return this.parent.password === value;
        })
        .nullable(),
      password: yup
        .string()
        .required("Required field")
        .min(6)
        .max(20)
        .test("passwords-match", "Passwords must match", function (value) {
          console.log(value, this.parent.confirmPassword);
          return this.parent.confirmPassword === value;
        }),
    });
    if (!validation.isValidSync(formData)) {
      let validationErrors = {};
      validation.validate(formData, { abortEarly: false }).catch((err) => {
        err.inner.forEach((validationError) => {
          validationErrors[validationError.path] = {};
          validationErrors[validationError.path] = validationError.message;
        });
        console.log(validationErrors);
        setFormDataErrors(validationErrors);
      });
    } else {
      setLoading(true);
      let data = { ...formData };
      console.log("users", data);
      UserRegistrationRepository.registerUserNow(data)
        .then((res) => {
          setLoading(false);
          setSnackbarOpen(true);
          setTimeout(() => navigate("/login"), 2000);
        })
        .catch((err) => {
          setLoading(false);
          setGlobalFormError(ErrorMessageResolver.resolve(err));
          dispath(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangeData = (name, value) => {
    setFormData(_.set({ ...formData }, name, value));
  };

  useEffect(() => {
    console.log("Form Data:", formData);
  }, [formData]);
  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={0} md={3}></Grid>
          <Grid item xs={0} md={6}>
            <Paper style={{ padding: "20px", borderRadius: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img
                      src={logo}
                      height={70}
                      style={{ marginLeft: "-15px" }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="h5">Create an account</Typography>
                </Grid>
                {globalError && (
                  <Grid item xs={12}>
                    <Alert severity="error">{globalError}</Alert>
                  </Grid>
                )}
                {loading && (
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "50px", marginBottom: "50px" }}
                  >
                    <CircularProgress />
                  </Grid>
                )}
                {globalFormError && (
                  <Grid item xs={12}>
                    <Alert severity="error">{globalFormError}</Alert>
                  </Grid>
                )}
                {!loading && !globalError && (
                  <>
                    {/* Organization Field */}

                    <Grid item xs={12}>
                      <TextField
                        value={formData?.username || ""}
                        onChange={(e) =>
                          handleChangeData("username", e.target.value)
                        }
                        name="username"
                        error={!!formDataErrors.username}
                        helperText={formDataErrors.username}
                        label="Username"
                        fullWidth
                        style={{ height: "48px" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={formData?.email || ""}
                        onChange={(e) =>
                          handleChangeData("email", e.target.value)
                        }
                        name="email"
                        error={!!formDataErrors.email}
                        helperText={formDataErrors.email}
                        label="Email"
                        fullWidth
                        style={{ height: "48px" }}
                      />
                    </Grid>

                    {/* First Name Field */}
                    <Grid item xs={6}>
                      <TextField
                        value={formData?.firstName || ""}
                        onChange={(e) =>
                          handleChangeData("firstName", e.target.value)
                        }
                        name="firstName"
                        error={!!formDataErrors.firstName}
                        helperText={formDataErrors.firstName}
                        label="First name"
                        fullWidth
                        style={{ height: "48px" }}
                      />
                    </Grid>

                    {/* Last Name Field */}
                    <Grid item xs={6}>
                      <TextField
                        value={formData?.lastName || ""}
                        onChange={(e) =>
                          handleChangeData("lastName", e.target.value)
                        }
                        name="lastName"
                        error={!!formDataErrors.lastName}
                        helperText={formDataErrors.lastName}
                        label="Last name"
                        fullWidth
                        style={{ height: "48px" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={
                              formData?.dateOfBirth
                                ? new Date(formData?.dateOfBirth)
                                : null
                            }
                            format="dd/MM/yyyy"
                            onChange={(e) =>
                              handleChangeData("dateOfBirth", e.toISOString())
                            }
                            maxDate={
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 18
                                )
                              )
                            }
                            error={!!formDataErrors.dateOfBirth}
                            helperText={formDataErrors.dateOfBirth}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={formData?.phoneNumber || ""}
                        variant="outlined"
                        name="phoneNumber"
                        placeholder="+(389) 70 000 000"
                        onChange={(e) =>
                          handleChangeData("phoneNumber", e.target.value)
                        }
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone />
                            </InputAdornment>
                          ),
                        }}
                        error={!!formDataErrors.phoneNumber}
                        helperText={formDataErrors.phoneNumber}
                      />
                    </Grid>

                    {/* Password Field */}
                    <Grid item xs={12}>
                      <TextField
                        value={formData?.password || ""}
                        onChange={(e) =>
                          handleChangeData("password", e.target.value)
                        }
                        name="password"
                        error={!!formDataErrors.password}
                        helperText={formDataErrors.password}
                        label="Password"
                        type="password"
                        fullWidth
                        style={{ height: "48px" }}
                      />
                    </Grid>

                    {/* Confirm Password Field */}
                    <Grid item xs={12}>
                      <TextField
                        value={formData?.confirmPassword || ""}
                        onChange={(e) =>
                          handleChangeData("confirmPassword", e.target.value)
                        }
                        error={!!formDataErrors.confirmPassword}
                        helperText={formDataErrors.confirmPassword}
                        label="Confirm password"
                        name="confirmPassword"
                        type="password"
                        fullWidth
                        style={{ height: "48px" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        style={{
                          backgroundColor: "#10377c",
                          height: "48px",
                        }}
                        variant="contained"
                        fullWidth
                        size="small"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ textTransform: "capitalize" }}
                        >
                          Create an account
                        </Typography>
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={7}
                  style={{ textAlign: "center", marginTop: "20px" }}
                >
                  <Typography style={{ opacity: 0.5, fontWeight: "bold" }}>
                    Already have an account?
                  </Typography>
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#10377c",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {LOCALE.login}
                  </a>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
      </Container>
      <Snackbar open={snackbarOpen} autoHideDuration={10000}>
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%", color: "white" }}
        >
          Check your email for Profile Activation!
        </Alert>
      </Snackbar>
    </>
  );
};
