import { Grid } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Header from "./Header";
import { Sidebar } from "./Sidebar";
import HeaderMobile from "./HeaderMobile";

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MainLayout({ themeMode, setThemeMode }) {
  const [open, setOpen] = React.useState(true);
  const [openMobile, setOpenMobile] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isLoginPath = window.location.pathname === "/login";

  return (
    <Box sx={{ display: { xs: "block", md: "flex" }, width: "100%" }}>
      {![
        "/login",
        "/forgotPassword",
        "/reset-password",
        "/registerUser",
        "/activate-user",
      ].includes(window.location.pathname) &&
        !window.location.pathname.startsWith("/invitation") && (
          <>
            <Sidebar
              setOpen={setOpen}
              open={open}
              sx={{ display: { xs: "none", md: "block" } }}
            />
            <Header
              sx={{ display: { xs: "none", md: "block" } }}
              setOpen={setOpen}
              open={open}
              themeMode={themeMode}
              setThemeMode={setThemeMode}
            />
            <HeaderMobile
              sx={{ display: { xs: "block", md: "none" } }}
              setOpenMobile={setOpenMobile}
              openMobile={openMobile}
              themeMode={themeMode}
              setThemeMode={setThemeMode}
            />
          </>
        )}

      <Box component="main" sx={{ flexGrow: 1, p: isLoginPath ? 0 : 2 }}>
        {
          <Grid
            container
            spacing={2}
            sx={{ marginTop: isLoginPath ? "-16x" : "45px" }}
          >
            <Grid item xs={12}>
              <AppRoutes />
            </Grid>
          </Grid>
        }
      </Box>
    </Box>
  );
}
