export const SideMenuLinks = [
  {
    name: "CAPITELIZE",
    path: "/",
    roles: ["ROLE_CLIENT", "ROLE_ADMINISTRATION"],
  },
  {
    name: "Dashboard",
    path: "/home",
    selectedPath: "/home",
    icon: "grid_view_outlined",
    roles: ["ROLE_ADMINISTRATION"],
  },
  {
    name: "Users",
    path: "/users",
    selectedPath: "/users",
    icon: "person",
    roles: ["ROLE_ADMINISTRATION"],
  },
  {
    name: "Companies",
    path: "/companies",
    selectedPath: "/companies",
    icon: "apartment",
    roles: ["ROLE_ADMINISTRATION"],
  },
  {
    name: "Stock Data",
    path: "/stockData",
    selectedPath: "/stockData",
    icon: "show_chart",
    roles: ["ROLE_ADMINISTRATION"],
  },
  {
    name: "Chart Data",
    path: "/chartData",
    selectedPath: "/chartData",
    icon: "add_chart",
    roles: ["ROLE_ADMINISTRATION"],
  },
  {
    name: "Consultant",
    path: "/",
    selectedPath: "/",
    icon: "support_agent",
    roles: ["ROLE_CLIENT"],
  },
  {
    name: "My Plan",
    path: "/myPlan",
    selectedPath: "/myPlan",
    icon: "pending_actions",
    roles: ["ROLE_CLIENT"],
  },
  {
    name: "History",
    path: "/history",
    selectedPath: "/history",
    icon: "history",
    roles: ["ROLE_CLIENT"],
  },
  {
    name: "Settings",
    path: "/settings",
    selectedPath: "/settings",
    icon: "settings",
    roles: ["ROLE_CLIENT", "ROLE_ADMINISTRATION"],
    submenus: [
      {
        name: "Profile Settings",
        path: "/profileSettings",
        selectedPath: "/profileSettings",
        icon: "admin_panel_settings",
        roles: ["ROLE_ADMINISTRATION", "ROLE_CLIENT"],
      },
      {
        name: "Indicator Settings",
        path: "/indicatorSettings",
        selectedPath: "/indicatorSettings",
        icon: "app_settings_alt",
        roles: ["ROLE_ADMINISTRATION"],
      },
    ],
  },
];

export const ProfileListMobile = [
  {
    name: "Profile",
    path: "/myProfile",
    selectedPath: "/myProfile",
    roles: ["ROLE_CLIENT", "ROLE_ADMINISTRATION"],
  },
];

export const SideMenuLinksDown = [
  {
    name: "Profile",
    path: "/myProfile",
    selectedPath: "/myProfile",
    roles: ["ROLE_CLIENT", "ROLE_ADMINISTRATION"],
  },
  {
    name: "Logout",
    path: "/login",
    selectedPath: "/login",
    icon: "logout",
    roles: ["ROLE_CLIENT", "ROLE_ADMINISTRATION"],
  },
];

export const LogoutListMobile = [
  {
    name: "Logout",
    path: "/login",
    selectedPath: "/login",
    icon: "logout",
    roles: ["ROLE_CLIENT"],
  },
];
