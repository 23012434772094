import { useTheme } from "@emotion/react";
import { alpha, Collapse, Icon, List, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthRepository } from "../repositories/AuthRepository";
import { Drawer } from "./MainLayout";
import { SideMenuLinks, SideMenuLinksDown } from "./SideMenuLink";
import { UsersRepository } from "../repositories/UsersRepository";
import { notifyShowErrorMessage } from "./CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export const Sidebar = ({ setOpen, open }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [menuLinks, setMenuLinks] = React.useState(SideMenuLinks);
  const [menuLinksDown, setMenuLinksDown] = React.useState(SideMenuLinksDown);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const [submenuExpanded, setSubmenuExpanded] = useState(false);

  const loadData = () => {
    const userId = AuthRepository.getUserDetails()?.userId;
    if (userId !== undefined) {
      setLoading(true);
      UsersRepository.getUser()
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          dispatch(notifyShowErrorMessage("Unable to load user data!"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    loadData();
  }, [globalState.triggerRerender]);

  const checkActiveTab = (item) => {
    if (item?.path !== "") {
      return (
        (window.location.pathname.includes(item.selectedPath) &&
          window.location.pathname !== "/" &&
          item.path !== "/") ||
        (window.location.pathname === item.selectedPath &&
          item.selectedPath === "/")
      );
    }

    return false;
  };

  const getUserInitials = (data) => {
    if (!data) return "";
    const firstNameInitial = data?.firstName?.charAt(0)?.toUpperCase() || "";
    const lastNameInitial = data?.lastName?.charAt(0)?.toUpperCase() || "";
    return firstNameInitial + lastNameInitial;
  };

  const firstLine = data?.email.substring(0, 22);
  const secondLine = data?.email.substring(22);

  return (
    <>
      <Drawer
        variant={"permanent"}
        open={open}
        color="default"
        sx={{
          display: { xs: "none", md: "block" },
          position: "relative",
          boxShadow: `0px 3px 5px rgba(0, 0, 0, 0.2)`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        PaperProps={{
          sx: {
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
              zIndex: (theme) => theme.zIndex.drawer,
              position: "relative",
            },
          },
        }}
      >
        <div style={{ flex: 1 }}>
          <List>
            {menuLinks.map(
              (item, index) =>
                AuthRepository.hasAnyRole(item.roles) && (
                  <React.Fragment key={index}>
                    <>
                      <ListItem
                        button
                        key={index}
                        onClick={() => {
                          if (item.name === "Settings") {
                            setSubmenuExpanded(!submenuExpanded);
                          } else if (item.name === "CAPITELIZE") {
                            if (AuthRepository.hasRole("ROLE_ADMINISTRATION")) {
                              navigate("/home");
                            } else {
                              navigate("/");
                            }
                          } else {
                            navigate(item.path);
                          }
                        }}
                        style={{
                          backgroundColor: checkActiveTab(item)
                            ? alpha("#10377c", 0.8)
                            : theme.palette.dashboardBackground.light,
                          color:
                            checkActiveTab(item) &&
                            theme.palette.dashboardBackground.light,
                          borderRadius: "8px",
                          transition: "all 0.3s ease",
                          transform: "scale(0.93)",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: "40px",
                            minHeight: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.name === "CAPITELIZE" ? (
                            <img
                              src={
                                theme.palette.mode == "dark"
                                  ? require("../assets/img/capitelize_logo_inverted.png")
                                  : require("../assets/img/capitelize_logo.png")
                              }
                              alt="CAPITELIZE"
                              style={{
                                width: "50px",
                                height: "50px",
                                marginLeft: "-5px",
                              }}
                            />
                          ) : (
                            <Icon
                              sx={{
                                color: checkActiveTab(item)
                                  ? "#ffffff"
                                  : theme.palette.darkContrast.main,
                                fontSize: "20px",
                              }}
                            >
                              {item.icon}
                            </Icon>
                          )}
                        </ListItemIcon>
                        {open && (
                          <>
                            <ListItemText
                              primary={
                                item.name === "CAPITELIZE" ? (
                                  <Typography
                                    variant="body1"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "25px",
                                      color: theme.palette.darkContrast.main,
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      color: checkActiveTab(item)
                                        ? "#ffffff"
                                        : theme.palette.darkContrast.main,
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                )
                              }
                              style={{ marginLeft: "5px" }}
                            />
                          </>
                        )}
                        {item.name === "Settings" ? (
                          submenuExpanded ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : null}
                      </ListItem>
                      {item.name === "Settings" && (
                        <Collapse
                          in={submenuExpanded}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {item.submenus
                              .filter((submenu) => {
                                if (
                                  AuthRepository.hasAnyRole(
                                    "ROLE_ADMINISTRATION"
                                  )
                                ) {
                                  return true;
                                }
                                if (AuthRepository.hasAnyRole("ROLE_CLIENT")) {
                                  return submenu.name === "Profile Settings";
                                }
                                return false;
                              })
                              .map((submenu, subIndex) => (
                                <ListItem
                                  button
                                  key={subIndex}
                                  onClick={() => navigate(submenu.path)}
                                  sx={{
                                    paddingLeft: "35px",
                                    backgroundColor: checkActiveTab(submenu)
                                      ? alpha("#10377c", 0.8)
                                      : theme.palette.dashboardBackground.light,
                                    color:
                                      checkActiveTab(item) &&
                                      theme.palette.dashboardBackground.light,
                                    borderRadius: "8px",
                                    transition: "all 0.2s ease",
                                    transform: "scale(0.95)",
                                    "&:hover": {
                                      backgroundColor: checkActiveTab(submenu)
                                        ? alpha("#10377c", 0.8)
                                        : alpha(
                                            theme.palette.dashboardBackground
                                              .main,
                                            0.8
                                          ),
                                    },
                                  }}
                                >
                                  <ListItemIcon>
                                    <Icon
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        color: checkActiveTab(submenu)
                                          ? "#ffffff"
                                          : theme.palette.darkContrast.main,
                                      }}
                                    >
                                      {submenu.icon}
                                    </Icon>
                                  </ListItemIcon>
                                  <ListItemText
                                    sx={{
                                      color: checkActiveTab(submenu)
                                        ? "#ffffff"
                                        : theme.palette.darkContrast.main,
                                    }}
                                  >
                                    <Typography
                                      variant="h6"
                                      sx={{ fontWeight: "light" }}
                                    >
                                      {submenu.name}
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      )}
                    </>
                  </React.Fragment>
                )
            )}
          </List>
        </div>
        <Divider style={{ borderColor: "rgb(255 255 255 / 20%)" }} />

        <div style={{ marginTop: "auto" }}>
          <List>
            {menuLinksDown.map(
              (item, index) =>
                AuthRepository.hasAnyRole(item.roles) && (
                  <React.Fragment key={index}>
                    <>
                      <ListItem
                        button
                        key={index}
                        onClick={() => {
                          if (item.name === "Logout") {
                            AuthRepository.logout();
                          } else {
                            navigate(item.path);
                          }
                        }}
                        style={{
                          backgroundColor: checkActiveTab(item)
                            ? alpha(theme.palette.darkContrast.main, 0.08)
                            : "transparent",
                          paddingLeft: "20px",
                          paddingRight: "5px",
                          borderRadius: "10px",
                          width: "92%",
                          marginLeft: "10px",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: "30px",
                            minHeight: "25px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.name === "Profile" ? (
                            <div
                              style={{
                                borderColor: alpha(
                                  theme.palette.darkContrast.main,
                                  0.6
                                ),
                                borderRadius: "4px",
                                width: "37px",
                                height: "39px",
                                minWidth: "30px",
                                minHeight: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                fontSize: "19px",
                                fontWeight: "bold",
                                color: alpha(theme.palette.mutedBlue.main, 1),
                              }}
                            >
                              {getUserInitials(data)}
                            </div>
                          ) : (
                            <Icon
                              sx={{
                                color: theme.palette.darkContrast.main,
                                fontSize: "20px",
                              }}
                            >
                              {item.icon}
                            </Icon>
                          )}
                        </ListItemIcon>
                        {open && (
                          <>
                            <ListItemText
                              primary={
                                item.name === "Profile" ? (
                                  <div>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: theme.palette.darkContrast.main,
                                        fontSize: "10px",
                                      }}
                                    >
                                      {data?.organization?.nameEn}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        marginTop: "-1px",
                                        marginBottom: "-1px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <b>
                                        {data?.firstName + " " + data?.lastName}
                                      </b>
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "12px",
                                        color: alpha(
                                          theme.palette.mutedBlue.main,
                                          1
                                        ),
                                      }}
                                    >
                                      {firstLine}
                                    </Typography>
                                    {secondLine && (
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          color: alpha(
                                            theme.palette.mutedBlue.main,
                                            1
                                          ),
                                        }}
                                      >
                                        {secondLine}
                                      </Typography>
                                    )}
                                  </div>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                )
                              }
                              style={{ marginLeft: "5px" }}
                              onClick={() => {
                                navigate(item.path);
                              }}
                            />
                          </>
                        )}
                      </ListItem>
                      {item.name === "Profile" && (
                        <Divider
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            borderBottomWidth: "2px",
                          }}
                        />
                      )}
                    </>
                  </React.Fragment>
                )
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
};
