import React, { useState } from "react";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Typography,
  Grid,
  Icon,
  useMediaQuery,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const ATRChart = ({
  ATRData,
  latestDataATR,
  setLatestDataATR,
  brushStartIndexATR,
  brushEndIndexATR,
  setBrushStartIndexATR,
  setBrushEndIndexATR,
  left,
  right,
  height,
}) => {
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={9}
          style={{ textAlign: isSmallScreen && "center" }}
        >
          <Typography
            variant="h5"
            style={{ color: theme.palette.mutedBlue.main }}
          >
            Indicator 4: ATR{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer height={height}>
              <ComposedChart data={ATRData}>
                <CartesianGrid strokeDasharray="0.1 0.1" />
                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  domain={[left, right]}
                  type="category"
                />
                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[-1.5, 1.5]}
                  ticks={[-1.5, -1, -0.5, 0, 1, 1.5]}
                  type="number"
                  yAxisId="1"
                />
                <YAxis
                  orientation="left"
                  allowDataOverflow
                  domain={[0, "dataMax + 5"]}
                  type="number"
                  yAxisId="2"
                />
                <Tooltip
                  cursor={false}
                  formatter={(value, name, props) => [
                    `${value} - ${name == "Signal" ? "" : name} ${
                      name == "Signal" ? props.payload.signal + " - Signal" : ""
                    }`,
                  ]}
                  labelFormatter={(value) => `Date: ${value}`}
                  itemStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                />
                <Brush
                  onChange={(e) => {
                    let item;
                    ATRData.map((d, index) => {
                      if (d.index === e.endIndex) {
                        item = d;
                      }
                    });
                    setLatestDataATR(item);
                    setBrushStartIndexATR(e.startIndex);
                    setBrushEndIndexATR(e.endIndex);
                  }}
                  data={ATRData}
                  dataKey="index"
                  height={20}
                  fill={`${theme.palette.background.paper}`}
                  startIndex={brushStartIndexATR}
                  endIndex={brushEndIndexATR}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="center"
                />

                <Line
                  yAxisId="2"
                  type="linear"
                  dataKey="price"
                  name="Price"
                  stroke="#f29157"
                  strokeWidth={5}
                  dot={false}
                  animationDuration={3000}
                />

                <Bar
                  yAxisId="1"
                  dataKey="signalNumber"
                  name="Signal"
                  fill="#b2b2b2"
                  barSize={1.5}
                />

                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          style={{ textAlign: isSmallScreen ? "center" : "left" }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align={isSmallScreen ? "center" : "left"}
                >
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.mutedBlue.main }}
                  >
                    {latestDataATR?.name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Date:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataATR?.date}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Price:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataATR?.price?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Average True Range:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataATR?.avgTrueRange?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Signal Numeric:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataATR?.signalNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Signal:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: "light" }}>
                    {latestDataATR?.signal === "BUY"
                      ? "BUY"
                      : latestDataATR?.signal === "SELL"
                      ? "SELL"
                      : latestDataATR?.signal === "NEUTRAL"
                      ? "NEUTRAL"
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  sx={{ borderBottom: "none" }}
                >
                  <Icon
                    sx={{ fontSize: "100px", marginTop: "10px" }}
                    color={
                      latestDataATR?.signal?.includes("BUY")
                        ? "success"
                        : latestDataATR?.signal?.includes("SELL")
                        ? "error"
                        : "warning"
                    }
                  >
                    {latestDataATR?.signal?.includes("BUY")
                      ? "thumb_up"
                      : latestDataATR?.signal?.includes("SELL")
                      ? "thumb_down"
                      : "sentiment_neutral"}
                  </Icon>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>{" "}
        <Grid item xs={12} md={8}>
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer height={height}>
              <ComposedChart data={ATRData}>
                <CartesianGrid strokeDasharray="0.1 0.1" />
                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  domain={[left, right]}
                  type="category"
                />
                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[-1.5, 1.5]}
                  ticks={[-1.5, -1, -0.5, 0, 1, 1.5]}
                  type="number"
                  yAxisId="1"
                />
                <YAxis
                  orientation="left"
                  allowDataOverflow
                  domain={["dataMin - 10", "dataMax + 10"]}
                  type="number"
                  tickFormatter={(value) => value.toFixed(2)}
                  yAxisId="2"
                />
                <Tooltip
                  cursor={false}
                  formatter={(value, name, props) => [
                    `${
                      name == "Average True Range" ? value.toFixed(2) : value
                    } - ${name == "Signal" ? "" : name} ${
                      name == "Signal" ? props.payload.signal + " - Signal" : ""
                    }`,
                  ]}
                  labelFormatter={(value) => `Date: ${value}`}
                  itemStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                />
                <Brush
                  onChange={(e) => {
                    let item;
                    ATRData.map((d, index) => {
                      if (d.index === e.endIndex) {
                        item = d;
                      }
                    });
                    setLatestDataATR(item);
                    setBrushStartIndexATR(e.startIndex);
                    setBrushEndIndexATR(e.endIndex);
                  }}
                  data={ATRData}
                  dataKey="index"
                  height={20}
                  fill={`${theme.palette.background.paper}`}
                  startIndex={brushStartIndexATR}
                  endIndex={brushEndIndexATR}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="center"
                />

                <Line
                  yAxisId="2"
                  type="linear"
                  dataKey="avgTrueRange"
                  name="Average True Range"
                  stroke="#5f8fb5"
                  strokeWidth={5}
                  dot={false}
                  animationDuration={3000}
                />

                <Bar
                  yAxisId="1"
                  dataKey="signalNumber"
                  name="Signal"
                  fill="#b2b2b2"
                  barSize={1.5}
                />

                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ATRChart;
