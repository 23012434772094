import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/CommonActions";
import { useDispatch } from "react-redux";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { CompanyRepository } from "../../repositories/CompanyRepository";
import { LOCALE } from "../../properties/Locale";
import { AuthRepository } from "../../repositories/AuthRepository";
import { SectorRepository } from "../../repositories/SectorRepository";
import { Clear, Close } from "@mui/icons-material";
import { TableRowEmptyData } from "../../common/TableRowEmptyData";
import { useTheme } from "@emotion/react";

export default function CompaniesList() {
  const [searchParams, setSearchParams] = useState({});
  const [data, setData] = useState({});
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [sectors, setSectors] = useState();
  const [sectorMap, setSectorMap] = useState({});
  const [companies, setCompanies] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedSector, setSelectedSector] = useState();

  useEffect(() => {
    loadData();
    loadSectors();
  }, [searchParams, page, size]);

  const loadData = () => {
    setLoading(true);
    CompanyRepository.getAll(page, size, JSON.stringify({ ...searchParams }))
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  useEffect(() => {
    CompanyRepository.getAllWithoutPaging()
      .then((res) => setCompanies(res.data))
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    SectorRepository.getAll()
      .then((res) => setSectors(res.data))
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
  }, []);

  const loadSectors = () => {
    setLoading(true);
    SectorRepository.getAll()
      .then((res) => {
        setSectors(res.data);
        const map = {};
        res.data.forEach((sector) => {
          map[sector?.id] = sector?.name;
        });
        setSectorMap(map);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  const findSectorById = (sectorId) => {
    return sectorMap[sectorId] || "Unknown Sector";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleUploadFile = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleSubmitFile = () => {
    CompanyRepository.uploadData(file)
      .then((res) => {
        dispatch(notifyShowSuccessMessage("File uploaded successfully!"));
        loadData();
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
  };

  const handleChange = (name, value) => {
    let tmp = { ...searchParams };
    tmp[name] = value;
    setSearchParams(tmp);
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
    let tmp = { ...searchParams };
    tmp["name"] = newValue?.name;
    setSearchParams(tmp);
  };

  const handleSectorChange = (event, newValue) => {
    setSelectedSector(newValue);
    let tmp = { ...searchParams };
    tmp["sector.name"] = newValue?.name;
    setSearchParams(tmp);
  };

  const clearFilters = () => {
    setSearchParams({});
    setSelectedSector(null);
    setSelectedCompany(null);
  };

  return (
    <Paper sx={{ padding: 5, borderRadius: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} pt={3}>
          <Typography
            variant="h3"
            sx={{ textAlign: { xs: "center", md: "start" } }}
          >
            Companies
            <Tooltip title={<Typography variant="h6">Refresh</Typography>}>
              <IconButton
                onClick={() => {
                  setSearchParams({});
                }}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: 1,
                  minWidth: "auto",
                  height: "auto",
                  marginLeft: "10px",
                  display: "inline-flex",
                }}
              >
                <Icon
                  style={{
                    color: theme.palette.darkContrast.main,
                    fontSize: "25px",
                  }}
                >
                  refresh
                </Icon>
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={1.5}
          sx={{
            textAlign: "center",
            padding: 2,
            marginTop: "5px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Update Company Data
          </Typography>
          <TextField
            type="file"
            inputProps={{ accept: ".xls, .xlsx" }}
            onChange={(e) => handleUploadFile(e, setFile)}
            sx={{
              display: "none",
            }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              color="mutedBlue"
              sx={{
                mt: 1,
                color: "white",
              }}
            >
              Choose File
            </Button>
          </label>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {file ? <>{file.name}</> : "Accepts: .xls, .xlsx"}
          </Typography>
        </Grid>
        {file != null && (
          <>
            <Grid
              item
              xs={11}
              md={1}
              sx={{
                textAlign: "center",
                padding: 2,
                alignContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="mutedBlue"
                fullWidth
                onClick={handleSubmitFile}
                sx={{ color: "white" }}
              >
                Submit
              </Button>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                textAlign: "left",
                padding: 2,
                alignContent: "center",
                marginLeft: "-20px",
              }}
            >
              <Tooltip title={<Typography variant="h6">Clear</Typography>}>
                <IconButton>
                  <Close
                    onClick={() => {
                      setFile(null);
                      document.getElementById("file-upload").value = "";
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Autocomplete
                size="small"
                fullWidth
                options={companies}
                getOptionLabel={(option) => option?.name || ""}
                value={selectedCompany ? selectedCompany : null}
                onChange={handleCompanyChange}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.isin}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company"
                    fullWidth
                    variant="outlined"
                  />
                )}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.mutedBlue.main,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.mutedBlue.main,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                size="small"
                onChange={(e) =>
                  handleChange("bloombergTicker", e.target.value)
                }
                label={"Bloomberg Ticker"}
                value={
                  searchParams?.bloombergTicker
                    ? searchParams?.bloombergTicker
                    : ""
                }
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.mutedBlue.main,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.mutedBlue.main,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                size="small"
                onChange={(e) => handleChange("ISIN", e.target.value)}
                label={"ISIN"}
                value={searchParams?.ISIN ? searchParams?.ISIN : ""}
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.mutedBlue.main,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.mutedBlue.main,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                size="small"
                fullWidth
                options={sectors}
                getOptionLabel={(option) => option?.name || ""}
                value={selectedSector ? selectedSector : null}
                onChange={handleSectorChange}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.isin}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sector"
                    fullWidth
                    variant="outlined"
                  />
                )}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.mutedBlue.main,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.mutedBlue.main,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {Object.keys(searchParams).length > 0 && (
                <Tooltip
                  title={<Typography variant="h6">Clear filters</Typography>}
                >
                  {isSmallScreen ? (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => clearFilters()}
                    >
                      Clear filters
                    </Button>
                  ) : (
                    <IconButton onClick={() => clearFilters()}>
                      <Clear
                        sx={{
                          fontSize: "22px",
                        }}
                      />
                    </IconButton>
                  )}
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              "@media (min-width: 600px)": {
                overflowX: "visible",
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">No.</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Bloomberg Ticker</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">ISIN</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Company Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Sector</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Primary Index</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && data && data?.length === 0 && (
                  <TableRowEmptyData />
                )}
                {data &&
                  data?.content?.map((company, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {index + 1 + page * size}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {company?.bloombergTicker}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {company?.isin}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {company?.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {company?.sector?.name
                            ? company?.sector?.name
                            : findSectorById(company?.sector)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {company?.primaryIndex}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <TablePagination
                      rowsPerPageOptions={
                        AuthRepository.hasAnyRole(["ROLE_ADMINISTRATION"])
                          ? [5, 10, 15, 25, 50, 75, 100]
                          : [5, 10, 15, 25]
                      }
                      rowsPerPage={data?.size ? data?.size : size}
                      labelRowsPerPage={LOCALE.rows_per_page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": LOCALE.rows_per_page,
                        },
                        native: true,
                      }}
                      onRowsPerPageChange={(event, value) => {
                        handleChangeRowsPerPage(event);
                      }}
                      ActionsComponent={(subProps) => {
                        const {
                          page,
                          count,
                          rowsPerPage,
                          backIconButtonProps,
                          nextIconButtonProps,
                          showLastButton,
                          getItemAriaLabel,
                          showFirstButton,
                          onPageChange,
                          ...restSubProps
                        } = subProps;
                        return (
                          <>
                            <Pagination
                              sx={{
                                minWidth: "350px",
                                paddingLeft: "10px",
                              }}
                              count={
                                count % rowsPerPage === 0
                                  ? count / rowsPerPage
                                  : Math.floor(count / rowsPerPage) + 1
                              }
                              shape="rounded"
                              size="small"
                              page={page + 1}
                              showFirstButton
                              showLastButton
                              onChange={(event, value) => {
                                handleChangePage(event, value - 1);
                              }}
                            />
                          </>
                        );
                      }}
                      page={data?.number}
                      count={data?.totalElements}
                      sx={{
                        borderBottom: "none",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
