import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { IndicatorRepository } from "../../repositories/IndicatorRepository";
import { useDispatch } from "react-redux";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/CommonActions";
import { DragIndicator } from "@mui/icons-material";
import { CompanyRepository } from "../../repositories/CompanyRepository";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { CompanyIndicatorRepository } from "../../repositories/CompanyIndicatorRepository";
import { useTheme } from "@emotion/react";
import { alpha } from "@mui/system";

export default function IndicatorSettings() {
  const [indicators, setIndicators] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState();
  const [selectedCompanyIndicator, setSelectedCompanyIndicator] = useState();
  const [selectedTerm, setSelectedTerm] = useState("");
  const [selectedCompany, setSelectedCompany] = useState();
  var _ = require("lodash");
  const dispatch = useDispatch();
  const [saveToCompanyIndicator, setSaveToCompanyIndicator] = useState(false);
  const [companies, setCompanies] = useState([]);
  const theme = useTheme();
  const indicatorRefs = useRef({});

  useEffect(() => {
    if (selectedCompany == null) {
      setSelectedIndicator();
      setSelectedTerm("");
      setSelectedCompanyIndicator();
      setSaveToCompanyIndicator(false);
    }
  }, [selectedCompany]);

  useEffect(() => {
    IndicatorRepository.getAll()
      .then((res) => {
        setIndicators(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (
      (selectedCompany != undefined ||
        selectedTerm != undefined ||
        selectedCompanyIndicator == undefined) &&
      saveToCompanyIndicator
    ) {
      CompanyIndicatorRepository.getByCompanyAndTerm(
        selectedCompany?.id,
        selectedTerm,
        selectedIndicator?.name
      )
        .then((res) => {
          setSelectedCompanyIndicator(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedTerm]);

  useEffect(() => {
    if (selectedCompanyIndicator) {
      const updatedIndicator = { ...selectedIndicator };

      const allowedKeys = [
        "rsiAverageGain",
        "rsiAverageLoss",
        "rsiBuyLevel",
        "rsiSellLevel",
        "bollingerSMA",
        "obvSmallVolume",
        "obvBigVolume",
        "aroonHighestHighPeriods",
        "aroonPeriodsSinceHighestHigh",
        "aroonUp",
        "aroonLowestLowPeriods",
        "aroonPeriodsSinceLowestLow",
        "aroonDown",
        "rocWeekly",
        "momentumWeekly",
        "stochasticLookbackPeriod",
        "stochasticSlow",
        "atrAvgTrueRange",
        "atrDailyChangePercentage",
        "atrLongTermPerformance",
        "atrShortTermPerformance",
        "atrMaxSignalPercentage",
        "atrMinSignalPercentage",
      ];

      Object.keys(selectedCompanyIndicator).forEach((key) => {
        if (
          allowedKeys.includes(key) &&
          selectedCompanyIndicator[key] !== null &&
          selectedCompanyIndicator[key] !== undefined
        ) {
          updatedIndicator[key] = selectedCompanyIndicator[key];
        }
      });

      setSelectedIndicator(updatedIndicator);
    }
  }, [selectedCompanyIndicator]);

  useEffect(() => {
    CompanyRepository.getAllWithoutPaging()
      .then((res) => setCompanies(res.data))
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
  }, []);

  const handleUpdate = (selectedIndicator) => {
    if (saveToCompanyIndicator) {
      CompanyIndicatorRepository.update(
        selectedCompany,
        selectedIndicator,
        selectedTerm
      )
        .then((res) => {
          dispatch(
            notifyShowSuccessMessage(
              "Company Indicator settings successfully updated!"
            )
          );
        })
        .catch((err) => {
          dispatch(
            notifyShowErrorMessage("Company Indicator settings update failed!")
          );
        });
    } else {
      IndicatorRepository.update(selectedIndicator)
        .then((res) => {
          dispatch(
            notifyShowSuccessMessage("Indicator settings successfully updated!")
          );
        })
        .catch((err) => {
          dispatch(notifyShowErrorMessage("Indicator settings update failed!"));
        });
    }
  };

  const handleSelectIndicator = (indicator) => {
    setSelectedIndicator(indicator);
  };

  useEffect(() => {
    if (selectedIndicator) {
      indicatorRefs.current[selectedIndicator.id]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedIndicator]);

  const handleTermChange = (event) => {
    setSelectedTerm(event.target.value);
  };

  const handleChange = (name, value) => {
    if (selectedCompany) {
      setSelectedCompanyIndicator(
        _.set({ ...selectedCompanyIndicator }, name, parseFloat(value))
      );
    } else {
      setSelectedIndicator(
        _.set({ ...selectedIndicator }, name, parseFloat(value))
      );
    }
  };

  const handleToggle = (event) => {
    const newValue = event.target.checked;
    setSaveToCompanyIndicator(newValue);
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Paper
        sx={{
          padding: 5,
          borderRadius: "20px",
          backgroundColor: theme.palette.background.default,
          backgroundSize: "cover",
          backgroundPosition: "center",
          boxShadow: 5,
          position: "relative",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} pt={3} pb={3}>
            <Typography
              variant="h3"
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              <DragIndicator
                sx={{
                  fontSize: "40px",
                  mr: 1,
                }}
              />
              Indicator Settings
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="center">
              {indicators &&
                indicators.map((indicator) => (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    key={indicator.id}
                    onClick={() => handleSelectIndicator(indicator)}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        borderRadius: "10px",
                        padding: { xs: 3, md: 5 },
                        cursor: "pointer",
                        backgroundColor:
                          selectedIndicator?.id === indicator.id
                            ? alpha(theme.palette.mutedBlue.main, 0.9)
                            : "transparent",
                        color:
                          selectedIndicator?.id === indicator.id
                            ? "white"
                            : theme.palette.darkContrast.main,
                        transition: "transform 0.3s, box-shadow 0.3s",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                          backgroundColor: alpha(
                            theme.palette.mutedBlue.main,
                            0.9
                          ),
                          color: "white",
                        },
                      }}
                    >
                      <Typography variant="h5" align="center">
                        {indicator?.name}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} pb={1}>
                <Divider />
              </Grid>
              {selectedIndicator && (
                <>
                  <Grid
                    item
                    xs={12}
                    pb={3}
                    ref={(el) =>
                      (indicatorRefs.current[selectedIndicator.id] = el)
                    }
                  >
                    <Typography variant="h4">
                      Change settings for:
                      <span style={{ color: theme.palette.extra1.main }}>
                        {" "}
                        {selectedIndicator?.name}
                      </span>
                    </Typography>
                  </Grid>
                </>
              )}

              {selectedIndicator?.name == "RSI" && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Possible change: 5 days - 5 weeks (35 days)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">Average Gain (days)</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="rsiAverageGain"
                      variant="outlined"
                      type="number"
                      InputProps={{ inputProps: { min: 5, max: 35 } }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.rsiAverageGain
                          ? selectedCompanyIndicator?.rsiAverageGain
                          : selectedIndicator?.rsiAverageGain
                      }
                      onChange={(e) =>
                        handleChange("rsiAverageGain", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Possible change: 5 days - 5 weeks (35 days)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">Average Loss (days)</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="rsiAverageLoss"
                      variant="outlined"
                      type="number"
                      InputProps={{ inputProps: { min: 5, max: 35 } }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.rsiAverageLoss
                          ? selectedCompanyIndicator?.rsiAverageLoss
                          : selectedIndicator?.rsiAverageLoss
                      }
                      onChange={(e) =>
                        handleChange("rsiAverageLoss", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Default: 30 - 40, Possible change: 30 - 45
                        </Typography>
                      }
                    >
                      <Typography variant="h6">Buy Level </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="rsiBuyLevel"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 40, max: 45 },
                        startAdornment: (
                          <InputAdornment position="start">
                            30 -{" "}
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.rsiBuyLevel
                          ? selectedCompanyIndicator?.rsiBuyLevel
                          : selectedIndicator?.rsiBuyLevel
                      }
                      onChange={(e) =>
                        handleChange("rsiBuyLevel", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Default: 60 - 70, Possible change: 55 - 70
                        </Typography>
                      }
                    >
                      <Typography variant="h6">Sell Level </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="rsiSellLevel"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 55, max: 70 },
                        endAdornment: (
                          <InputAdornment position="end"> - 70</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.rsiSellLevel
                          ? selectedCompanyIndicator?.rsiSellLevel
                          : selectedIndicator?.rsiSellLevel
                      }
                      onChange={(e) =>
                        handleChange("rsiSellLevel", e.target.value)
                      }
                    />
                  </Grid>
                </>
              )}
              {selectedIndicator?.name == "Bollinger Bands" && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          SMA (10, 20, 50, 100, 200 days)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">SMA (days)</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      select
                      name="bollingerSMA"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.bollingerSMA
                          ? selectedCompanyIndicator?.bollingerSMA
                          : selectedIndicator?.bollingerSMA
                      }
                      onChange={(e) =>
                        handleChange("bollingerSMA", e.target.value)
                      }
                    >
                      {[10, 20, 50, 100, 200].map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
              {selectedIndicator?.name == "OBV" && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "start",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Possible change: 5 - 60 (periods)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">Short Term Ø Volume</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="obvSmallVolume"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 5, max: 60 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.obvSmallVolume
                          ? selectedIndicator?.obvSmallVolume
                          : selectedIndicator?.obvSmallVolume
                      }
                      onChange={(e) =>
                        handleChange("obvSmallVolume", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Possible change: 5 - 60 (periods)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">Long Term Ø Volume</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="obvBigVolume"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 5, max: 60 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.obvBigVolume
                          ? selectedIndicator?.obvBigVolume
                          : selectedIndicator?.obvBigVolume
                      }
                      onChange={(e) =>
                        handleChange("obvBigVolume", e.target.value)
                      }
                    />
                  </Grid>
                </>
              )}
              {selectedIndicator?.name == "Aroon Oscilator" && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Typography variant="h6">Highest High Periods</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="aroonHighestHighPeriods"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.aroonHighestHighPeriods
                          ? selectedCompanyIndicator?.aroonHighestHighPeriods
                          : selectedIndicator?.aroonHighestHighPeriods
                      }
                      onChange={(e) =>
                        handleChange("aroonHighestHighPeriods", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Typography variant="h6">
                      Periods since Highest High
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="aroonPeriodsSinceHighestHigh"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.aroonPeriodsSinceHighestHigh
                          ? selectedCompanyIndicator?.aroonPeriodsSinceHighestHigh
                          : selectedIndicator?.aroonPeriodsSinceHighestHigh
                      }
                      onChange={(e) =>
                        handleChange(
                          "aroonPeriodsSinceHighestHigh",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Typography variant="h6">Aroon Up</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="aroonUp"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.aroonUp
                          ? selectedCompanyIndicator?.aroonUp
                          : selectedIndicator?.aroonUp
                      }
                      onChange={(e) => handleChange("aroonUp", e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                      marginLeft: "1px",
                    }}
                  >
                    <Typography variant="h6">Lowest Low Periods</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="aroonLowestLowPeriods"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.aroonLowestLowPeriods
                          ? selectedCompanyIndicator?.aroonLowestLowPeriods
                          : selectedIndicator?.aroonLowestLowPeriods
                      }
                      onChange={(e) =>
                        handleChange("aroonLowestLowPeriods", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Typography variant="h6">
                      Periods since Lowest Low
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="aroonPeriodsSinceLowestLow"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.aroonPeriodsSinceLowestLow
                          ? selectedCompanyIndicator?.aroonPeriodsSinceLowestLow
                          : selectedIndicator?.aroonPeriodsSinceLowestLow
                      }
                      onChange={(e) =>
                        handleChange(
                          "aroonPeriodsSinceLowestLow",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Typography variant="h6">Aroon Down</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="aroonDown"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.aroonDown
                          ? selectedCompanyIndicator?.aroonDown
                          : selectedIndicator?.aroonDown
                      }
                      onChange={(e) =>
                        handleChange("aroonDown", e.target.value)
                      }
                    />
                  </Grid>
                </>
              )}
              {selectedIndicator?.name == "ROC & Momentum" && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">3 - 15 periods</Typography>
                      }
                    >
                      <Typography variant="h6">ROC Weekly</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="rocWeekly"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 3, max: 15 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.rocWeekly
                          ? selectedCompanyIndicator?.rocWeekly
                          : selectedIndicator?.rocWeekly
                      }
                      onChange={(e) =>
                        handleChange("rocWeekly", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">4 - 40 periods</Typography>
                      }
                    >
                      <Typography variant="h6">Momentum Weekly</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="momentumWeekly"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 4, max: 40 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.momentumWeekly
                          ? selectedCompanyIndicator?.momentumWeekly
                          : selectedIndicator?.momentumWeekly
                      }
                      onChange={(e) =>
                        handleChange("momentumWeekly", e.target.value)
                      }
                    />
                  </Grid>
                </>
              )}
              {selectedIndicator?.name == "Stochastic Ind." && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={1.6}
                    sx={{
                      alignItems: "center",
                      justifyContent: "start",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Lookback period: 14 (Possible change: 3-45 periods)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">
                        Close - Lowest Low & Highest High
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="stochasticLookbackPeriod"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 3, max: 45 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.stochasticLookbackPeriod
                          ? selectedCompanyIndicator?.stochasticLookbackPeriod
                          : selectedIndicator?.stochasticLookbackPeriod
                      }
                      onChange={(e) =>
                        handleChange("stochasticLookbackPeriod", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Lookback period: 3 (Possible change: 2-45 periods)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">%D (Slow Stochastic)</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="stochasticSlow"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 2, max: 45 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.stochasticSlow
                          ? selectedCompanyIndicator?.stochasticSlow
                          : selectedIndicator?.stochasticSlow
                      }
                      onChange={(e) =>
                        handleChange("stochasticSlow", e.target.value)
                      }
                    />
                  </Grid>
                </>
              )}
              {selectedIndicator?.name == "ATR" && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "start",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Lookback period: 14 (Possible change: 5-45 periods)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">Average True Range</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="atrAvgTrueRange"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 3, max: 45 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.atrAvgTrueRange
                          ? selectedCompanyIndicator?.atrAvgTrueRange
                          : selectedIndicator?.atrAvgTrueRange
                      }
                      onChange={(e) =>
                        handleChange("atrAvgTrueRange", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Default: 5% (Possible change: 1%-20%)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">
                        Daily change {">"} 5%
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="atrDailyChangePercentage"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 1, max: 20 },
                        endAdornment: (
                          <InputAdornment position="end"> %</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.atrDailyChangePercentage
                          ? selectedCompanyIndicator?.atrDailyChangePercentage
                          : selectedIndicator?.atrDailyChangePercentage
                      }
                      onChange={(e) =>
                        handleChange("atrDailyChangePercentage", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2.5}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Default: 10 (Possible change: 2-30 periods)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">
                        Long term performance
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="atrLongTermPerformance"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 2, max: 30 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.atrLongTermPerformance
                          ? selectedCompanyIndicator?.atrLongTermPerformance
                          : selectedIndicator?.atrLongTermPerformance
                      }
                      onChange={(e) =>
                        handleChange("atrLongTermPerformance", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "start",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Default: 5 (Possible change: 2-30 periods)
                        </Typography>
                      }
                    >
                      <Typography variant="h6">
                        Short term performance
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="atrShortTermPerformance"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 2, max: 30 },
                        endAdornment: (
                          <InputAdornment position="end"> days</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.atrShortTermPerformance
                          ? selectedCompanyIndicator?.atrShortTermPerformance
                          : selectedIndicator?.atrShortTermPerformance
                      }
                      onChange={(e) =>
                        handleChange("atrShortTermPerformance", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Typography variant="h6">Signal Percentage Max</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="atrMaxSignalPercentage"
                      variant="outlined"
                      type="number"
                      inputMode="decimal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"> %</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.atrMaxSignalPercentage
                          ? selectedCompanyIndicator?.atrMaxSignalPercentage
                          : selectedIndicator?.atrMaxSignalPercentage?.toFixed(
                              2
                            )
                      }
                      onChange={(e) =>
                        handleChange(
                          "atrMaxSignalPercentage",
                          e.target.value === ""
                            ? null
                            : parseFloat(e.target.value)
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2.5}
                    sx={{
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Typography variant="h6">Signal Percentage Min</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="atrMinSignalPercentage"
                      variant="outlined"
                      type="number"
                      inputMode="decimal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"> %</InputAdornment>
                        ),
                      }}
                      size="small"
                      fullWidth
                      value={
                        selectedCompanyIndicator?.atrMinSignalPercentage
                          ? selectedCompanyIndicator?.atrMinSignalPercentage
                          : selectedIndicator?.atrMinSignalPercentage?.toFixed(
                              2
                            )
                      }
                      onChange={(e) =>
                        handleChange(
                          "atrMinSignalPercentage",
                          e.target.value === ""
                            ? null
                            : parseFloat(e.target.value)
                        )
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {selectedIndicator && (
            <>
              <Grid item xs={12} mt={3} pb={3}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      size="small"
                      options={companies}
                      getOptionLabel={(option) => option?.name || ""}
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          label="Company"
                        />
                      )}
                    />
                    <Box pt={1}>
                      <label>Select Term</label>
                      <Select
                        size="small"
                        fullWidth
                        value={selectedTerm}
                        onChange={(e) => handleTermChange(e)}
                        displayEmpty
                        renderValue={(value) =>
                          value ? value : "No term selected"
                        }
                      >
                        <MenuItem value="">
                          <em>No term selected</em>
                        </MenuItem>
                        <MenuItem value="SIMPLE">Simple</MenuItem>
                        <MenuItem value="SHORT_TERM">Short term</MenuItem>
                        <MenuItem value="MID_TERM">Mid term</MenuItem>
                        <MenuItem value="LONG_TERM">Long term</MenuItem>
                      </Select>
                    </Box>
                    {selectedCompany && (
                      <>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={saveToCompanyIndicator}
                                onChange={handleToggle}
                                color="info"
                              />
                            }
                            label={
                              <Tooltip
                                title={
                                  <Typography variant="subtitle1">
                                    (Note*) Company settings overwrite Global
                                    settings
                                  </Typography>
                                }
                              >
                                <Typography variant="subtitle1">
                                  {selectedTerm == ""
                                    ? "Update Company Settings for all terms"
                                    : "Update Company Settings for selected terms"}
                                </Typography>
                              </Tooltip>
                            }
                          />
                        </Box>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="extra1"
                      sx={{
                        padding: "10px",
                        fontSize: "14px",
                        color: "white",
                      }}
                      onClick={() => {
                        handleUpdate(selectedIndicator);
                      }}
                    >
                      {saveToCompanyIndicator
                        ? "Update Company Settings"
                        : "Update Global Settings"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}
