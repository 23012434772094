import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Icon,
  TextField,
  Autocomplete,
  Box,
  Button,
  useMediaQuery,
  alpha,
  CircularProgress,
  CardContent,
  Card,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Clear, QueryStatsOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  notifyShowErrorMessage,
  triggerRerender,
} from "../../common/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { CompanyRepository } from "../../repositories/CompanyRepository";
import { CompanyStockDataRepository } from "../../repositories/CompanyStockDataRepository";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import Typewriter from "../../common/TypeWriter";
import { AuthRepository } from "../../repositories/AuthRepository";
import RSIChart from "../charts/RSIChart";
import OBVChart from "../charts/OBVChart";
import AroonOscilatorChart from "../charts/AroonOscilatorChart";
import ATRChart from "../charts/ATRChart";
import StochasticIndChart from "../charts/StochasticIndChart";
import ROCMomentumChart from "../charts/ROCMomentumChart";
import BollingerBands from "../charts/BollingerBandsChart";
import { ReactTyped } from "react-typed";
import { useTheme } from "@emotion/react";
import { UsersRepository } from "../../repositories/UsersRepository";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

export default function Consultant() {
  const [searchParams, setSearchParams] = useState({});
  const [paramsFromHistory] = useSearchParams();
  const companyFromHistory = paramsFromHistory.get("company");
  const [selectedCompanyFromHistory, setSelectedCompanyFromHistory] =
    useState();
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [RSIData, setRSIData] = useState([]);
  const [latestData, setLatestData] = useState();
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [brushStartIndex, setBrushStartIndex] = useState(0);
  const [brushEndIndex, setBrushEndIndex] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState("SIMPLE");
  const navigate = useNavigate();
  const [selectedCompanyMessage, setSelectedCompanyMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyISIN, setCompanyISIN] = useState("");
  const [companyTicker, setCompanyTicker] = useState("");
  const [visible, setVisible] = useState(false);
  const [OBVData, setOBVData] = useState([]);
  const [latestDataOBV, setLatestDataOBV] = useState();
  const [fullRSIData, setFullRSIData] = useState([]);
  const [fullOBVData, setFullOBVData] = useState([]);
  const [fullAroonData, setFullAroonData] = useState([]);
  const [fullATRData, setFullATRData] = useState([]);
  const [brushStartIndexOBV, setBrushStartIndexOBV] = useState(0);
  const [brushEndIndexOBV, setBrushEndIndexOBV] = useState(0);
  const [latestDataATR, setLatestDataATR] = useState();
  const [brushStartIndexATR, setBrushStartIndexATR] = useState(0);
  const [brushEndIndexATR, setBrushEndIndexATR] = useState(0);
  const [AroonData, setAroonData] = useState([]);
  const [ATRData, setATRData] = useState([]);
  const [latestDataAroon, setLatestDataAroon] = useState();
  const [rocMomentumData, setRocMomemtumData] = useState([]);
  const [StochasticData, setStochasticData] = useState([]);
  const [BollingerData, setBollingerData] = useState([]);
  const [brushStartIndexAroon, setBrushStartIndexAroon] = useState(0);
  const [brushEndIndexAroon, setBrushEndIndexAroon] = useState(0);
  const [fullStochasticData, setFullStochasticData] = useState([]);
  const [fullRocMomentumData, setFullRocMomentumData] = useState([]);
  const [fullBollingerData, setFullBollingerData] = useState([]);
  const [latestDataStochastic, setLatestDataStochastic] = useState();
  const [brushStartIndexStochastic, setBrushStartIndexStochastic] = useState(0);
  const [brushEndIndexStochastic, setBrushEndIndexStochastic] = useState(0);
  const [latestDataRocMomentum, setLatestDataRocMomentum] = useState();
  const [brushStartIndexRocMomentum, setBrushStartIndexRocMomentum] =
    useState(0);
  const [brushEndIndexRocMomentum, setBrushEndIndexRocMomentum] = useState(0);
  const [latestDataBollinger, setLatestDataBollinger] = useState();
  const [brushStartIndexBollinger, setBrushStartIndexBollinger] = useState(0);
  const [brushEndIndexBollinger, setBrushEndIndexBollinger] = useState(0);
  const [globalSignal, setGlobalSignal] = useState("");
  const theme = useTheme();
  const [mostFavoritedCompanies, setMostFavoritedCompanies] = useState([]);
  const globalState = useSelector((state) => state);

  const signalMap = {
    STRONG_SELL: -1.5,
    STRONG_BUY: 1.5,
    WEAK_BUY: 0.5,
    WEAK_SELL: -0.5,
    BUY: 3,
    SELL: -3,
  };

  const ATRSignalMap = {
    BUY: 1,
    SELL: -1,
    NEUTRAL: 0,
  };

  const StochasticSignalMap = {
    BUY: 1.5,
    SELL: -1.5,
    NEUTRAL: 0,
  };

  const RocMomentumAndBollingerBandsSignalMap = {
    BUY: 2,
    SELL: -2,
    NEUTRAL: 0,
  };

  useEffect(() => {
    loadMostFavoritedCompanies();
  }, [globalState.triggerRerender]);

  useEffect(() => {
    if (selectedCompanyId) {
      getCompanyById(selectedCompanyId);
    }
  }, [searchParams, page, size]);

  useEffect(() => {
    let tmp = { ...searchParams };
    tmp["company.id"] = selectedCompanyId;
    setSearchParams(tmp);
  }, [selectedCompanyId]);

  useEffect(() => {
    CompanyRepository.getAllWithoutPaging()
      .then((res) => setCompanies(res.data))
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
  }, []);

  useEffect(() => {
    if (companyFromHistory && companies.length > 0) {
      const company = companies.find((c) => c.name === companyFromHistory);
      if (company) {
        setSelectedCompanyFromHistory(company);
      }
    }
  }, [companyFromHistory, companies]);

  useEffect(() => {
    if (selectedCompanyFromHistory) {
      handleCompanyChange(selectedCompanyFromHistory);
    }
  }, [selectedCompanyFromHistory]);

  const handleCompanyChange = (newValue) => {
    if (newValue) {
      setSelectedCompany(newValue);
      setSelectedCompanyId(newValue.id);
      setPage(0);
      setSelectedCompanyMessage(
        `Great! You want me to analyze "${newValue.name}", right?`
      );
      setCompanyName(newValue.name);
      setCompanyISIN(newValue.isin);
      setCompanyTicker(newValue.bloombergTicker);
    } else {
      setSelectedCompanyId("");
      setCompanyName("");
      setSelectedCompanyMessage("");
    }
  };

  const getCompanyById = () => {
    CompanyRepository.getCompanyById(selectedCompanyId)
      .then((res) => {
        setSelectedCompany(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadData = () => {
    setLoading(true);
    console.log("selected comapny", selectedCompanyId);
    CompanyStockDataRepository.calculateWithRSIIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            rsi: item?.rsPercentage?.toFixed(2),
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
          };
        });
        setFullRSIData(finalData);
        setBrushEndIndex(finalData.length - 1);
        setBrushStartIndex(finalData.length - 30);
        setLatestData(finalData[finalData.length - 1]);
        setRSIData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithOBVIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber: signalMap[item?.signalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            volume: item?.volume,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
          };
        });
        setFullOBVData(finalData);
        setBrushEndIndexOBV(finalData.length - 1);
        setBrushStartIndexOBV(finalData.length - 30);
        setLatestDataOBV(finalData[finalData.length - 1]);
        setOBVData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
    CompanyStockDataRepository.calculateWithAroonIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber: signalMap[item?.signalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
            aroonUp: item?.aroonUp,
            aroonDown: item?.aroonDown,
            differenceAroonUpDown: item?.differenceAroonUpDown,
          };
        });
        setFullAroonData(finalData);
        setBrushEndIndexAroon(finalData.length - 1);
        setBrushStartIndexAroon(finalData.length - 30);
        setLatestDataAroon(finalData[finalData.length - 1]);
        setAroonData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithATRIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber: ATRSignalMap[item?.signalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
            avgTrueRange: item?.averageTrueRangeDifferences,
          };
        });
        setFullATRData(finalData);
        setBrushEndIndexATR(finalData.length - 1);
        setBrushStartIndexATR(finalData.length - 30);
        setLatestDataATR(finalData[finalData.length - 1]);
        setATRData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithStochasticIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber: StochasticSignalMap[item?.signalType],
            signalPercentage: item?.signalPercentage,
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
          };
        });
        setFullStochasticData(finalData);
        setBrushEndIndexStochastic(finalData.length - 1);
        setBrushStartIndexStochastic(finalData.length - 30);
        setLatestDataStochastic(finalData[finalData.length - 1]);
        setStochasticData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithROCMomentumIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber:
              RocMomentumAndBollingerBandsSignalMap[item?.signalType],
            rocSignal: item?.rocSignalType,
            rocSignalNumber:
              RocMomentumAndBollingerBandsSignalMap[item?.rocSignalType],
            momentumSignal: item?.momentumSignalType,
            momentumSignalNumber:
              RocMomentumAndBollingerBandsSignalMap[item?.momentumSignalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
          };
        });
        setFullRocMomentumData(finalData);
        setBrushEndIndexRocMomentum(finalData.length - 1);
        setBrushStartIndexRocMomentum(finalData.length - 30);
        setLatestDataRocMomentum(finalData[finalData.length - 1]);
        setRocMomemtumData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    CompanyStockDataRepository.calculateWithBollingerIndicatorWithoutPaging(
      selectedCompanyId,
      selectedTerm
    )
      .then((res) => {
        let finalData = res.data.map((item, index) => {
          return {
            signal: item?.signalType,
            signalNumber:
              RocMomentumAndBollingerBandsSignalMap[item?.signalType],
            index: index,
            date: moment(item?.date).format("DD/MM/YYYY"),
            name: selectedCompany ? selectedCompany?.name : "",
            price: item?.price,
            indicator: selectedCompany ? selectedCompany?.primaryIndex : "",
            upperBand: item?.upperBand,
            lowerBand: item?.lowerBand,
            sma: item?.sma,
          };
        });
        setFullBollingerData(finalData);
        setBrushEndIndexBollinger(finalData.length - 1);
        setBrushStartIndexBollinger(finalData.length - 30);
        setLatestDataBollinger(finalData[finalData.length - 1]);
        setBollingerData(finalData);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });

    setLoading(false);
  };

  const loadMostFavoritedCompanies = () => {
    UsersRepository.getUserCompanyAccessFavorites()
      .then((res) => {
        setMostFavoritedCompanies(res.data);
      })
      .catch((err) => {
        dispatch(
          notifyShowErrorMessage("Error fetching most analysed companies!")
        );
      });
  };

  const getSignal = (signal) => {
    if (signal === "STRONG_SELL" || signal === "WEAK_SELL" || signal === "SELL")
      return "SELL";
    if (signal === "STRONG_BUY" || signal === "WEAK_BUY" || signal === "BUY")
      return "BUY";
    if (signal === "NEUTRAL") return "NEUTRAL";
    return "NEUTRAL";
  };

  useEffect(() => {
    const signals = [
      getSignal(latestData?.signal),
      getSignal(latestDataOBV?.signal),
      getSignal(latestDataAroon?.signal),
      getSignal(latestDataATR?.signal),
      getSignal(latestDataStochastic?.signal),
      getSignal(latestDataRocMomentum?.signal),
      getSignal(latestDataBollinger?.signal),
    ];

    const buyCount = signals.filter((signal) => signal === "BUY").length;
    const sellCount = signals.filter((signal) => signal === "SELL").length;
    const neutralCount = signals.filter(
      (signal) => signal === "NEUTRAL"
    ).length;

    let updatedGlobalSignal = "";
    if (buyCount > sellCount && buyCount > neutralCount) {
      updatedGlobalSignal = "BUY";
    } else if (sellCount > buyCount && sellCount > neutralCount) {
      updatedGlobalSignal = "SELL";
    } else {
      updatedGlobalSignal = "HOLD";
    }
    setGlobalSignal(updatedGlobalSignal);
  }, [
    latestData?.signal,
    latestDataOBV?.signal,
    latestDataAroon?.signal,
    latestDataATR?.signal,
    latestDataStochastic?.signal,
    latestDataRocMomentum?.signal,
    latestDataBollinger?.signal,
  ]);

  const terms = [
    { value: "SIMPLE", label: "Just Show Me The Latest Trading Signal" },
    { value: "SHORT_TERM", label: "Short-Term" },
    { value: "MID_TERM", label: "Medium Term" },
    { value: "LONG_TERM", label: "Long-Term" },
  ];

  const handleTermClick = (termValue) => {
    setSelectedTerm(termValue);
  };
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isClient = AuthRepository.hasRole("ROLE_CLIENT");
  if (!isClient) {
    return null;
  }
  const handleBackClick = () => {
    setVisible(false);
    setSelectedTerm("SIMPLE");
    setCompanyName("");
    setSelectedCompany(null);
    dispatch(triggerRerender());
  };

  const handleAnalyse = () => {
    if (selectedCompany && selectedTerm) {
      loadData();
      setVisible(true);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {!visible && (
          <Grid container justifyContent="center">
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                marginBottom: "15px",
                paddingTop: "60px",
              }}
            >
              <img
                alt=""
                src={
                  theme.palette.mode == "dark"
                    ? require("../../assets/img/capitelize_logo_inverted.png")
                    : require("../../assets/img/capitelize_logo.png")
                }
                width="90px"
                onClick={() => navigate("/")}
                style={{
                  cursor: "pointer",
                  marginLeft: "-10px",
                  borderRadius: "20px",
                }}
              ></img>{" "}
            </Grid>

            <Grid item md={2} xs={0.5}></Grid>
            <Grid item md={8} xs={11} style={{ textAlign: "center" }}>
              <div>
                <h1>
                  <Typewriter
                    text={
                      companyFromHistory != null
                        ? "Hi! Would you like to analyse the same company again?"
                        : "Hi, I’m your financial consultant. What investment would you like to have analysed?"
                    }
                    delay={40}
                  />
                </h1>
              </div>
            </Grid>
            <Grid item md={2} xs={0.5}></Grid>
          </Grid>
        )}

        {!visible && (
          <Grid container justifyContent="center" paddingTop={4} spacing={0}>
            <Grid item md={1}></Grid>
            <Grid item xs={7} md={7} style={{ justifyContent: "center" }}>
              <Autocomplete
                options={companies}
                getOptionLabel={(option) => option.name}
                value={selectedCompany != undefined ? selectedCompany : null}
                onChange={(event, newValue) => {
                  handleCompanyChange(newValue);
                }}
                clearIcon={
                  <IconButton
                    onClick={() => {
                      setSelectedCompany(null);
                    }}
                  >
                    <Clear />
                  </IconButton>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="🔍 Search for a company..."
                    fullWidth
                    defaultValue={selectedCompany?.name || ""}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: "1.2rem",
                        borderRadius: "30px 0 0 30px",
                        height: "60px",
                        paddingLeft: "25px",
                      },
                    }}
                    InputLabelProps={{
                      style: { fontSize: "1.2rem" },
                    }}
                  />
                )}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.mutedBlue.main,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.mutedBlue.main,
                  },
                }}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <Button
                variant="contained"
                component="span"
                color="mutedBlue"
                disabled={!selectedCompanyId || !selectedTerm || !companyName}
                sx={{
                  height: "60px",
                  width: isSmallScreen ? "100px" : "200px",
                  borderRadius: "0px 30px 30px 0px",
                  backgroundColor: theme.palette.mutedBlue.main,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.mutedBlue.main, 0.85),
                    borderColor: alpha(theme.palette.mutedBlue.main, 0.85),
                  },
                }}
                onClick={handleAnalyse}
              >
                <Typography
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isSmallScreen ? "18px" : "20px",
                    textTransform: "none",
                  }}
                >
                  {!isSmallScreen && (
                    <QueryStatsOutlined
                      style={{ fontSize: "25px", paddingRight: "2px" }}
                    />
                  )}
                  <span style={{ marginLeft: !isSmallScreen && "3px" }}>
                    Analyse
                  </span>
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        paddingTop={5}
        style={{ justifyContent: isSmallScreen ? "left" : "center" }}
      >
        {!visible && (
          <Grid
            item
            xs={12}
            md={2}
            style={{
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <Typography
              style={{
                fontSize: "18px",
                color: alpha(theme.palette.lightGray.main, 0.8),
              }}
            >
              Analyse By:
            </Typography>
          </Grid>
        )}

        {!visible && (
          <Grid item xs={12} md={6}>
            <Grid item container spacing={1}>
              {terms.map((term) => (
                <Grid
                  item
                  {...(isSmallScreen ? { xs: 12 } : {})}
                  key={term.value}
                  style={{
                    marginTop: "5px",

                    textAlign: isSmallScreen && "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleTermClick(term.value)}
                    sx={{
                      margin: isSmallScreen ? "1px" : "5px",
                      borderRadius: "15px",
                      borderColor: "#10377c",
                      color:
                        selectedTerm === term.value
                          ? "white"
                          : theme.palette.mutedBlue.main,
                      backgroundColor:
                        selectedTerm === term.value
                          ? theme.palette.mutedBlue.main
                          : "transparent",
                      "&:hover": {
                        backgroundColor: alpha(
                          theme.palette.mutedBlue.main,
                          0.7
                        ),
                        color: "white",
                      },
                    }}
                  >
                    {term.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {!visible && (
          <Grid item xs={12} md={10}>
            <Box
              sx={{
                textAlign: "center",
                marginBottom: 2,
                marginTop: "30px",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: theme.palette.mutedBlue.main,
                  fontFamily: "EB Garamond",
                }}
              >
                Favorite Companies
              </Typography>
              <Divider
                variant="middle"
                sx={{ width: "60%", margin: "0 auto", mt: 2, mb: 2 }}
              />
            </Box>
            {mostFavoritedCompanies && mostFavoritedCompanies.length !== 0 && (
              <Swiper
                spaceBetween={220}
                slidesPerView={6}
                autoplay={{ delay: 2000, reverseDirection: true }}
                modules={[Autoplay]}
                centeredSlides={true}
                loop={true}
                style={{
                  overflow: "hidden",
                  height: 110,
                }}
              >
                {mostFavoritedCompanies.map((company, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      display: "inline-block",
                      height: 120,
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "8px",
                        boxShadow: 2,
                        transition: "transform 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: 3,
                        },
                        backgroundColor: "transparent",
                        minHeight: 100,
                        width: "220px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <CardContent sx={{ padding: 0 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            color: theme.palette.darkContrast.main,
                            textAlign: "center",
                            fontWeight: "bold",
                            marginBottom: 1,
                            marginTop: 3,
                          }}
                        >
                          {company?.companyName}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: theme.palette.text.secondary,
                            textAlign: "center",
                            fontStyle: "italic",
                          }}
                        >
                          ISIN: {company?.companyISIN || "N/A"}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: theme.palette.text.secondary,
                            textAlign: "center",
                            fontStyle: "italic",
                          }}
                        >
                          Ticker: {company?.companyBloombergTicker || "N/A"}
                        </Typography>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}{" "}
          </Grid>
        )}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: "center" }}
          >
            <Grid item>
              <Typography
                variant="h2"
                style={{
                  color: alpha(theme.palette.mutedBlue.main, 1),
                  fontFamily: "EB Garamond",
                  marginBottom: "15px",
                }}
              >
                Wait for me to analyse
                <ReactTyped
                  strings={["...", " "]}
                  typeSpeed={100}
                  loop
                  backSpeed={20}
                  cursorChar=""
                  showCursor={true}
                />
              </Typography>
              <CircularProgress style={{ color: "#10377c" }} />
            </Grid>
          </Grid>
        ) : (
          <>
            {visible && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ marginLeft: "16px" }}>
                    <IconButton onClick={handleBackClick}>
                      <Icon style={{ fontSize: "22px" }}>arrow_back</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: isSmallScreen ? 1 : 5,
                        borderRadius: "20px",
                        marginLeft: "16px",
                        marginTop: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      <Grid container spacing={2}>
                        {!isSmallScreen && (
                          <Grid item xs={12} md={6}>
                            <Grid item md={12}>
                              <Typography
                                variant="h3"
                                style={{
                                  marginBottom: "30px",
                                  color: theme.palette.mutedBlue.main,
                                }}
                              >
                                {companyName}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              md={7}
                              xs={12}
                              style={{ textAlign: isSmallScreen && "center" }}
                            >
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h6">
                                        ISIN:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "light" }}
                                      >
                                        {companyISIN}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h6">
                                        Bloomberg Ticker:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "light" }}
                                      >
                                        {companyTicker}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          md={5}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              padding: "20px 20px 20px",
                              border: "2px solid",
                              borderColor: globalSignal?.includes("BUY")
                                ? alpha("#81c784", 0.5)
                                : globalSignal?.includes("SELL")
                                ? alpha("#ef5350", 0.5)
                                : alpha("#ffd54f", 0.5),
                              borderRadius: "10px",
                              alignItems: "center",
                              width: isSmallScreen ? "auto" : "550px",
                              height: "300px",
                              textAlign: "center",
                              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                              position: "relative",
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant="h4" mb={2}>
                                  {selectedCompany?.name}
                                </Typography>
                              </Grid>

                              {globalSignal?.includes("BUY") && (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>
                                    The trader says {selectedCompany?.name} is a
                                    clear{" "}
                                    <span
                                      style={{
                                        color: "#81c784",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      BUY
                                    </span>
                                    . This means you should buy this security if
                                    you don’t already own it. Traders would not
                                    buy or increase their position.
                                  </Typography>
                                </Grid>
                              )}
                              {globalSignal?.includes("SELL") && (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>
                                    The trader says {selectedCompany?.name} is a
                                    clear{" "}
                                    <span
                                      style={{
                                        color: "#ef5350",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      SELL
                                    </span>
                                    . This means you should sell this security
                                    if you already own it. Traders would not buy
                                    this security at this point in time.
                                  </Typography>
                                </Grid>
                              )}
                              {globalSignal?.includes("HOLD") && (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>
                                    The trader says {selectedCompany?.name} is a
                                    clear{" "}
                                    <span
                                      style={{
                                        color: "#ffd54f",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      HOLD
                                    </span>
                                    . This means you should keep this security
                                    if you already own it. Traders would not buy
                                    this security at this point in time.
                                  </Typography>
                                </Grid>
                              )}
                              <Grid item xs={6}>
                                <Typography
                                  variant="h5"
                                  style={{
                                    color: theme.palette.mutedBlue.main,
                                  }}
                                >
                                  Traders Rating:
                                </Typography>
                                <Typography
                                  variant="h4"
                                  color={
                                    globalSignal?.includes("BUY")
                                      ? "#81c784"
                                      : globalSignal?.includes("SELL")
                                      ? "#ef5350"
                                      : "#ffd54f"
                                  }
                                  mb={2}
                                >
                                  {globalSignal}
                                </Typography>
                                <Icon
                                  sx={{
                                    fontSize: "80px",
                                    transition: "transform 0.2s ease",
                                  }}
                                  color={
                                    globalSignal?.includes("BUY")
                                      ? "success"
                                      : globalSignal?.includes("SELL")
                                      ? "error"
                                      : "warning"
                                  }
                                >
                                  {globalSignal?.includes("BUY")
                                    ? "thumb_up"
                                    : globalSignal?.includes("SELL")
                                    ? "thumb_down"
                                    : "sentiment_neutral"}
                                </Icon>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: isSmallScreen ? 1 : 5,
                        borderRadius: "20px",
                        marginLeft: "16px",
                      }}
                    >
                      <RSIChart
                        RSIData={RSIData}
                        latestData={latestData}
                        setLatestData={setLatestData}
                        brushStartIndex={brushStartIndex}
                        brushEndIndex={brushEndIndex}
                        setBrushStartIndex={setBrushStartIndex}
                        setBrushEndIndex={setBrushEndIndex}
                        left={left}
                        right={right}
                        height={250}
                      />
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: isSmallScreen ? 1 : 5,
                        borderRadius: "20px",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      <OBVChart
                        OBVData={OBVData}
                        latestDataOBV={latestDataOBV}
                        setLatestDataOBV={setLatestDataOBV}
                        brushStartIndexOBV={brushStartIndexOBV}
                        brushEndIndexOBV={brushEndIndexOBV}
                        setBrushStartIndexOBV={setBrushStartIndexOBV}
                        setBrushEndIndexOBV={setBrushEndIndexOBV}
                        left={left}
                        right={right}
                        height={250}
                      />
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: isSmallScreen ? 1 : 5,
                        borderRadius: "20px",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      <AroonOscilatorChart
                        AroonData={AroonData}
                        latestDataAroon={latestDataAroon}
                        setLatestDataAroon={setLatestDataAroon}
                        brushStartIndexAroon={brushStartIndexAroon}
                        brushEndIndexAroon={brushEndIndexAroon}
                        setBrushStartIndexAroon={setBrushStartIndexAroon}
                        setBrushEndIndexAroon={setBrushEndIndexAroon}
                        left={left}
                        right={right}
                        height={250}
                      />
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: isSmallScreen ? 1 : 5,
                        borderRadius: "20px",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      <ATRChart
                        ATRData={ATRData}
                        latestDataATR={latestDataATR}
                        setLatestDataATR={setLatestDataATR}
                        brushStartIndexATR={brushStartIndexATR}
                        brushEndIndexATR={brushEndIndexATR}
                        setBrushStartIndexATR={setBrushStartIndexATR}
                        setBrushEndIndexATR={setBrushEndIndexATR}
                        left={left}
                        right={right}
                        height={250}
                      />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: isSmallScreen ? 1 : 5,
                        borderRadius: "20px",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      <StochasticIndChart
                        StochasticData={StochasticData}
                        latestDataStochastic={latestDataStochastic}
                        setLatestDataStochastic={setLatestDataStochastic}
                        brushStartIndexStochastic={brushStartIndexStochastic}
                        brushEndIndexStochastic={brushEndIndexStochastic}
                        setBrushStartIndexStochastic={
                          setBrushStartIndexStochastic
                        }
                        setBrushEndIndexStochastic={setBrushEndIndexStochastic}
                        left={left}
                        right={right}
                        height={250}
                      />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: isSmallScreen ? 1 : 5,
                        borderRadius: "20px",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      <ROCMomentumChart
                        rocMomentumData={rocMomentumData}
                        latestDataRocMomentum={latestDataRocMomentum}
                        setLatestDataRocMomentum={setLatestDataRocMomentum}
                        brushStartIndexRocMomentum={brushStartIndexRocMomentum}
                        brushEndIndexRocMomentum={brushEndIndexRocMomentum}
                        setBrushStartIndexRocMomentum={
                          setBrushStartIndexRocMomentum
                        }
                        setBrushEndIndexRocMomentum={
                          setBrushEndIndexRocMomentum
                        }
                        left={left}
                        right={right}
                        height={250}
                      />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: isSmallScreen ? 1 : 5,
                        borderRadius: "20px",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      <BollingerBands
                        BollingerData={BollingerData}
                        latestDataBollinger={latestDataBollinger}
                        setLatestDataBollinger={setLatestDataBollinger}
                        brushStartIndexBollinger={brushStartIndexBollinger}
                        brushEndIndexBollinger={brushEndIndexBollinger}
                        setBrushStartIndexBollinger={
                          setBrushStartIndexBollinger
                        }
                        setBrushEndIndexBollinger={setBrushEndIndexBollinger}
                        left={left}
                        right={right}
                        height={250}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
