import { useTheme } from "@emotion/react";
import {
  AppBar,
  FormControlLabel,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Navigate, useLocation } from "react-router";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
        ...theme.applyStyles("dark", {
          backgroundColor: "#8796A5",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#003892",
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
    ...theme.applyStyles("dark", {
      backgroundColor: "#8796A5",
    }),
  },
}));
export default function Header({ setOpen, open, themeMode, setThemeMode }) {
  const [redirectTo, setRedirectTo] = React.useState();
  const location = useLocation();

  const theme = useTheme();

  React.useEffect(() => {
    setRedirectTo(undefined);
  }, [location]);

  return (
    <React.Fragment>
      {redirectTo && <Navigate to={redirectTo} push />}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          display: { xs: "none", md: "block" },
          width: `calc(100% - ${open ? "230px" : "74px"})`,
          transition: (theme) =>
            theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Toolbar
          style={{
            minHeight: "50px",
          }}
        >
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{
              marginLeft: "-10px",
              marginRight: "36px",
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton> */}

          {/* Header title */}
          <Typography sx={{ flexGrow: 1 }}></Typography>

          {/* Username */}
          {/* <Tooltip title="Profile">
            <IconButton onClick={handleClickProfile} style={{ color: "white" }}>
              <Icon>person</Icon>
            </IconButton>
          </Tooltip>
          <Typography
            style={{ marginRight: "20px" }}
            onClick={handleClickProfile}
          >
            {currentUser?.firstName?.toUpperCase() +
              " " +
              currentUser?.lastName?.toUpperCase() +
              " (" +
              currentUser?.organization?.nameEn?.toUpperCase() +
              ")"}
          </Typography> */}
          {
            <>
              <FormControlLabel
                control={<MaterialUISwitch sx={{ m: 1 }} size="small" />}
                checked={themeMode === "dark" ? true : false}
                onChange={(e) => {
                  setThemeMode(e.target.checked ? "dark" : "light");
                }}
              />
              {/* <IconButton
                onClick={() => {
                  //decrease typography font size by 0.2
                  setTypographyFontSize(typographyFontSize - 0.5);
                  setH6FontSize(h6FontSize - 0.5);
                  setH5FontSize(h5FontSize - 0.5);
                  setH4FontSize(h4FontSize - 0.5);
                }}
              >
                <Icon>zoom_out</Icon>
              </IconButton>
              <IconButton
                onClick={() => {
                  //increase typography font size by 0.2
                  setTypographyFontSize(typographyFontSize + 0.5);
                  setH6FontSize(h6FontSize + 0.5);
                  setH5FontSize(h5FontSize + 0.5);
                  setH4FontSize(h4FontSize + 0.5);
                }}
              >
                <Icon>zoom_in</Icon>
              </IconButton> */}
            </>
          }

          {/* <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openProfile}
            onClose={handleCloseProfile}
            onClick={handleCloseProfile}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            disableScrollLock={true}
          >
            <MenuItem onClick={() => navigate("/dashboard/resetPassword")}>
              <ListItemIcon>
                <Icon fontSize="small">lock_reset</Icon>
              </ListItemIcon>
              Reset password
            </MenuItem>
            <MenuItem onClick={() => AuthRepository.logout()}>
              <ListItemIcon>
                <Icon fontSize="small">logout</Icon>
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu> */}

          {/* Logout */}
          {/* <Tooltip title="Logout">
            <IconButton
              onClick={() => AuthRepository.logout()}
              style={{ marginLeft: "15px", color: "white" }}
            >
              <Icon>logout</Icon>
            </IconButton>
          </Tooltip> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
