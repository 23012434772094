import {
  Button,
  Divider,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  TextField,
  IconButton,
  FormControlLabel,
  Box,
  Switch,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/CommonActions";
import { useDispatch } from "react-redux";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { CompanyRepository } from "../../repositories/CompanyRepository";
import { CompanyStockDataRepository } from "../../repositories/CompanyStockDataRepository";
import moment from "moment";
import { Close } from "@mui/icons-material";
import { AuthRepository } from "../../repositories/AuthRepository";
import { LOCALE } from "../../properties/Locale";
import { TableRowEmptyData } from "../../common/TableRowEmptyData";
import { IndicatorRepository } from "../../repositories/IndicatorRepository";
import { CompanyIndicatorRepository } from "../../repositories/CompanyIndicatorRepository";
import { useTheme } from "@emotion/react";

export default function CompanyStockData() {
  const [stockData, setStockData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedIndicator, setSelectedIndicator] = useState();
  const [selectedCompanyIndicator, setSelectedCompanyIndicator] = useState();
  const [selectedIndicatorName, setSelectedIndicatorName] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [showCalculations, setShowCalculations] = useState(false);
  const [indicators, setIndicators] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    IndicatorRepository.getAll()
      .then((res) => {
        setIndicators(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedTerm && selectedIndicator) {
      CompanyIndicatorRepository.getByCompanyAndTerm(
        selectedCompany?.id,
        selectedTerm,
        selectedIndicatorName
      )
        .then((res) => {
          setSelectedCompanyIndicator(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedIndicator, selectedTerm]);

  useEffect(() => {
    if (selectedCompany && selectedIndicator && selectedTerm) {
      loadData();
    }
  }, [page, size, selectedCompany, selectedIndicator, selectedTerm]);

  useEffect(() => {
    CompanyRepository.getAllWithoutPaging()
      .then((res) => setCompanies(res.data))
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
  }, []);

  const loadData = () => {
    setLoading(true);
    CompanyStockDataRepository.calculateWithIndicator(
      page,
      size,
      selectedCompany?.id,
      selectedIndicatorName,
      selectedTerm
    )
      .then((res) => {
        setStockData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
    setPage(0);
  };

  const handleIndicatorChange = (e) => {
    setSelectedIndicator(e.target.value);
    setSelectedIndicatorName(e.target.value.name);
    setPage(0);
  };

  const handleTermChange = (event) => {
    setSelectedTerm(event.target.value);
    setPage(0);
  };

  const handleUploadFile = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleSubmitFile = () => {
    CompanyStockDataRepository.uploadData(file)
      .then((res) => {
        dispatch(notifyShowSuccessMessage("File uploaded successfully!"));
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
      });
  };

  const handleToggle = (event) => {
    const newValue = event.target.checked;
    setShowCalculations(newValue);
  };

  return (
    <Paper sx={{ padding: 5, borderRadius: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} pt={3}>
          <Typography
            variant="h3"
            sx={{ textAlign: { xs: "center", md: "start" } }}
          >
            Company Stock Data
          </Typography>
        </Grid>

        <Grid item xs={12} md={2.5}>
          <label>Select Company</label>
          <Autocomplete
            fullWidth
            options={companies}
            getOptionLabel={(option) => option?.name || ""}
            value={selectedCompany}
            onChange={handleCompanyChange}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderOption={(props, option) => (
              <li {...props} key={option.isin}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" fullWidth />
            )}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mutedBlue.main,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.mutedBlue.main,
              },
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.mutedBlue.main,
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: theme.palette.mutedBlue.main,
            },
          }}
        >
          <label>Select Indicator</label>
          <Select
            fullWidth
            value={selectedIndicator}
            onChange={(e) => handleIndicatorChange(e)}
            displayEmpty
            renderValue={(value) =>
              value
                ? indicators.find((c) => c.id === value.id)?.name
                : "No indicator selected"
            }
          >
            <MenuItem value="">
              <em>No indicator selected</em>
            </MenuItem>
            {indicators.map((indicator) => (
              <MenuItem key={indicator.id} value={indicator}>
                {indicator.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.mutedBlue.main,
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: theme.palette.mutedBlue.main,
            },
          }}
        >
          <label>Select Term</label>
          <Select
            fullWidth
            value={selectedTerm}
            onChange={(e) => handleTermChange(e)}
            displayEmpty
            renderValue={(value) => (value ? value : "No term selected")}
          >
            <MenuItem value="">
              <em>No term selected</em>
            </MenuItem>
            <MenuItem value="SIMPLE">Simple</MenuItem>
            <MenuItem value="SHORT_TERM">Short term</MenuItem>
            <MenuItem value="MID_TERM">Mid term</MenuItem>
            <MenuItem value="LONG_TERM">Long term</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} md={1.5} sx={{ textAlign: "center", padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Update Stock Data
          </Typography>
          <TextField
            type="file"
            inputProps={{ accept: ".xls, .xlsx" }}
            onChange={(e) => handleUploadFile(e, setFile)}
            sx={{
              display: "none",
            }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              color="mutedBlue"
              sx={{
                mt: 1,
                color: "white",
              }}
            >
              Choose File
            </Button>
          </label>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {file ? <>{file.name}</> : "Accepts: .xls, .xlsx"}
          </Typography>
        </Grid>
        {file != null && (
          <>
            <Grid
              item
              xs={11}
              md={1}
              sx={{
                textAlign: "center",
                padding: 2,
                alignContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="mutedBlue"
                fullWidth
                onClick={handleSubmitFile}
                sx={{ color: "white" }}
              >
                Submit
              </Button>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                textAlign: "left",
                padding: 2,
                alignContent: "center",
                marginLeft: "-20px",
              }}
            >
              <Tooltip title={<Typography variant="h6">Clear</Typography>}>
                <IconButton>
                  <Close
                    onClick={() => {
                      setFile(null);
                      document.getElementById("file-upload").value = "";
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={showCalculations}
                  onChange={handleToggle}
                  color="primary"
                />
              }
              label="Show Calculations"
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              "@media (min-width: 600px)": {
                overflowX: "visible",
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {showCalculations && selectedIndicatorName == "RSI" ? (
                    <>
                      <TableCell>
                        <Typography variant="h6">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Volume</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Low</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Gain</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Loss</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Average Gain (
                          {selectedCompanyIndicator?.rsiAverageGain
                            ? selectedCompanyIndicator?.rsiAverageGain
                            : selectedIndicator?.rsiAverageGain}{" "}
                          - day)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Average Loss (
                          {selectedCompanyIndicator?.rsiAverageLoss
                            ? selectedCompanyIndicator?.rsiAverageLoss
                            : selectedIndicator?.rsiAverageLoss}{" "}
                          - day)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">RS</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">RSI</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Strong Sell Level {"(>70)"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Strong Buy Level {"(<30)"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Weak Sell Level (
                          {selectedCompanyIndicator?.rsiSellLevel
                            ? selectedCompanyIndicator?.rsiSellLevel
                            : selectedIndicator?.rsiSellLevel}
                          -70)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Weak Buy Level (30-
                          {selectedCompanyIndicator?.rsiBuyLevel
                            ? selectedCompanyIndicator?.rsiBuyLevel
                            : selectedIndicator?.rsiBuyLevel}
                          )
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal</Typography>
                      </TableCell>
                    </>
                  ) : showCalculations &&
                    selectedIndicatorName == "Bollinger Bands" ? (
                    <>
                      <TableCell>
                        <Typography variant="h6">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Volume</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Low</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          SMA (
                          {selectedCompanyIndicator?.bollingerSMA
                            ? selectedCompanyIndicator?.bollingerSMA
                            : selectedIndicator?.bollingerSMA}{" "}
                          - day)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Standard Deviation</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Upper Bollinger Band
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Lower Bollinger Band
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal</Typography>
                      </TableCell>
                    </>
                  ) : showCalculations &&
                    selectedIndicatorName == "Aroon Oscilator" ? (
                    <>
                      <TableCell>
                        <Typography variant="h6">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Volume</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Low</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Highest High in{" "}
                          {selectedCompanyIndicator?.aroonHighestHighPeriods
                            ? selectedCompanyIndicator?.aroonHighestHighPeriods
                            : selectedIndicator?.aroonHighestHighPeriods}{" "}
                          days
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Periods since Highest High (
                          {selectedCompanyIndicator?.aroonPeriodsSinceHighestHigh
                            ? selectedCompanyIndicator?.aroonPeriodsSinceHighestHigh
                            : selectedIndicator?.aroonPeriodsSinceHighestHigh}
                          )
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Aroon Up (
                          {selectedCompanyIndicator?.aroonUp
                            ? selectedCompanyIndicator?.aroonUp
                            : selectedIndicator?.aroonUp}
                          )
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Lowest Low in the last{" "}
                          {selectedCompanyIndicator?.aroonLowestLowPeriods
                            ? selectedCompanyIndicator?.aroonLowestLowPeriods
                            : selectedIndicator?.aroonLowestLowPeriods}{" "}
                          days
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Periods since Lowest Low (
                          {selectedCompanyIndicator?.aroonPeriodsSinceLowestLow
                            ? selectedCompanyIndicator?.aroonPeriodsSinceLowestLow
                            : selectedIndicator?.aroonPeriodsSinceLowestLow}
                          )
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Aroon Down (
                          {selectedCompanyIndicator?.aroonDown
                            ? selectedCompanyIndicator?.aroonDown
                            : selectedIndicator?.aroonDown}
                          )
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Aroon Up - Aroon Down
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal</Typography>
                      </TableCell>
                    </>
                  ) : showCalculations && selectedIndicatorName == "OBV" ? (
                    <>
                      <TableCell>
                        <Typography variant="h6">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Volume</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Low</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.obvSmallVolume
                            ? selectedCompanyIndicator?.obvSmallVolume
                            : selectedIndicator?.obvSmallVolume}
                          -day Volume (Avg)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.obvBigVolume
                            ? selectedCompanyIndicator?.obvBigVolume
                            : selectedIndicator?.obvBigVolume}
                          -day Volume (Avg)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.obvSmallVolume
                            ? selectedCompanyIndicator?.obvSmallVolume
                            : selectedIndicator?.obvSmallVolume}
                          -day Signal
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.obvSmallVolume
                            ? selectedCompanyIndicator?.obvSmallVolume
                            : selectedIndicator?.obvSmallVolume}
                          -day Signal Numeric
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.obvBigVolume
                            ? selectedCompanyIndicator?.obvBigVolume
                            : selectedIndicator?.obvBigVolume}
                          -day Signal
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.obvBigVolume
                            ? selectedCompanyIndicator?.obvBigVolume
                            : selectedIndicator?.obvBigVolume}
                          -day Signal Numeric
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal</Typography>
                      </TableCell>
                    </>
                  ) : showCalculations &&
                    selectedIndicatorName == "Stochastic Ind." ? (
                    <>
                      <TableCell>
                        <Typography variant="h6">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Volume</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Low</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Price - Lowest Low (Last{" "}
                          {selectedCompanyIndicator?.stochasticLookbackPeriod
                            ? selectedCompanyIndicator?.stochasticLookbackPeriod
                            : selectedIndicator?.stochasticLookbackPeriod}{" "}
                          days)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Highest High - Lowest Low (Last{" "}
                          {selectedCompanyIndicator?.stochasticLookbackPeriod
                            ? selectedCompanyIndicator?.stochasticLookbackPeriod
                            : selectedIndicator?.stochasticLookbackPeriod}{" "}
                          days)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          %K (= Fast Stochastic)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          %D (= Slow Stochastic) (Last:{" "}
                          {selectedCompanyIndicator?.stochasticSlow
                            ? selectedCompanyIndicator?.stochasticSlow
                            : selectedIndicator?.stochasticSlow}{" "}
                          days)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal Line %</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal</Typography>
                      </TableCell>
                    </>
                  ) : showCalculations && selectedIndicatorName == "ATR" ? (
                    <>
                      <TableCell>
                        <Typography variant="h6">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Volume</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Low</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High - Low</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High - Prev Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Low - Prev Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">True Range</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Average True Range (Last{" "}
                          {selectedCompanyIndicator?.atrAvgTrueRange
                            ? selectedCompanyIndicator?.atrAvgTrueRange
                            : selectedIndicator?.atrAvgTrueRange}{" "}
                          days)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Daily change in ATR %
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Daily change {`${">"}`}{" "}
                          {selectedCompanyIndicator?.atrDailyChangePercentage
                            ? selectedCompanyIndicator?.atrDailyChangePercentage
                            : selectedIndicator?.atrDailyChangePercentage}
                          %
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.atrLongTermPerformance
                            ? selectedCompanyIndicator?.atrLongTermPerformance
                            : selectedIndicator?.atrLongTermPerformance}{" "}
                          Day Performance (Long Term)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.atrLongTermPerformance
                            ? selectedCompanyIndicator?.atrLongTermPerformance
                            : selectedIndicator?.atrLongTermPerformance}{" "}
                          Day Performance + or - (Long Term)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.atrLongTermPerformance
                            ? selectedCompanyIndicator?.atrLongTermPerformance
                            : selectedIndicator?.atrLongTermPerformance}{" "}
                          Day Signal (Long Term)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.atrShortTermPerformance
                            ? selectedCompanyIndicator?.atrShortTermPerformance
                            : selectedIndicator?.atrShortTermPerformance}{" "}
                          Day Performance (Short Term)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.atrShortTermPerformance
                            ? selectedCompanyIndicator?.atrShortTermPerformance
                            : selectedIndicator?.atrShortTermPerformance}{" "}
                          Day Performance + or - (Short Term)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {selectedCompanyIndicator?.atrShortTermPerformance
                            ? selectedCompanyIndicator?.atrShortTermPerformance
                            : selectedIndicator?.atrShortTermPerformance}{" "}
                          Day Signal (Short Term)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal Number</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal</Typography>
                      </TableCell>
                    </>
                  ) : showCalculations &&
                    selectedIndicatorName == "ROC & Momentum" ? (
                    <>
                      <TableCell>
                        <Typography variant="h6">Weekly Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Weekly Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          ROC (
                          {selectedCompanyIndicator?.rocWeekly
                            ? selectedCompanyIndicator?.rocWeekly
                            : selectedIndicator?.rocWeekly}
                          ) Weekly
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">ROC Signal</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">ROC Signal Numeric</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Momentum (
                          {selectedCompanyIndicator?.momentumWeekly
                            ? selectedCompanyIndicator?.momentumWeekly
                            : selectedIndicator?.momentumWeekly}
                          ) Weekly
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Momentum Signal</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Momentum Signal Numeric
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Compared Signal</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          Compared Signal Numeric
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Signal</Typography>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <Typography variant="h6">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">High Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Low Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Volume</Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              {showCalculations ? (
                <>
                  <TableBody>
                    {!loading &&
                      (!selectedCompany ||
                        (stockData && stockData?.length === 0)) && (
                        <TableRowEmptyData />
                      )}
                    {selectedCompany &&
                      selectedIndicatorName == "RSI" &&
                      stockData &&
                      stockData?.content?.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {moment(
                                new Date(stock?.date).toLocaleDateString()
                              ).format("DD.MM.YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.price?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume?.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.highPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.gain?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.loss?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.averageGain?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.averageLoss?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.rsIndex?.toFixed(6)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.rsPercentage?.toFixed(6)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.strongSellLevel}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.strongBuyLevel}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.weakSellLevel}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.weakBuyLevel}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType == "STRONG_SELL"
                                ? "STRONG SELL"
                                : stock?.signalType == "STRONG_BUY"
                                ? "STRONG BUY"
                                : stock?.signalType == "WEAK_SELL"
                                ? "WEAK SELL"
                                : stock?.signalType == "WEAK_BUY"
                                ? "WEAK BUY"
                                : stock?.signalType == "NEUTRAL"
                                ? stock?.signalType
                                : ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    {selectedCompany &&
                      selectedIndicatorName == "Bollinger Bands" &&
                      stockData &&
                      stockData?.content?.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {moment(
                                new Date(stock?.date).toLocaleDateString()
                              ).format("DD.MM.YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.price?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume?.toLocaleString()}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.highPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.sma?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.standardDeviation?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.upperBand?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowerBand?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType == "SELL"
                                ? "SELL"
                                : stock?.signalType == "BUY"
                                ? "BUY"
                                : stock?.signalType == "NEUTRAL"
                                ? stock?.signalType
                                : ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    {selectedCompany &&
                      selectedIndicatorName == "Aroon Oscilator" &&
                      stockData &&
                      stockData?.content?.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {moment(
                                new Date(stock?.date).toLocaleDateString()
                              ).format("DD.MM.YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.price?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume?.toLocaleString()}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.highPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.highestHigh?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.periodsSinceHighestHigh}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.aroonUp == 0
                                ? "-"
                                : stock?.aroonUp?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowestLow?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.periodsSinceLowestLow}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.aroonDown == 0
                                ? "-"
                                : stock?.aroonDown?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.differenceAroonUpDown == 0
                                ? "-"
                                : stock?.differenceAroonUpDown?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType == "SELL"
                                ? "SELL"
                                : stock?.signalType == "BUY"
                                ? "BUY"
                                : stock?.signalType}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    {selectedCompany &&
                      selectedIndicatorName == "OBV" &&
                      stockData &&
                      stockData?.content?.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {moment(
                                new Date(stock?.date).toLocaleDateString()
                              ).format("DD.MM.YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.price?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume?.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.highPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume20day?.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume50day?.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType20Day?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType20Day?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType20Day == "SELL"
                                ? "SELL"
                                : stock?.signalType20Day == "BUY"
                                ? "BUY"
                                : stock?.signalType20Day == "NEUTRAL"
                                ? stock?.signalType20Day
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.signalType20DayNumeric}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType50Day?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType50Day?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType50Day == "SELL"
                                ? "SELL"
                                : stock?.signalType50Day == "BUY"
                                ? "BUY"
                                : stock?.signalType50Day == "NEUTRAL"
                                ? stock?.signalType50Day
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.signalType50DayNumeric}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType == "STRONG_SELL"
                                ? "STRONG SELL"
                                : stock?.signalType == "STRONG_BUY"
                                ? "STRONG BUY"
                                : stock?.signalType == "WEAK_SELL"
                                ? "WEAK SELL"
                                : stock?.signalType == "WEAK_BUY"
                                ? "WEAK BUY"
                                : stock?.signalType == "NEUTRAL"
                                ? stock?.signalType
                                : ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    {selectedCompany &&
                      selectedIndicatorName == "Stochastic Ind." &&
                      stockData &&
                      stockData?.content?.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {moment(
                                new Date(stock?.date).toLocaleDateString()
                              ).format("DD.MM.YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.price?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume?.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.highPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.differencePriceLowest?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.differenceHighestLowest?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.fastStochasticPercentage?.toFixed(5)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.slowStochasticPercentage?.toFixed(5)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.signalPercentage?.toFixed(5)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType == "SELL"
                                ? "SELL"
                                : stock?.signalType == "BUY"
                                ? "BUY"
                                : stock?.signalType == "NEUTRAL"
                                ? stock?.signalType
                                : ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    {selectedCompany &&
                      selectedIndicatorName == "ATR" &&
                      stockData &&
                      stockData?.content?.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {moment(
                                new Date(stock?.date).toLocaleDateString()
                              ).format("DD.MM.YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.price?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume?.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.highPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.differenceHighLow?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.differenceHighPrevPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.differenceLowPrevPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.trueRangeDifferences?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.averageTrueRangeDifferences?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.dailyChangeATRPercentage
                                ? stock?.dailyChangeATRPercentage?.toFixed(2) +
                                  "%"
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.dailyChangePercentageBiggerThanFive}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.tenDayPerformancePercentage
                                ? stock?.tenDayPerformancePercentage?.toFixed(
                                    2
                                  ) + "%"
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.tenDayPerformanceNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.tenDaySignalNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.fiveDayPerformancePercentage
                                ? stock?.fiveDayPerformancePercentage?.toFixed(
                                    2
                                  ) + "%"
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.fiveDayPerformanceNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.fiveDaySignalNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.signalNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType == "SELL"
                                ? "SELL"
                                : stock?.signalType == "BUY"
                                ? "BUY"
                                : stock?.signalType == "NEUTRAL"
                                ? stock?.signalType
                                : ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    {selectedCompany &&
                      selectedIndicatorName == "ROC & Momentum" &&
                      stockData &&
                      stockData?.content?.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.weeklyDate
                                ? moment(
                                    new Date(
                                      stock?.weeklyDate
                                    ).toLocaleDateString()
                                  ).format("DD.MM.YYYY")
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.weeklyPrice
                                ? stock?.weeklyPrice?.toFixed(2)
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.rocWeekly
                                ? stock?.rocWeekly?.toFixed(2)
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.rocSignalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.rocSignalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.rocSignalType == "SELL"
                                ? "SELL"
                                : stock?.rocSignalType == "BUY"
                                ? "BUY"
                                : stock?.rocSignalType
                                ? stock?.rocSignalType
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.rocSignalNumeric
                                ? stock?.rocSignalNumeric
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.momentumWeekly
                                ? stock?.momentumWeekly?.toFixed(2)
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.momentumSignalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.momentumSignalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.momentumSignalType == "SELL"
                                ? "SELL"
                                : stock?.momentumSignalType == "BUY"
                                ? "BUY"
                                : stock?.momentumSignalType
                                ? stock?.momentumSignalType
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.momentumSignalNumeric
                                ? stock?.momentumSignalNumeric
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.numericSignalComparisonType?.includes(
                                  "BUY"
                                )
                                  ? "#81c784"
                                  : stock?.numericSignalComparisonType?.includes(
                                      "SELL"
                                    )
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.numericSignalComparisonType ==
                                "STRONG_BUY" ||
                              stock?.numericSignalComparisonType ==
                                "STRONG_SELL"
                                ? "STRONG"
                                : stock?.numericSignalComparisonType ==
                                  "NEUTRAL"
                                ? "NEUTRAL"
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.numericSignalComparisonNumeric == 0
                                ? "0"
                                : stock?.numericSignalComparisonNumeric == -1
                                ? "-1"
                                : stock?.numericSignalComparisonNumeric == 1
                                ? "1"
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color={
                                stock?.signalType?.includes("BUY")
                                  ? "#81c784"
                                  : stock?.signalType?.includes("SELL")
                                  ? "#ef5350"
                                  : "#ffd54f"
                              }
                            >
                              {stock?.signalType == "SELL"
                                ? "SELL"
                                : stock?.signalType == "BUY"
                                ? "BUY"
                                : stock?.signalType == "NEUTRAL"
                                ? stock?.signalType
                                : ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              ) : (
                <>
                  <TableBody>
                    {!loading &&
                      (!selectedCompany ||
                        (stockData && stockData?.length === 0)) && (
                        <TableRowEmptyData />
                      )}
                    {selectedCompany &&
                      stockData &&
                      stockData?.content?.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {moment(
                                new Date(stock?.date).toLocaleDateString()
                              ).format("DD.MM.YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.price?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.highPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.lowPrice?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1">
                              {stock?.volume?.toLocaleString()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              )}

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={20} align="center">
                    <TablePagination
                      rowsPerPageOptions={
                        AuthRepository.hasAnyRole(["ROLE_ADMINISTRATION"])
                          ? [5, 10, 15, 25, 50, 75, 100]
                          : [5, 10, 15, 25]
                      }
                      rowsPerPage={stockData?.size ? stockData?.size : size}
                      labelRowsPerPage={LOCALE.rows_per_page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": LOCALE.rows_per_page,
                        },
                        native: true,
                      }}
                      onRowsPerPageChange={(event, value) => {
                        handleChangeRowsPerPage(event);
                      }}
                      ActionsComponent={(subProps) => {
                        const {
                          page,
                          count,
                          rowsPerPage,
                          backIconButtonProps,
                          nextIconButtonProps,
                          showLastButton,
                          getItemAriaLabel,
                          showFirstButton,
                          onPageChange,
                          ...restSubProps
                        } = subProps;
                        return (
                          <>
                            <Pagination
                              sx={{
                                minWidth: "350px",
                                paddingLeft: "10px",
                              }}
                              count={
                                count % rowsPerPage === 0
                                  ? count / rowsPerPage
                                  : Math.floor(count / rowsPerPage) + 1
                              }
                              shape="rounded"
                              size="small"
                              page={page + 1}
                              showFirstButton
                              showLastButton
                              onChange={(event, value) => {
                                handleChangePage(event, value - 1);
                              }}
                            />
                          </>
                        );
                      }}
                      page={stockData?.number}
                      count={stockData?.totalElements}
                      sx={{
                        borderBottom: "none",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
