import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { triggerRerender } from "../../common/CommonActions";
import { LOCALE } from "../../properties/Locale";
import { AuthRepository } from "../../repositories/AuthRepository";
import { useTheme } from "@emotion/react";
import Facebook from "./Facebook";
import google from "../../assets/img/search.png";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SETTINGS } from "../../properties/Settings";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleLogin = () => {
    setLoading(true);
    AuthRepository.fetchToken({ username: username, password: password }).then(
      (res) => {
        window.localStorage.setItem("jwt", res?.data?.jwt);
        dispatch(triggerRerender());
        if (AuthRepository.hasAnyRole("ROLE_ADMINISTRATION")) {
          navigate("/home");
        } else {
          navigate("/");
        }
        setLoading(false);
      },
      (err) => {
        console.log(err);
        console.log(err.response);
        setGlobalFormError(err.response?.data);
        setLoading(false);
      }
    );
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Paper
          elevation={3}
          justifyContent="center"
          style={{
            width: isSmallScreen ? "380px" : "500px",
            height: isSmallScreen ? "665px" : "600px",
            textAlign: "center",
            borderRadius: "20px",
          }}
        >
          <Grid
            continer
            justifyContent="center"
            style={{
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <img
              alt=""
              src={require("../../assets/img/capitelize_logo.png")}
              width="90px"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer", marginBottom: "60px" }}
            ></img>{" "}
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            {globalFormError && (
              <Grid
                item
                xs={8}
                style={{
                  marginTop: "-60px",
                  marginBottom: "5px",
                }}
              >
                <Alert severity="error">{globalFormError}</Alert>
              </Grid>
            )}
            <Grid container justifyContent="center" paddingBottom={2}>
              <Grid item xs={8} md={7}>
                <TextField
                  fullWidth
                  sx={{ alignItems: "center" }}
                  label="Username"
                  value={username}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                  onChange={(e) => setUsername(e.target.value)}
                  style={{
                    height: "48px",
                    borderRadius: "4px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" paddingBottom={2}>
              <Grid item xs={8} md={7}>
                <TextField
                  fullWidth
                  label={LOCALE.password}
                  type={showPassword ? "text" : "password"}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  style={{
                    height: "48px",
                    borderRadius: "4px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {!showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={8} md={7}>
                <Button
                  onClick={() => {
                    handleLogin();
                  }}
                  fullWidth
                  variant="contained"
                  sx={{
                    height: "48px",
                    backgroundColor: "#10377c",
                    marginTop: "20px",
                  }}
                >
                  <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                    {LOCALE.login.toLowerCase()}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <a
                style={{
                  cursor: "pointer",
                  color: "#10377c",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  navigate("/forgotPassword");
                }}
              >
                {LOCALE.forgotPassword}
              </a>
            </Grid>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={8}
                md={7}
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <Divider
                  style={{
                    borderBottomWidth: "2px",
                  }}
                >
                  <span style={{ opacity: 0.5 }}> or continue with</span>
                </Divider>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={2.5}
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: "2px",
                }}
              >
                <div
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "8px",
                    padding: "7px",
                    width: "100px",
                    display: "inline-block",
                  }}
                >
                  <a
                    href={`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${SETTINGS.API_URL}/grantcode&response_type=code&client_id=96069768200-hh0198rae0vp1fais7eifsbamcegg4ut.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid&access_type=offline`}
                  >
                    <img
                      style={{
                        width: "23px",
                        height: "23px",
                      }}
                      src={google}
                      alt="Google"
                    />
                  </a>
                </div>
              </Grid>
              {isSmallScreen && <Grid item xs={1}></Grid>}

              <Grid
                item
                xs={2.5}
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "8px",
                    padding: "5px",
                    display: "inline-block",
                    width: "100px",
                    marginTop: "2px",
                  }}
                >
                  <Facebook />
                </div>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginTop: "25px" }}
            >
              <Typography style={{ opacity: 0.5, fontWeight: "bold" }}>
                Don't Have An Account?
              </Typography>
              <a
                style={{
                  cursor: "pointer",
                  color: "#10377c",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  navigate("/registerUser");
                }}
              >
                Sign up
              </a>
            </Grid>
          </Grid>
        </Paper>
      </div>

      {!isSmallScreen && (
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            color: theme.palette.lightGray.main,
            marginTop: "-20px",
          }}
        >
          &copy; Capitelize 2024
        </Grid>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
