import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const CompanyRepository = {
  uploadData: (file) => {
    const data = new FormData();
    data.append("file", file);
    return Axios({
      url: `${SETTINGS.API_URL}/companies/uploadData`,
      method: "PUT",
      data: data,
    });
  },
  getAll: (page, size, searchParams) => {
    return Axios({
      url: `${SETTINGS.API_URL}/companies`,
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: searchParams,
        orderBy: "id",
        orderDirection: "DESC",
      },
    });
  },
  getAllWithoutPaging: () => {
    return Axios({
      url: SETTINGS.API_URL + "/companies/all",
      method: "GET",
    });
  },
  getCompanyById: (companyId) => {
    return Axios({
      url: SETTINGS.API_URL + `/companies/${companyId}`,
      method: "GET",
    });
  },
};
