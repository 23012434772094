import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { notifyShowErrorMessage } from "../../common/CommonActions";
import { useDispatch } from "react-redux";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { LOCALE } from "../../properties/Locale";
import { AuthRepository } from "../../repositories/AuthRepository";
import { TableRowEmptyData } from "../../common/TableRowEmptyData";
import { UsersRepository } from "../../repositories/UsersRepository";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Clear } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { alpha } from "@mui/system";

export default function UserList() {
  const [searchParams, setSearchParams] = useState({});
  const [data, setData] = useState({});
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    loadData();
  }, [searchParams, page, size]);

  const loadData = () => {
    setLoading(true);
    UsersRepository.getAll(page, size, JSON.stringify({ ...searchParams }))
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleChange = (name, value) => {
    let tmp = { ...searchParams };
    tmp[name] = value;
    setSearchParams(tmp);
  };

  const handleDateChange = (name, event) => {
    let tmp = { ...searchParams };
    tmp[name] = event;
    setSearchParams(tmp);
  };

  const clearFilters = () => {
    setSearchParams({});
  };

  return (
    <Paper sx={{ padding: 5, borderRadius: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} pt={3}>
          <Typography
            variant="h3"
            sx={{ textAlign: { xs: "center", md: "start" } }}
          >
            Users
            <Tooltip title={<Typography variant="h6">Refresh</Typography>}>
              <IconButton
                onClick={() => {
                  setSearchParams({});
                }}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: 1,
                  minWidth: "auto",
                  height: "auto",
                  marginLeft: "10px",
                  display: "inline-flex",
                }}
              >
                <Icon
                  style={{
                    color: theme.palette.darkContrast.main,
                    fontSize: "25px",
                  }}
                >
                  refresh
                </Icon>
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <TextField
            size="small"
            onChange={(e) => handleChange("username", e.target.value)}
            label={"Username"}
            value={searchParams?.username ? searchParams?.username : ""}
            variant="outlined"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mutedBlue.main,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.mutedBlue.main,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <TextField
            size="small"
            onChange={(e) => handleChange("firstName", e.target.value)}
            label={"First Name"}
            value={searchParams?.firstName ? searchParams?.firstName : ""}
            variant="outlined"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mutedBlue.main,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.mutedBlue.main,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <TextField
            size="small"
            onChange={(e) => handleChange("lastName", e.target.value)}
            label={"Last Name"}
            value={searchParams?.lastName ? searchParams?.lastName : ""}
            variant="outlined"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mutedBlue.main,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.mutedBlue.main,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <TextField
            size="small"
            onChange={(e) => handleChange("email", e.target.value)}
            label={"Email"}
            value={searchParams?.email ? searchParams?.email : ""}
            variant="outlined"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mutedBlue.main,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.mutedBlue.main,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mutedBlue.main,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.mutedBlue.main,
              },
            }}
          >
            <InputLabel style={{ display: "flex" }}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <Icon
                  style={{
                    color: theme.palette.mutedBlue.main,
                  }}
                >
                  circle
                </Icon>
                <Icon
                  style={{
                    position: "absolute",
                    top: 1,
                    left: 1,
                    right: 1,
                    bottom: 1,
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  check
                </Icon>
              </div>
              Is Enabled
            </InputLabel>
            <Select
              label="All"
              value={searchParams?.isEnabled ? searchParams?.isEnabled : ""}
              onChange={(e) => handleChange("isEnabled", e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value={"true"}>{"Enabled"}</MenuItem>
              <MenuItem value={"false"}>{"Not enabled"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                minHeight: 34,
                height: 34,
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mutedBlue.main,
                },
              },
              "& .MuiInputLabel-root": {
                transform: "translate(14px, 10px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.mutedBlue.main,
              },
              "& .MuiInputBase-input": {
                height: "100%",
                display: "flex",
                alignItems: "center",
              },
              "& .MuiSvgIcon-root": {
                color: theme.palette.mutedBlue.main,
              },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date Created"
                value={
                  searchParams?.dateCreated ? searchParams?.dateCreated : null
                }
                format="dd/MM/yyyy"
                onChange={(e) => handleDateChange("dateCreated", e)}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {Object.keys(searchParams).length > 0 && (
            <Tooltip
              title={<Typography variant="h6">Clear filters</Typography>}
            >
              {isSmallScreen ? (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => clearFilters()}
                >
                  Clear filters
                </Button>
              ) : (
                <IconButton onClick={() => clearFilters()}>
                  <Clear
                    sx={{
                      fontSize: "22px",
                    }}
                  />
                </IconButton>
              )}
            </Tooltip>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              "@media (min-width: 600px)": {
                overflowX: "visible",
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">No. </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Date Created</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Username</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">First Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Last Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Is Enabled</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && data && data?.length === 0 && (
                  <TableRowEmptyData />
                )}
                {data &&
                  data?.content?.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {index + 1 + page * size}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {moment(user?.dateCreated).format("DD.MM.YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {user?.username}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {user?.firstName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {user?.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {user?.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          checked={user?.isEnabled}
                          disabled={!user?.isEnabled}
                          control={
                            <Switch
                              onChange={(e) => {}}
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  backgroundColor: user?.isEnabled
                                    ? theme.palette.mutedBlue.main
                                    : alpha(theme.palette.lightGray.main, 0.75),
                                },
                              }}
                            />
                          }
                          label=""
                          sx={{
                            marginLeft: "5px",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <TablePagination
                      rowsPerPageOptions={
                        AuthRepository.hasAnyRole(["ROLE_ADMINISTRATION"])
                          ? [5, 10, 15, 25, 50, 75, 100]
                          : [5, 10, 15, 25]
                      }
                      rowsPerPage={data?.size ? data?.size : size}
                      labelRowsPerPage={LOCALE.rows_per_page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": LOCALE.rows_per_page,
                        },
                        native: true,
                      }}
                      onRowsPerPageChange={(event, value) => {
                        handleChangeRowsPerPage(event);
                      }}
                      ActionsComponent={(subProps) => {
                        const {
                          page,
                          count,
                          rowsPerPage,
                          backIconButtonProps,
                          nextIconButtonProps,
                          showLastButton,
                          getItemAriaLabel,
                          showFirstButton,
                          onPageChange,
                          ...restSubProps
                        } = subProps;
                        return (
                          <>
                            <Pagination
                              sx={{
                                minWidth: "200px",
                                paddingLeft: "10px",
                              }}
                              count={
                                count % rowsPerPage === 0
                                  ? count / rowsPerPage
                                  : Math.floor(count / rowsPerPage) + 1
                              }
                              shape="rounded"
                              size="small"
                              page={page + 1}
                              showFirstButton
                              showLastButton
                              onChange={(event, value) => {
                                handleChangePage(event, value - 1);
                              }}
                            />
                          </>
                        );
                      }}
                      page={data?.number}
                      count={data?.totalElements}
                      sx={{
                        borderBottom: "none",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
