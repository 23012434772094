import axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const UserRegistrationRepository = {
  registerUserNow: (registration) => {
    return axios({
      url: `${SETTINGS.API_URL}/register/user/registerUserNow`,
      data: registration,
      method: "POST",
    });
  },
  activateUser: (token) => {
    return axios({
      url: `${SETTINGS.API_URL}/activate-user?token=${encodeURI(token)}`,
      data: token,
      method: "GET",
    });
  },
  facebookLogin: (email, name) => {
    return axios({
      url: SETTINGS.API_URL + "/facebook",
      method: "POST",
      data: {
        email: email,
        name: name,
      },
    });
  },
};
